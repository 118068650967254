/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import PageLayout from '../../layouts/PageLayout'
import Card from '../../components/Card'
import Button from '../../components/Button'
import Typography from '../../components/Typography'
import { Table, Space, Row, message, Pagination } from 'antd'
import Modal from './Modal'
import PlusCircle from '../../image/PlusCircle.png'
import request from '../../utils/request'
import { Symptom } from '../../interface'
import AlertModal from '../../components/AlertModal'
import type { PaginationProps } from 'antd'

const SymptomsPage = (): JSX.Element => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [page, setPage] = useState<number>(1)
  const [total, setTotal] = useState<number>(0)
  const [selectId, setSelectId] = useState<number | undefined>(undefined)
  const [symptomList, setSymptomList] = useState<Symptom[]>([])
  const [refresh, setRefresh] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [paginations, setPaginations] = useState([])

  const getPagination = async () => {
    setLoading(true)
    try {
      const pagination: any = await request.get(`/symptom/firstChar`)
      setPaginations(pagination)

      setTotal(pagination.length)
      const res: any = await request.get(
        `/symptom/char/${pagination[page - 1]}`
      )
      setLoading(false)
      setSymptomList(res)
    } catch (error) {
      console.log(error)
    }
  }

  const onDelete = async (id: number | undefined) => {
    try {
      await request.delete(`/symptom/${id}`)
      message.success('ลบสำเร็จ')
      setOpenConfirmModal(false)
      setRefresh?.((prev) => !prev)
    } catch (err) {
      message.error('ดำเนินการผิดพลาด')
      console.log(err)
    }
  }

  useEffect(() => {
    getPagination()
  }, [page, refresh])

  const columns = [
    {
      title: <Typography sub1>รหัส</Typography>,
      dataIndex: 'id',
      key: 'id',
      render: (text: string) => (
        <Typography sub2>{String(text).padStart(3, '0')}</Typography>
      ),
    },
    {
      title: <Typography sub1>ชื่ออาการ / โรค</Typography>,
      dataIndex: 'name',
      key: 'name',
      render: (text: string) => (
        <Typography sub2 color="black90">
          {text}
        </Typography>
      ),
    },
    {
      title: <Typography sub1>ชื่อเสมือน</Typography>,
      dataIndex: 'otherNames',
      key: 'otherNames',
      render: (_: any, record: Symptom) => (
        <Row>
          {record.otherNames.map((x, indx) => {
            return (
              <Typography key={indx} sub2 color="black90">
                {x}
                {indx != record.otherNames.length - 1 ? ', ' : ''}
              </Typography>
            )
          })}
        </Row>
      ),
    },
    {
      title: <Typography sub1>ICD-10 CM</Typography>,
      dataIndex: 'icd10Code',
      key: 'icd10Code',
      width: 120,
      render: (text: string) => (
        <Typography sub2 color="black90">
          {text}
        </Typography>
      ),
    },
    {
      title: <Typography sub1>ประเภท</Typography>,
      dataIndex: 'type',
      key: 'type',
      width: 120,
      render: (text: string) => (
        <Typography sub2 color="black90">
          {text === 'accident'
            ? 'อุบัติเหตุ'
            : text === 'not accident'
            ? 'ไม่อุบัติเหตุ'
            : ''}
        </Typography>
      ),
    },

    {
      title: '',
      key: 'action',
      render: (_: any, record: Symptom) => (
        <Space size="middle">
          <a
            onClick={() => {
              setSelectId(record.id)
              setIsModalVisible(true)
            }}
          >
            <Typography sub1 color="primary2">
              แก้ไข
            </Typography>
          </a>

          <a
            onClick={() => {
              setSelectId(record.id)
              setOpenConfirmModal(true)
            }}
          >
            <Typography sub1 color="primary2">
              ลบ
            </Typography>
          </a>
        </Space>
      ),
    },
  ]

  const itemRender: PaginationProps['itemRender'] = (
    _,
    type,
    originalElement
  ) => {
    if (type === 'page') {
      return paginations[_ - 1]
    }
    return originalElement
  }

  return (
    <PageLayout
      title="รายชื่ออาการ / โรค"
      shownBack={false}
      radioGroup={
        <div className="row">
          <Button
            title="เพิ่มรายชื่ออาการ / โรค"
            type="primary"
            iconLeft={
              <img style={{ width: 23, height: 23 }} src={PlusCircle} />
            }
            onClick={() => {
              setSelectId(undefined)
              setIsModalVisible(true)
            }}
          />
        </div>
      }
    >
      <Card style={{ padding: '0px 16px' }}>
        <Table
          columns={columns}
          dataSource={symptomList}
          pagination={false}
          loading={loading}
          scroll={{ x: 'fit-content' }}
        />
        <Pagination
          current={page}
          pageSize={total / paginations.length}
          total={total}
          style={{ padding: '20px 0px', textAlign: 'end' }}
          onChange={(page: number) => setPage(page)}
          showSizeChanger={false}
          itemRender={itemRender}
        />
      </Card>
      <Modal
        id={selectId}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        setRefresh={setRefresh}
      />
      <AlertModal
        type="confirm"
        title="คุณยืนยันที่จะลบรายชื่ออาการ/โรคนี้ใช่หรือไม่"
        isModalVisible={openConfirmModal}
        onCancel={() => setOpenConfirmModal(false)}
        onSubmit={() => {
          onDelete(selectId)
        }}
      />
    </PageLayout>
  )
}

export default SymptomsPage
