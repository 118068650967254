import React from 'react'
import { Form, Input, message } from 'antd'
import Button from '../../components/Button'
import LogoEcliaim from '../../image/LogoEcliaim.png'
import Typography from '../../components/Typography'
import request from '../../utils/request'
// import { useHistory } from 'react-router-dom'

interface FormValues {
  email: string
}

const ForgetPasswordPage = (): JSX.Element => {
  // const history = useHistory()
  const onSubmit = async (values: FormValues) => {
    try {
      await request.post('/admin/password/forget', {
        email: values.email,
      })
      message.success('ส่งลิงก์สำหรับรีเซ็ตรหัสผ่านให้แล้ว')
      // history.push(`reset-password`)
    } catch (e) {
      message.error('อีเมลนี้ไม่มีอยู่ในระบบ โปรดตรวจสอบอีเมลอีกครั้งหนึ่ง')
    }
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img style={{ height: 90 }} src={LogoEcliaim} />
      <Typography
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          lineHeight: '60px',
          margin: '20px 0px',
        }}
        h2
      >
        ลืมรหัสผ่าน
      </Typography>
      <Form
        name="login"
        layout="vertical"
        initialValues={{}}
        onFinish={onSubmit}
      >
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'กรุณากรอกอีเมล์' }]}
        >
          <Input className="ant-input-class" placeholder="example@gmail.com" />
        </Form.Item>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Form.Item>
            <Button
              htmlType="submit"
              title="ส่งอีเมล์"
              style={{ width: '350px' }}
            />
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default ForgetPasswordPage
