import React from 'react'
import PageLayout from '../../layouts/PageLayout'
import Card from '../../components/Card'
import Button from '../../components/Button'
import { Form, Input, message } from 'antd'
import { useAuth } from '../../contexts/AuthContext'

const PasswordPage = (): JSX.Element => {
  const {
    authContext: { changePassword },
  } = useAuth()
  const [form] = Form.useForm()

  const onFinish = async (values: {
    newPassword: string
    oldPassword: string
  }) => {
    const { newPassword, oldPassword } = values
    try {
      await changePassword({ oldPassword, newPassword })
      message.success('เปลี่ยนรหัสผ่านสำเร็จ')
      form.setFieldsValue({
        oldPassword: '',
        newPassword: '',
      })
    } catch (e) {
      message.error('ดำเนินการผิดพลาด กรุณาตรวจสอบรหัสผ่าน')
    }
  }

  return (
    <PageLayout title="เปลี่ยนรหัสผ่าน" shownBack={false}>
      <Card style={{ padding: '24px' }}>
        <Form
          form={form}
          name="PasswordDetail"
          layout="vertical"
          onFinish={onFinish}
          style={{
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Form.Item name="oldPassword">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Input.Password
                className="ant-input-class"
                placeholder="รหัสผ่านเก่า"
              />
            </div>
          </Form.Item>
          <Form.Item name="newPassword">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Input.Password
                className="ant-input-class"
                placeholder="รหัสผ่านใหม่"
              />
            </div>
          </Form.Item>
          <Button htmlType="submit" title="เปลี่ยนรหัสผ่าน" />
        </Form>
      </Card>
    </PageLayout>
  )
}

export default PasswordPage
