/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { Upload, message, Form, FormInstance } from 'antd'
import Button from '../Button'
import IconAdd from '../../image/IconAdd.png'
import { UploadFile } from 'antd/lib/upload/interface'
import request from '../../utils/request'

const baseUrl = process.env.REACT_APP_API_URL

interface Props {
  formName: string
  form: FormInstance<any>
  data: number[] | undefined
}

const UploadMultipleFile = ({ formName, form, data }: Props): JSX.Element => {
  const token = localStorage.getItem('accessToken')
  const [fileList, setFileList] = useState<UploadFile[] | any[]>([])
  const getInfo = async (data: number[] | undefined) => {
    try {
      if (data) {
        const list = await Promise.all(
          data.map(async (item) => {
            return await request
              .get(`${baseUrl}/container/${item}/info/?access_token=${token}`)
              .then((res: any) => {
                return {
                  uid: res.id,
                  name: res.originalName,
                  status: 'done',
                  response: res,
                }
              })
          })
        )
        if (list.length < form.getFieldValue(formName)?.length) {
          setFileList(form.getFieldValue(formName))
        } else {
          setFileList(list)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (data?.length !== 0) {
      getInfo(data)
    } else {
      setFileList([])
    }
    if (form.getFieldValue(formName)?.length > fileList?.length) {
      setFileList(form.getFieldValue(formName))
    }
  }, [data])

  const onImageChange = async ({ fileList }: any) => {
    setFileList(fileList)
  }
  const beforeUpload = (file: { type: string; size: number }) => {
    const isJpgOrPng =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'application/pdf'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG/PDF file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!')
    }
    return isJpgOrPng && isLt2M ? true : Upload.LIST_IGNORE
  }

  const normFile = (e: { file: any; fileList: any }) => {
    if (e.file.response) {
      return e.fileList
    }
  }

  return (
    <Form.Item noStyle name={formName} getValueFromEvent={(e) => normFile(e)}>
      <Upload
        name="file"
        accept="image/*,.pdf"
        action={`${baseUrl}/container`}
        beforeUpload={beforeUpload}
        headers={{
          accept: 'application/json',
          Authorization: token ? `Bearer ${token}` : '',
        }}
        onChange={(info) => {
          onImageChange(info)
        }}
        fileList={fileList}
      >
        <Button
          htmlType="button"
          iconLeft={<img style={{ width: 23, height: 23 }} src={IconAdd} />}
          style={{ padding: 0 }}
          title="เพิ่มไฟล์"
          type="text"
        />
      </Upload>
    </Form.Item>
  )
}

export default UploadMultipleFile
