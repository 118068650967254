/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction } from 'react'
import { Col, Row } from 'antd'
import Button from '../../../components/Button'
import Typography from '../../../components/Typography'
import { CaseDiagnosis } from '../../../interface'
import { scrollToTop } from '../../../utils/getName'

interface Props {
  id?: string
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
  setStep?: Dispatch<SetStateAction<number>> | undefined
  data: CaseDiagnosis | undefined
}

const Step5 = ({ setStep, setIsModalVisible, data }: Props): JSX.Element => {
  const medicineList = data ? data.diagnosis.medicineList : []
  const medicalSupplies = data ? data.diagnosis.medicalSupplies : []
  const medicalServices = data ? data.diagnosis.medicalServices : []

  const empty = () => {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
        }}
      >
        <Col span={10}>
          <Typography sub1 color="black">
            -
          </Typography>
        </Col>
        <Col span={5}>
          <Typography sub1 color="black">
            -
          </Typography>
        </Col>
        <Col span={5}>
          <Typography sub1 color="black">
            -
          </Typography>
        </Col>
        <Col span={4}>
          <Typography sub1 color="black">
            -
          </Typography>
        </Col>
      </div>
    )
  }

  return (
    <>
      <Row style={{ marginBottom: 20, flexDirection: 'column' }}>
        <Typography sub2 color="black">
          รายการยา
        </Typography>
        <Row style={{ padding: '10px 0px' }} gutter={10}>
          <Col span={10}>
            <Typography sub4 color="black65">
              ชื่อยา
            </Typography>
          </Col>
          <Col span={5}>
            <Typography sub4 color="black65">
              ยูนิต
            </Typography>
          </Col>
          <Col span={5}>
            <Typography sub4 color="black65">
              จำนวน
            </Typography>
          </Col>
          <Col span={4}>
            <Typography sub4 color="black65">
              ราคา
            </Typography>
          </Col>
          {medicineList.length > 0
            ? medicineList.map((item: any) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      paddingTop: 5,
                      alignItems: 'flex-start',
                    }}
                    key={item.id}
                  >
                    <Col span={10}>
                      <Typography sub1 color="black">
                        {item.medicine.commonName}
                      </Typography>
                    </Col>
                    <Col span={5}>
                      <Typography sub1 color="black">
                        {item.medicine.unit}
                      </Typography>
                    </Col>
                    <Col span={5}>
                      <Typography sub1 color="black">
                        {item.quantity}
                      </Typography>
                    </Col>
                    <Col span={4}>
                      <Typography sub1 color="black">
                        {item.totalPrice}
                      </Typography>
                    </Col>
                  </div>
                )
              })
            : empty()}
        </Row>
      </Row>

      <Row style={{ marginBottom: 20, flexDirection: 'column' }}>
        <Typography sub2 color="black">
          เวชภัณฑ์
        </Typography>
        <Row style={{ padding: '10px 0px' }} gutter={10}>
          <Col span={10}>
            <Typography sub4 color="black65">
              ชื่อเวชภัณฑ์
            </Typography>
          </Col>
          <Col span={5}>
            <Typography sub4 color="black65">
              ยูนิต
            </Typography>
          </Col>
          <Col span={5}>
            <Typography sub4 color="black65">
              จำนวน
            </Typography>
          </Col>
          <Col span={4}>
            <Typography sub4 color="black65">
              ราคา
            </Typography>
          </Col>

          {medicalSupplies.length > 0
            ? medicalSupplies?.map((item: any) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      paddingTop: 5,
                      alignItems: 'flex-start',
                    }}
                    key={item.id}
                  >
                    <Col span={10}>
                      <Typography sub1 color="black">
                        {item.supply.commonName}
                      </Typography>
                    </Col>
                    <Col span={5}>
                      <Typography sub1 color="black">
                        {item.supply.unit}
                      </Typography>
                    </Col>
                    <Col span={5}>
                      <Typography sub1 color="black">
                        {item.quantity}
                      </Typography>
                    </Col>
                    <Col span={4}>
                      <Typography sub1 color="black">
                        {item.totalPrice}
                      </Typography>
                    </Col>
                    <Col span={2}></Col>
                  </div>
                )
              })
            : empty()}
        </Row>
      </Row>

      <Row style={{ marginBottom: 20, flexDirection: 'column' }}>
        <Typography sub2 color="black">
          บริการทางการแพทย์
        </Typography>
        <Row style={{ padding: '10px 0px' }} gutter={10}>
          <Col span={10}>
            <Typography sub4 color="black65">
              ชื่อบริการ
            </Typography>
          </Col>
          <Col span={5}>
            <Typography sub4 color="black65">
              ประเภท
            </Typography>
          </Col>
          <Col span={5}>
            <Typography sub4 color="black65">
              จำนวน
            </Typography>
          </Col>
          <Col span={4}>
            <Typography sub4 color="black65">
              ราคา
            </Typography>
          </Col>
          {medicalServices.length > 0
            ? medicalServices?.map((item: any) => {
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      paddingTop: 5,
                      alignItems: 'flex-start',
                    }}
                    key={item.id}
                  >
                    <Col span={10}>
                      <Typography sub1 color="black">
                        {item.service.name}
                      </Typography>
                    </Col>
                    <Col span={5}>
                      <Typography sub1 color="black">
                        {item.type}
                      </Typography>
                    </Col>
                    <Col span={5}>
                      <Typography sub1 color="black">
                        {item.quantity}
                      </Typography>
                    </Col>
                    <Col span={4}>
                      <Typography sub1 color="black">
                        {item.totalPrice}
                      </Typography>
                    </Col>
                  </div>
                )
              })
            : empty()}
        </Row>
      </Row>
      <Row gutter={10}>
        <Col span={10}>
          <Typography sub2 color="black">
            ราคาทั้งหมด
          </Typography>
        </Col>
        <Col span={14}>
          <Typography sub1 color="black">
            {`${data?.diagnosis.totalPrice} บาท`}
          </Typography>
        </Col>
      </Row>
      <br />
      <br />
      <Row justify="space-between" align="middle" style={{ paddingBottom: 24 }}>
        <Button
          htmlType="button"
          title="ย้อนกลับ"
          type="secondary"
          style={{ width: '48%' }}
          onClick={() => {
            scrollToTop()
            setStep?.(4)
          }}
        />
        <Button
          htmlType="button"
          title="ปิด"
          style={{ width: '48%' }}
          onClick={() => {
            scrollToTop()
            setIsModalVisible(false)
            setStep?.(1)
          }}
        />
      </Row>
    </>
  )
}

export default Step5
