/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, FormInstance } from 'antd'
import React, { useEffect, useState } from 'react'
import Button from '../../components/Button'
import Typography from '../../components/Typography'
import request from '../../utils/request'
import IconAdd from '../../image/IconAdd.png'
import { CreateGroupItem } from './CreateGroupItem'

interface Props {
  form: FormInstance<any>
  onCopy: () => Promise<void>
  copy: any
}

export const CreateGroupForm = ({ form, onCopy, copy }: Props): JSX.Element => {
  // const [searchValue, setSearchValue] = useState<string>()
  const [firstCharOption, setFirstCharOption] = useState<any[]>([])

  useEffect(() => {
    const getFirstChar = async () => {
      try {
        const res: any = await request.get(`/symptom/firstChar`)
        const firstChar = res.map((item: string) => {
          return { id: item }
        })
        setFirstCharOption(firstChar)
      } catch (e) {
        console.log(e)
      }
    }

    getFirstChar()
  }, [])

  return (
    <>
      <Form.List name="symptomList" initialValue={[]}>
        {(fields, { add, remove }) => (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography sub4 color="black90">
                โรค / อาการที่เคลมยานี้ได้
              </Typography>
              <div style={{ display: 'flex', gap: 5 }}>
                <Button
                  type="secondary"
                  size="small"
                  htmlType="button"
                  onClick={async () => {
                    // form.setFieldsValue({
                    //   symptomList: (copy || [])?.map((item: any) => item.id),
                    // })
                    form?.setFieldsValue({ symptomList: copy })
                    // setSelectedSymptomObj(copy || [])
                  }}
                  title="วาง"
                />
                <Button
                  size="small"
                  htmlType="button"
                  title="คัดลอก"
                  onClick={onCopy}
                />
              </div>
            </div>
            {fields.map((field, idx) => (
              <div key={idx}>
                <CreateGroupItem
                  idx={idx}
                  form={form}
                  remove={remove}
                  firstCharOption={firstCharOption}
                />
              </div>
            ))}
            <Button
              htmlType="button"
              type="text"
              iconLeft={<img style={{ width: 23, height: 23 }} src={IconAdd} />}
              onClick={() => {
                add()
              }}
              title="เพิ่มหมวดโรค / อาการ"
              style={{ padding: 0, marginBottom: 16 }}
            />
          </>
        )}
      </Form.List>
    </>
  )
}
