/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Form, Row, Modal as AntModal, Select, message } from 'antd'
import Button from '../../../../components/Button'
import Typography from '../../../../components/Typography'
import { Symptom, UserSymptom } from '../../../../interface'
import request from '../../../../utils/request'
import DatePicker from '../../../../components/DatePicker'
import dayjs from 'dayjs'

interface Props {
  id?: string
  selectUserSymptomId: number | undefined
  setSelectUserSymptomId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >
  isModalVisible: boolean
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>
}

type FormValues = {
  symptomId: number
  type: 'chronic' | 'infectious' | 'congenital'
  detectionDate: string
}

const { Option } = Select

const ModalDisease = ({
  id,
  selectUserSymptomId,
  setSelectUserSymptomId,
  isModalVisible,
  setIsModalVisible,
  setRefresh,
}: Props): JSX.Element => {
  const [form] = Form.useForm()
  const [symptomOption, setSymptomOption] = useState<Symptom[]>([])
  const [data, setData] = useState<UserSymptom>()
  const [disabled, setDisabled] = useState(false)

  const onClose = () => {
    setIsModalVisible(false)
    setSelectUserSymptomId(undefined)
    form.resetFields()
  }

  const getSymptom = async () => {
    try {
      const res: Symptom[] = await request.get(`/symptom/all`)
      setSymptomOption(res)
    } catch (error) {
      message.error('เรียกข้อมูลไม่สำเร็จ')
    }
  }

  useEffect(() => {
    getSymptom()
  }, [])

  const getUserSymptom = async () => {
    try {
      if (selectUserSymptomId) {
        const res: UserSymptom = await request.get(
          `/insured/${id}/symptom/${selectUserSymptomId}`
        )
        setData(res)
      } else {
        setData(undefined)
      }
    } catch (error) {
      message.error('เรียกข้อมูลไม่สำเร็จ')
    }
  }

  useEffect(() => {
    getUserSymptom()
  }, [selectUserSymptomId])

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        symptomId: data?.symptomId,
        type: data?.type,
        detectionDate: dayjs(data?.detectionDate),
      })
    } else {
      form.resetFields()
    }
  }, [data])

  const onFinish = async (values: FormValues) => {
    const { symptomId, type, detectionDate } = values
    setDisabled(true)
    try {
      if (selectUserSymptomId) {
        await request.put(`/insured/${id}/symptom/${selectUserSymptomId}`, {
          symptomId,
          type,
          detectionDate,
        })
        message.success('แก้ไขรายการสำเร็จ')
      } else {
        await request.post(`/insured/${id}/symptom`, {
          symptomId,
          type,
          detectionDate,
        })
        message.success('เพิ่มรายการสำเร็จ')
      }
      setIsModalVisible(false)
      setSelectUserSymptomId(undefined)
      form.resetFields()
      setRefresh?.((prev) => !prev)
      setDisabled(false)
    } catch (e) {
      message.error('ดำเนินการผิดพลาด')
    }
  }

  return (
    <AntModal
      centered
      visible={isModalVisible}
      style={{ borderRadius: 8 }}
      bodyStyle={{ overflowX: 'scroll', maxHeight: 500 }}
      width={700}
      title={
        <Typography title1 color="black90">
          {selectUserSymptomId ? 'แก้ไขข้อมูล' : 'เพิ่มโรคประจำตัว'}
        </Typography>
      }
      footer={null}
      onCancel={onClose}
    >
      <Form
        form={form}
        name="UserSymptomDetail"
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          label={
            <Typography sub4 color="black65">
              โรคประจำตัว
            </Typography>
          }
          name="symptomId"
        >
          <Select
            className="ant-input-class"
            placeholder="เลือกโรคประจำตัว"
            listHeight={128}
            showSearch
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            {symptomOption?.map((x) => {
              return (
                <Option key={x.id} value={x.id}>
                  {x.name}
                </Option>
              )
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label={
            <Typography sub4 color="black65">
              ประเภทโรค
            </Typography>
          }
          name="type"
        >
          <Select
            className="ant-input-class"
            placeholder="เลือกประเภทโรค"
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            <Option value="chronic">โรคเรื้อรัง</Option>
            <Option value="infectious">โรคติดเชื้อ / อุบัติเหตุ</Option>
            <Option value="congenital">โรคที่เป็นแต่กำเนิด</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={
            <Typography sub4 color="black65">
              วันที่ตรวจพบ
            </Typography>
          }
          name="detectionDate"
        >
          <DatePicker placeholder="เลือกวันที่" />
        </Form.Item>
        <Row
          justify="space-between"
          align="middle"
          style={{ justifyContent: 'center' }}
        >
          <Button
            htmlType="submit"
            title="บันทึก"
            disabled={disabled}
            style={{ width: '48%' }}
          />
        </Row>
      </Form>
    </AntModal>
  )
}

export default ModalDisease
