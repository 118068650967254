/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, FormInstance, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import Typography from '../../components/Typography'
import request from '../../utils/request'
import SelectTag from '../../components/SelectTag/Symptom'
import { Symptom } from '../../interface'
import _ from 'lodash'
interface Props {
  form: FormInstance<any>
  firstCharOption: any[]
  remove: (index: number | number[]) => void
  idx: number
}
const { Option } = Select

export const CreateGroupItem = ({
  form,
  idx,
  remove,
  firstCharOption,
}: Props): JSX.Element => {
  const [icdOption, setIcdOption] = useState<Symptom[]>([])
  const [selectedICD, setSelectedICD] = useState<number[]>([])
  const [selectedMain, setSelectedMain] = useState('')
  const firstChar = form.getFieldValue([`symptomList`])[idx]?.mainGroup

  useEffect(() => {
    const getICD = async () => {
      try {
        const res: any = await request.get(
          // searchValue
          //   ? `/symptom/char/${selectFirstChar}?page=1&limit=100&keyword=${searchValue}`
          //   :
          `/symptom/char/${firstChar}?page=1&limit=100`
        )
        setIcdOption([
          { id: 'all', name: 'เลือกทั้งหมด', icd10Code: 'All' },
          ...res,
        ])
      } catch (error) {
        // message.error('เรียกข้อมูลไม่สำเร็จ')
        console.log(error)
      }
    }
    if (firstChar && selectedMain === '') {
      getICD()
      setSelectedICD([])
    } else if (firstChar) {
      getICD()
      const symptomList = form.getFieldValue([`symptomList`])
      const cloneList = _.cloneDeep(symptomList)
      const symptom = _.set(cloneList, [idx, `icd10Code`], [])
      form.setFieldsValue({ symptomList: symptom })
    } else {
      // ยังไม่เลือกหมวด
      setIcdOption([])
    }
  }, [firstChar])

  return (
    <>
      <Form.Item
        label={
          <div
            style={{
              width: 700,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography sub4 color="black65">
              หมวด
            </Typography>
            <div
              onClick={() => {
                remove(idx)
              }}
            >
              <Typography color="error" style={{ cursor: 'pointer' }}>
                ลบ
              </Typography>
            </div>
          </div>
        }
        name={[idx, `mainGroup`]}
        rules={[{ required: true, message: 'กรุณาเลือกหมวด' }]}
      >
        <Select
          className="ant-input-class"
          listHeight={128}
          // showSearch
          // onSearch={onSearch}
          // optionFilterProp="children"
          onChange={(e: any) => setSelectedMain(e)}
          getPopupContainer={(trigger) => trigger.parentNode}
        >
          {firstCharOption?.map((x) => {
            return (
              <Option key={x.id} value={x.id}>
                {x.id}
              </Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item
        style={{ width: '100%' }}
        label={
          <div>
            <Typography sub4 color="black65">
              ICD10
            </Typography>
            {/* <Checkbox onChange={(e: CheckboxChangeEvent) => onCheckedAll(e)}>
              Select All
            </Checkbox> */}
          </div>
        }
      >
        <SelectTag
          form={form}
          formName={[idx, `icd10Code`]}
          rules={[{ required: true, message: 'กรุณาเลือก ICD10' }]}
          tagColor="blue"
          tagKey={`icd`}
          options={icdOption}
          selectedItems={selectedICD}
          setSelectedItems={setSelectedICD}
          // setSelectedObj={setSelectedIcdObj}
          isOpen={true}
          // setSearchValue={setSearchValue}
        />
      </Form.Item>
    </>
  )
}
