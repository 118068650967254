/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction } from 'react'
import { Divider, Form, Row } from 'antd'
import Button from '../../../components/Button'
import Typography from '../../../components/Typography'
import { CaseDiagnosis } from '../../../interface'
import { scrollToTop } from '../../../utils/getName'
import DownloadLink from '../../../components/DownloadLink'

interface Props {
  id?: string
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
  setStep?: Dispatch<SetStateAction<number>> | undefined
  data: CaseDiagnosis | undefined
}

const Step4 = ({ setStep, data }: Props): JSX.Element => {
  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <Form.Item
          label={
            <Typography sub4 color="black65">
              เหตุผลของการแอดมิท
            </Typography>
          }
        >
          <Typography sub1 color="black">
            {data?.diagnosis.admitReason || '-'}
          </Typography>
          <Divider style={{ borderColor: '#CDCDCD', margin: '10px 0px' }} />
          <DownloadLink data={data?.diagnosis.admitReasonAttachments} />
        </Form.Item>
        <Form.Item
          style={{ width: '100%' }}
          label={
            <Typography sub4 color="black65">
              แผนการรักษา
            </Typography>
          }
        >
          <Typography sub1 color="black">
            {data?.diagnosis.treatmentPlan || '-'}
          </Typography>
          <Divider style={{ borderColor: '#CDCDCD', margin: '10px 0px' }} />
          <DownloadLink data={data?.diagnosis.treatmentPlanAttachments} />
        </Form.Item>
      </div>
      <Row justify="space-between" align="middle">
        <Button
          htmlType="button"
          title="ย้อนกลับ"
          type="secondary"
          style={{ width: '48%' }}
          onClick={() => {
            scrollToTop()
            setStep?.(3)
          }}
        />
        <Button
          htmlType="button"
          title="ต่อไป"
          style={{ width: '48%' }}
          onClick={() => {
            scrollToTop()
            setStep?.(5)
          }}
        />
      </Row>
    </div>
  )
}

export default Step4
