/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction } from 'react'
import { Form, Row, Checkbox, Col, Divider } from 'antd'
import Button from '../../../components/Button'
import Typography from '../../../components/Typography'
import { CaseDiagnosis } from '../../../interface'
import { scrollToTop } from '../../../utils/getName'
import DownloadLink from '../../../components/DownloadLink'
import DatePicker from '../../../components/DatePicker'
import TimePicker from '../../../components/TimePicker'

import dayjs from 'dayjs'

interface Props {
  id?: string
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
  setStep?: Dispatch<SetStateAction<number>> | undefined
  data: CaseDiagnosis | undefined
}

const Step2 = ({ setStep, data }: Props): JSX.Element => {
  return (
    <>
      <Form.Item
        label={
          <Typography sub4 color="black65">
            อาการบาดเจ็บทางร่างกาย
          </Typography>
        }
      >
        <Typography sub1 color="black">
          {data?.diagnosis.physicalInjury || '-'}
        </Typography>
        <Divider style={{ borderColor: '#CDCDCD', margin: '10px 0px' }} />
        <DownloadLink data={data?.diagnosis.physicalInjuryAttachments} />
      </Form.Item>
      <Form.Item
        style={{ width: '100%' }}
        label={
          <Typography sub4 color="black65">
            ประวัติการรักษา (วันและสถานที่)
          </Typography>
        }
      >
        <Typography sub1 color="black">
          {data?.diagnosis.treatmentHistory || '-'}
        </Typography>
        <Divider style={{ borderColor: '#CDCDCD', margin: '10px 0px' }} />
        <DownloadLink data={data?.diagnosis.treatmentHistoryAttachments} />
      </Form.Item>
      <Form.Item
        style={{ width: '100%' }}
        label={
          <Typography sub4 color="black65">
            อาการป่วยนี้เกี่ยวข้องกับปัจจัยต่อไปนี้
          </Typography>
        }
        name="symptomFactors"
      >
        <Checkbox.Group style={{ width: '100%', lineHeight: 2 }} disabled>
          <Row>
            <Col span={24}>
              <Checkbox value="pregnancy">
                <Typography sub2 color="black">
                  Pregnancy/ Childbirth/ Infertility/ Cacesarem section/
                  Miscarriage
                </Typography>
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="drug">
                <Typography sub2 color="black">
                  Influence of Drugs/ Alcohol
                </Typography>
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="mental">
                <Typography sub2 color="black">
                  Nervous/ Mental/ Emotional/ Sleeping disorder
                </Typography>
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="cosmetic">
                <Typography sub2 color="black">
                  Cosmetic reason/ Dental care/ Refractive errors correction
                </Typography>
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="aids">
                <Typography sub2 color="black">
                  AIDS
                </Typography>
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="congenital" style={{ marginBottom: 5 }}>
                <Typography sub2 color="black">
                  Cognenital/ Hereditary disease
                </Typography>
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="accident">
                <Row align="middle">
                  <Col span={12} style={{ marginBottom: 10 }}>
                    <Typography sub2 color="black">
                      Date of accident
                    </Typography>
                  </Col>
                  <Col span={12} style={{ marginBottom: 10 }}>
                    <DatePicker
                      disabled
                      value={
                        data?.diagnosis.accidentDate
                          ? dayjs(data?.diagnosis.accidentDate)
                          : undefined
                      }
                    />
                  </Col>
                  <Col span={12} style={{ marginBottom: 10 }}>
                    <Typography sub2 color="black">
                      Time of accident
                    </Typography>
                  </Col>
                  <Col span={12} style={{ marginBottom: 10 }}>
                    <Form.Item name="accidentTime" noStyle>
                      <TimePicker disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </Form.Item>
      <Row justify="space-between" align="middle" style={{ paddingBottom: 24 }}>
        <Button
          htmlType="button"
          title="ย้อนกลับ"
          type="secondary"
          style={{ width: '48%' }}
          onClick={() => {
            scrollToTop()
            setStep?.(1)
          }}
        />
        <Button
          htmlType="button"
          title="ต่อไป"
          style={{ width: '48%' }}
          onClick={() => {
            scrollToTop()
            setStep?.(3)
          }}
        />
      </Row>
    </>
  )
}

export default Step2
