import React, { useState } from 'react'
import { Upload, message, Row, Col } from 'antd'
import styled from '@emotion/styled'
import Typography from '../Typography'
import Button from '../../components/Button'
import request from '../../utils/request'

const baseUrl = process.env.REACT_APP_API_URL

const NameImage = styled.div`
  width: auto;
  padding: 5px 10px;
  align-items: center;
  border-radius: 10px;
  margin-top: 10px;
  justify-content: center;

  &:hover {
    transition: 0.3s;
    background-color: #bdbdbd29;
    border-radius: 10px;
  }
`
const CurrentImage = styled.img`
  width: 140px;
  height: 140px;
`
const UploadButton = (): JSX.Element => (
  <Button title="แก้ไขรูปประจำตัว" type="secondary" style={{ width: 140 }} />
)
interface UrlState {
  mimetype: string
  originalname: string
  size: number
  storageUrl: string
  id: number
}

interface Props {
  onChange?: (
    response:
      | {
          mimetype: string
          originalname: string
          size: number
          storageUrl: string
        }
      | null
      | undefined
  ) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any
  setUrlId: React.Dispatch<React.SetStateAction<string | undefined>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  id: any
}

const UploadPhoto = ({ onChange, value, setUrlId, id }: Props): JSX.Element => {
  const [url, setUrl] = useState<UrlState | null>(null)
  const token = localStorage.getItem('accessToken')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onImageChange = async ({ file }: any) => {
    if (file.response) {
      setUrl(file.response)
      setUrlId(file.response.id)

      try {
        await request.patch(`/insured/${id}/image`, {
          image: file.response.id,
        })
        message.success('แก้ไขรูปสำเร็จ')
      } catch (e) {
        message.error('ดำเนินการผิดพลาด')
        console.log(e)
      }
    }
  }
  const beforeUpload = async (file: { type: string; size: number }) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!')
    }

    return isJpgOrPng && isLt2M
  }

  return (
    <>
      <Upload
        name="file"
        action={`${baseUrl}/container`}
        beforeUpload={beforeUpload}
        showUploadList={false}
        headers={{
          accept: 'application/json',
          Authorization: token ? `Bearer ${token}` : '',
        }}
        onChange={(info) => {
          const { response } = info.file
          onImageChange(info)
          onChange?.(response)
        }}
        maxCount={1}
        accept="image/*"
      >
        {value ? (
          <>
            <CurrentImage
              src={`${baseUrl}/container/${
                url?.id ? url?.id : value
              }/?access_token=${token}`}
              alt=""
            />
          </>
        ) : (
          <UploadButton />
        )}
      </Upload>
      {url !== null && (
        <NameImage>
          <Row align="middle">
            <Col span={23}>
              <Typography
                style={{
                  transition: '0.5s',
                }}
              >
                {url?.originalname}
              </Typography>
            </Col>
          </Row>
        </NameImage>
      )}
    </>
  )
}

export default UploadPhoto
