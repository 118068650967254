/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction, useState, useEffect } from 'react'
import { Form, Row, Modal as AntModal, Input, Col, message } from 'antd'
import Button from '../../components/Button'
import Typography from '../../components/Typography'
import IconAdd from '../../image/IconAdd.png'
import { MedicalService } from '../../interface'
import request from '../../utils/request'
import AlertModal from '../../components/AlertModal'

interface Props {
  id?: string | number
  isModalVisible: boolean
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
  setRefresh?: Dispatch<SetStateAction<boolean>>
}

type FormValues = {
  name: string
  otherNames: string[]
  icd9Code: string | null
  icd10Code: string | null
  minIpd: string
  maxIpd: string
  minOpd: string
  maxOpd: string
}

const Modal = ({
  id,
  isModalVisible,
  setIsModalVisible,
  setRefresh,
}: Props): JSX.Element => {
  const [form] = Form.useForm()
  const [otherNameIndex, setOtherNameIndex] = useState(1)
  const [data, setData] = useState<MedicalService>()
  const [openConfirmModal, setOpenConfirmModal] = useState(false)

  const getData = async () => {
    if (id) {
      try {
        const res: MedicalService = await request.get(`/medical-service/${id}`)
        setData(res)
      } catch (error) {
        console.log(error)
        message.error('เรียกข้อมูลไม่สำเร็จ')
        setData(undefined)
      }
      return
    }
    setData(undefined)
  }

  useEffect(() => {
    getData()
  }, [id])

  useEffect(() => {
    if (data) {
      setOtherNameIndex(data?.otherNames.length)
    } else {
      setOtherNameIndex(1)
    }
    form.setFieldsValue({
      name: data?.name,
      otherNames: data?.otherNames,
      icd9Code: data?.icd9Code,
      icd10Code: data?.icd10Code,
      minIpd: data?.minIpd,
      maxIpd: data?.maxIpd,
      minOpd: data?.minOpd,
      maxOpd: data?.maxOpd,
    })
  }, [data])

  const onClose = () => {
    setIsModalVisible(false)
    if (!id) {
      form.resetFields()
      setOtherNameIndex(1)
    }
  }

  const onFinish = async (values: FormValues) => {
    const {
      name,
      otherNames,
      minIpd,
      maxIpd,
      minOpd,
      maxOpd,
      icd9Code,
      icd10Code,
    } = values
    try {
      if (id) {
        await request.put(`/medical-service/${id}`, {
          name,
          otherNames,
          icd9Code: icd9Code || null,
          icd10Code: icd10Code || null,
          minIpd: parseInt(minIpd),
          maxIpd: parseInt(maxIpd),
          minOpd: parseInt(minOpd),
          maxOpd: parseInt(maxOpd),
        })
        message.success('แก้ไขรายการสำเร็จ')
      } else {
        await request.post('/medical-service', {
          name,
          otherNames,
          icd9Code: icd9Code ? icd9Code : null,
          icd10Code: icd10Code ? icd10Code : null,
          minIpd: parseInt(minIpd),
          maxIpd: parseInt(maxIpd),
          minOpd: parseInt(minOpd),
          maxOpd: parseInt(maxOpd),
        })
        message.success('เพิ่มรายการสำเร็จ')
      }
      onClose()
      setRefresh?.((prev) => !prev)
    } catch (e: any) {
      message.error('ดำเนินการผิดพลาด')
      if (e.response.status === 400) {
        setOpenConfirmModal(true)
      }
    }
  }

  const createElements = (n: number) => {
    const elements = []
    for (let i = 0; i < n; i++) {
      elements.push(
        i === 0 ? (
          <Form.Item
            name={['otherNames', i]}
            className="tradeName"
            rules={[{ required: true, message: 'กรุณากรอกชื่อเสมือน' }]}
          >
            <Input className="ant-input-class" />
          </Form.Item>
        ) : (
          <Form.Item className="tradeName">
            <Row gutter={10} align="middle">
              <Col span={22}>
                <Form.Item
                  name={['otherNames', i]}
                  noStyle
                  rules={[{ required: true, message: 'กรุณากรอกชื่อเสมือน' }]}
                >
                  <Input className="ant-input-class" />
                </Form.Item>
              </Col>
              <Col span={2}>
                <a onClick={() => setOtherNameIndex((prev) => prev - 1)}>
                  <Typography color="primary1">ลบ</Typography>
                </a>
              </Col>
            </Row>
          </Form.Item>
        )
      )
    }
    return elements
  }

  return (
    <>
      <AntModal
        centered
        visible={isModalVisible}
        style={{ borderRadius: 8 }}
        bodyStyle={{ overflowX: 'scroll', maxHeight: 500 }}
        width={700}
        title={
          <Typography title1 color="black90">
            {id
              ? 'แก้ไขรายชื่อบริการทางการแพทย์'
              : 'เพิ่มรายชื่อบริการทางการแพทย์'}
          </Typography>
        }
        footer={null}
        onCancel={onClose}
      >
        <Form
          form={form}
          name="MedicalServiceModal"
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            label={
              <Typography sub4 color="black65">
                ชื่อบริการ
              </Typography>
            }
            name="name"
            rules={[{ required: true, message: 'กรุณากรอกชื่อบริการ' }]}
          >
            <Input className="ant-input-class" />
          </Form.Item>
          <Form.Item
            required
            label={
              <Typography sub4 color="black65">
                ราคา IPD (บาท)
              </Typography>
            }
            style={{ margin: 0 }}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="minIpd"
                  rules={[{ required: true, message: 'กรุณากรอกราคา IPD' }]}
                >
                  <Input
                    placeholder="ต่ำสุด"
                    className="ant-input-class"
                    type="number"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="maxIpd"
                  rules={[{ required: true, message: 'กรุณากรอกราคา IPD' }]}
                >
                  <Input
                    placeholder="สูงสุด"
                    className="ant-input-class"
                    type="number"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            required
            label={
              <Typography sub4 color="black65">
                ราคา OPD (บาท)
              </Typography>
            }
            style={{ margin: 0 }}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="minOpd"
                  rules={[{ required: true, message: 'กรุณากรอกราคา IPD' }]}
                >
                  <Input
                    placeholder="ต่ำสุด"
                    className="ant-input-class"
                    type="number"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="maxOpd"
                  rules={[{ required: true, message: 'กรุณากรอกราคา IPD' }]}
                >
                  <Input
                    placeholder="สูงสุด"
                    className="ant-input-class"
                    type="number"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            label={
              <Typography sub4 color="black65">
                ICD-9 CM
              </Typography>
            }
            name="icd9Code"
          >
            <Input className="ant-input-class" />
          </Form.Item>
          <Form.Item
            label={
              <Typography sub4 color="black65">
                ชื่อเสมือน
              </Typography>
            }
            required={true}
          >
            {createElements(otherNameIndex)}
            <Button
              htmlType="button"
              type="text"
              title="เพิ่มชื่อเสมือน"
              iconLeft={<img style={{ width: 23, height: 23 }} src={IconAdd} />}
              style={{ padding: 0 }}
              onClick={() => setOtherNameIndex((prev) => prev + 1)}
            />
          </Form.Item>
          <Row justify="center" align="middle">
            <Button htmlType="submit" title="บันทึก" style={{ width: '50%' }} />
          </Row>
        </Form>
      </AntModal>
      <AlertModal
        type="confirm"
        title="มีรายการนี้อยู่แล้ว"
        isModalVisible={openConfirmModal}
        onSubmit={() => {
          setOpenConfirmModal(false)
        }}
      />
    </>
  )
}

export default Modal
