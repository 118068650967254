/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import PageLayout from '../../../layouts/PageLayout'
import Card from '../../../components/Card'
import Button from '../../../components/Button'
import Typography from '../../../components/Typography'
import {
  Table,
  Space,
  Input,
  Form,
  message,
  Pagination,
  DatePicker,
} from 'antd'
import theme from '../../../utils/theme'
import Modal from './Modal'
import { useHistory, useRouteMatch } from 'react-router'
import PlusCircle from '../../../image/PlusCircle.png'
import request from '../../../utils/request'
import { Insurance } from '../../../interface'
import AlertModal from '../../../components/AlertModal'
import dayjs from 'dayjs'
import { SearchOutlined } from '@ant-design/icons'

const List = (): JSX.Element => {
  const [form] = Form.useForm()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { path } = useRouteMatch()
  const history = useHistory()
  const [page, setPage] = useState<number>(1)
  const [total, setTotal] = useState<number>()
  const [claimingDetailList, setClaimingDetaiList] = useState<Insurance[]>([])
  const [refresh, setRefresh] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [selectId, setSelectId] = useState<number | undefined>(undefined)

  const getClaimingDetailList = async () => {
    try {
      const res: any = await request.get('/claiming-detail', {
        params: {
          page: page,
          limit: 6,
        },
      })
      setClaimingDetaiList(res.data)
      setTotal(res.total)
    } catch (error) {
      console.log(error)
    }
  }

  const onDelete = async (id: number | undefined) => {
    try {
      await request.delete(`/claiming-detail/${id}`)
      message.success('ลบสำเร็จ')
      setOpenConfirmModal(false)
      setRefresh?.((prev) => !prev)
    } catch (err) {
      message.error('ดำเนินการผิดพลาด')
      console.log(err)
    }
  }

  const onChange = async (e: any) => {
    try {
      const res: any = await request.get('/claiming-detail', {
        params: {
          page: page,
          limit: 6,
          hn: e.target.value ? e.target.value : null,
          date: form.getFieldValue('date')
            ? dayjs(form.getFieldValue('date')).format('YYYY-MM-DD HH:mm')
            : null,
        },
      })
      setClaimingDetaiList(res.data)
      setTotal(res.total)
    } catch (error) {
      console.log(error)
    }
  }

  const onChangeDate = async (date: any) => {
    try {
      const params = {
        page: page,
        limit: 6,
        hn: form.getFieldValue('search') ? form.getFieldValue('search') : null,
        date: date ? dayjs(date).format('YYYY-MM-DD') : null,
      }
      const res: any = await request.get('/claiming-detail', { params })
      setClaimingDetaiList(res.data)
      setTotal(res.total)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getClaimingDetailList()
  }, [page, refresh])

  const columns = [
    {
      title: <Typography sub1>รหัส</Typography>,
      dataIndex: 'id',
      key: 'id',
      render: (text: string) => (
        <Typography sub2>{String(text).padStart(3, '0')}</Typography>
      ),
    },
    {
      title: <Typography sub1>วัน-เวลา</Typography>,
      dataIndex: 'admissionDateTime',
      key: 'admissionDateTime',
      render: (text: string) => (
        <Typography sub2 color="black90">
          {dayjs(text).format('YYYY-MM-DD HH:mm:ss')}
        </Typography>
      ),
    },
    {
      title: <Typography sub1>EN</Typography>,
      dataIndex: 'en',
      key: 'en',
      render: (text: string) => (
        <Typography sub2 color="black90">
          {text}
        </Typography>
      ),
    },
    {
      title: <Typography sub1>HN</Typography>,
      dataIndex: 'hn',
      key: 'hn',
      render: (text: string) => (
        <Typography sub2 color="black90">
          {text}
        </Typography>
      ),
    },
    {
      title: <Typography sub1>อาการสำคัญ</Typography>,
      dataIndex: 'chiefComplaint',
      key: 'chiefComplaint',
      render: (text: string) => (
        <Typography sub2 color="black90">
          {text}
        </Typography>
      ),
    },
    {
      title: '',
      key: 'action',
      render: (_: any, record: Insurance) => (
        <Space size="middle">
          <a onClick={() => history.push(`${path}/edit/${record.id}`)}>
            <Typography sub1 color="primary2">
              แก้ไข
            </Typography>
          </a>
          <a
            onClick={() => {
              setSelectId(record.id)
              setOpenConfirmModal(true)
            }}
          >
            <Typography sub1 color="primary2">
              ลบ
            </Typography>
          </a>
        </Space>
      ),
    },
  ]

  return (
    <PageLayout
      title="ใบเคลม"
      shownBack={false}
      radioGroup={
        <div className="row">
          <Button
            title="เพิ่ม"
            type="primary"
            iconLeft={
              <img style={{ width: 23, height: 23 }} src={PlusCircle} />
            }
            onClick={() => setIsModalVisible(true)}
          />
        </div>
      }
    >
      <Card style={{ padding: '0px 16px' }}>
        <br />
        <Form form={form} name="ClaimList" layout="vertical">
          <div style={{ display: 'flex' }}>
            <Form.Item name="search" style={{ marginBottom: 0, width: '100%' }}>
              <Input
                className="ant-input-class"
                placeholder="ค้นหา HN"
                allowClear
                onChange={onChange}
                addonAfter={<SearchOutlined style={{ color: theme.black40 }} />}
              />
            </Form.Item>
            <Form.Item name="date">
              <DatePicker
                onChange={onChangeDate}
                style={{ width: 180, marginLeft: 20 }}
                placeholder="เลือกวันที่"
                allowClear
              />
            </Form.Item>
          </div>
        </Form>
        <Table
          columns={columns}
          dataSource={claimingDetailList}
          pagination={false}
        />
        <Pagination
          current={page}
          pageSize={6}
          total={total}
          style={{ padding: '20px 0px', textAlign: 'end' }}
          onChange={(page: number) => setPage(page)}
          showSizeChanger={false}
        />
      </Card>
      <Modal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
      <AlertModal
        type="confirm"
        title="คุณยืนยันที่จะลบรายชื่อกรมธรรม์นี้ใช่หรือไม่"
        isModalVisible={openConfirmModal}
        onCancel={() => setOpenConfirmModal(false)}
        onSubmit={() => {
          onDelete(selectId)
        }}
      />
    </PageLayout>
  )
}

export default List
