/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction, useState } from 'react'
import { Form, Row, Radio, Space, Input, Divider } from 'antd'
import Button from '../../../components/Button'
import Typography from '../../../components/Typography'
import { CaseDiagnosis } from '../../../interface'
import { scrollToTop } from '../../../utils/getName'
import DownloadLink from '../../../components/DownloadLink'

interface Props {
  id?: string
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
  setStep?: Dispatch<SetStateAction<number>> | undefined
  data: CaseDiagnosis | undefined
}

const Step3 = ({ setStep, data }: Props): JSX.Element => {
  const [valueState] = useState(
    data?.diagnosis.isTreatable ? data?.diagnosis.isTreatable : true
  )
  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <Form.Item
          label={
            <Typography sub4 color="black65">
              มีภาวะอื่นอยู่ก่อน
            </Typography>
          }
        >
          <Typography sub1 color="black">
            {data?.diagnosis.otherCondition || '-'}
          </Typography>
          <Divider style={{ borderColor: '#CDCDCD', margin: '10px 0px' }} />
          <DownloadLink data={data?.diagnosis.otherConditionAttachments} />
        </Form.Item>
        <Form.Item
          style={{ width: '100%' }}
          label={
            <Typography sub4 color="black65">
              การวินิจฉัยเบื้องต้น
            </Typography>
          }
          name="logo"
        >
          <Typography sub1 color="black">
            {data?.diagnosis.initialDiagnosis.map((item, index) => {
              return index !== data?.diagnosis.initialDiagnosis.length - 1
                ? `${item.name}, `
                : `${item.name}`
            }) || '-'}
          </Typography>
          <Divider style={{ borderColor: '#CDCDCD', margin: '10px 0px' }} />
          <DownloadLink data={data?.diagnosis.initialDiagnosisAttachments} />
        </Form.Item>
        <Form.Item
          style={{ width: '100%' }}
          label={
            <Typography sub4 color="black65">
              อาการนี้รักษาได้หรือไม่
            </Typography>
          }
          name="isTreatable"
        >
          <Radio.Group disabled>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Radio value={true}>
                <Typography sub2 color="black">
                  ได้
                </Typography>
              </Radio>
              <Radio value={false}>
                <Typography
                  sub2
                  color="black"
                  style={{ padding: '0px 0px 8px' }}
                >
                  ไม่ได้ (ถ้าไม่ได้ โปรดระบุ)
                </Typography>
                <Form.Item name="untreatableReason" noStyle>
                  {!valueState ? (
                    <Input className="ant-input-class" disabled />
                  ) : null}
                </Form.Item>
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
      </div>
      <Row justify="space-between" align="middle">
        <Button
          htmlType="button"
          title="ย้อนกลับ"
          type="secondary"
          style={{ width: '48%' }}
          onClick={() => {
            scrollToTop()
            setStep?.(2)
          }}
        />
        <Button
          htmlType="button"
          title="ต่อไป"
          style={{ width: '48%' }}
          onClick={() => {
            scrollToTop()
            setStep?.(4)
          }}
        />
      </Row>
    </div>
  )
}

export default Step3
