/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  Form,
  Row,
  Modal as AntModal,
  Input,
  Checkbox,
  Col,
  message,
  Select,
} from 'antd'
import Button from '../../components/Button'
import Typography from '../../components/Typography'
import request from '../../utils/request'
import { Admin } from '../../interface'

interface Props {
  id?: string | number
  isModalVisible: boolean
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
  setRefresh?: Dispatch<SetStateAction<boolean>>
}

type FormValues = {
  email: string
  firstName: string
  lastName: string
  role:
    | 'admin'
    | 'history taking officer'
    | 'claims officer'
    | 'financial officer'
  careProviderId?: string
  permissions: []
}

const { Option } = Select

const Modal = ({
  id,
  isModalVisible,
  setIsModalVisible,
  setRefresh,
}: Props): JSX.Element => {
  const [form] = Form.useForm()
  const [data, setData] = useState<Admin>()

  const onClose = () => {
    setIsModalVisible(false)
    if (!id) {
      form.resetFields()
    }
  }

  const onFinish = async (values: FormValues) => {
    const { email, firstName, lastName, role, careProviderId, permissions } =
      values
    try {
      if (id) {
        await request.put(`/admin/${id}`, {
          firstName,
          lastName,
          role,
          careProviderId,
          permissions,
        })
        message.success('แก้ไขรายการสำเร็จ')
      } else {
        await request.post('/admin', {
          email,
          firstName,
          lastName,
          role,
          careProviderId,
          permissions,
        })
        message.success('เพิ่มรายการสำเร็จ')
      }
      onClose()
      setRefresh?.((prev) => !prev)
    } catch (e) {
      message.error('ดำเนินการผิดพลาด')
      console.log(e)
    }
  }

  const getData = async () => {
    if (id) {
      try {
        const res: Admin = await request.get(`/admin/${id}`)
        setData(res)
      } catch (error) {
        message.error('เรียกข้อมูลไม่สำเร็จ')
        setData(undefined)
      }
      return
    }
    setData(undefined)
  }

  useEffect(() => {
    getData()
  }, [id])

  useEffect(() => {
    form.setFieldsValue({
      email: data?.email,
      firstName: data?.firstName,
      lastName: data?.lastName,
      careProviderId: data?.careProviderId,
      permissions: data?.permissions,
      role: data?.role,
    })
  }, [data])

  return (
    <AntModal
      centered
      visible={isModalVisible}
      style={{ borderRadius: 8, top: '40px' }}
      bodyStyle={{ overflowX: 'scroll', maxHeight: 500 }}
      width={700}
      title={
        <Typography title1 color="black90">
          {id ? 'แก้ไขสมาชิก' : 'เพิ่มสมาชิก'}
        </Typography>
      }
      footer={null}
      onCancel={onClose}
    >
      <Form
        form={form}
        name="PermissionModal"
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          label={
            <Typography sub4 color="black65">
              ชื่อ
            </Typography>
          }
          name="firstName"
        >
          <Input className="ant-input-class" />
        </Form.Item>
        <Form.Item
          label={
            <Typography sub4 color="black65">
              นามสกุล
            </Typography>
          }
          name="lastName"
        >
          <Input className="ant-input-class" />
        </Form.Item>
        <Form.Item
          label={
            <Typography sub4 color="black65">
              อีเมล์
            </Typography>
          }
          name="email"
        >
          <Input className="ant-input-class" disabled={id ? true : false} />
        </Form.Item>
        <Form.Item
          label={
            <Typography sub4 color="black65">
              บทบาท
            </Typography>
          }
          name="role"
        >
          <Select
            allowClear={true}
            placeholder="เลือกบทบาท"
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            <Option value="admin">แอดมิน</Option>
            <Option value="doctor">แพทย์</Option>
            <Option value="history taking officer">
              เจ้าหน้าที่กรอกประวัติ
            </Option>
            <Option value="claims officer">เจ้าหน้าที่สินไหม</Option>
            <Option value="financial officer">เจ้าหน้าที่การเงิน</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={
            <Typography sub4 color="black65">
              Care Provider ID
            </Typography>
          }
          name="careProviderId"
        >
          <Input
            className="ant-input-class"
            placeholder="สำหรับแพทย์เท่านั้น"
          />
        </Form.Item>
        <Form.Item
          label={
            <Typography sub4 color="black65">
              การเข้าถึง
            </Typography>
          }
          name="permissions"
        >
          <Checkbox.Group style={{ width: '100%', lineHeight: 2 }}>
            <Row>
              <Col span={12}>
                <Checkbox value="insurance">กรมธรรม์</Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox value="insured">รายชื่อผู้ใช้</Checkbox>
              </Col>
              {/* <Col span={12}>
                <Checkbox value="diagnosis">วินิจฉัย</Checkbox>
              </Col> */}
              <Col span={12}>
                <Checkbox value="claiming">ใบเคลม</Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox value="accessability">การเข้าถึง</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
        <Row
          justify="space-between"
          align="middle"
          style={{ justifyContent: 'center' }}
        >
          {!id ? (
            <Button
              htmlType="submit"
              title="ส่งคำเชิญ"
              style={{ width: '48%' }}
            />
          ) : (
            <Button htmlType="submit" title="บันทึก" style={{ width: '48%' }} />
          )}
        </Row>
      </Form>
    </AntModal>
  )
}

export default Modal
