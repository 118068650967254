import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Form, message, Modal as AntModal } from 'antd'
import Typography from '../../../../components/Typography'
import Step1 from './Step1'
import Step2 from './Step2'
import request from '../../../../utils/request'
import { useParams } from 'react-router-dom'
import { UserInsurance } from '../../../../interface'

interface Props {
  isModalVisible: boolean
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
  step: number
  setStep: Dispatch<SetStateAction<number>>
  selectUserInsuranceId?: number
  setSelectUserInsuranceId: Dispatch<React.SetStateAction<number | undefined>>
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>
}
interface Params {
  id: string
}

const ModalInsurance = ({
  isModalVisible,
  setIsModalVisible,
  step,
  setStep,
  selectUserInsuranceId,
  setSelectUserInsuranceId,
  setRefresh,
}: Props): JSX.Element => {
  const [form] = Form.useForm()
  const params = useParams<Params>()
  const [data, setData] = useState<UserInsurance>()
  const [selectCompanyId, setSelectCompanyId] = useState<number | undefined>(
    undefined
  )
  const [selectInsuranceId, setSelectInsuranceId] = useState<
    number | undefined
  >(undefined)
  const [disabled, setDisabled] = useState(false)

  const onClose = () => {
    setIsModalVisible(false)
    setSelectUserInsuranceId(undefined)
    setSelectCompanyId(undefined)
    setSelectInsuranceId(undefined)
    setStep(1)
    form.resetFields()
  }

  const Content = useMemo(() => {
    if (step === 1) return Step1
    else if (step === 2) return Step2
    else return Step1
  }, [step])

  const getUserInsurance = async () => {
    try {
      if (selectUserInsuranceId) {
        const res: UserInsurance = await request.get(
          `/insured/${params.id}/plan/${selectUserInsuranceId}`
        )
        setData(res)
      } else {
        setData(undefined)
      }
    } catch (error) {
      message.error('เรียกข้อมูลไม่สำเร็จ')
    }
  }

  useEffect(() => {
    getUserInsurance()
  }, [selectUserInsuranceId])

  const onFinish = async () => {
    setDisabled(true)
    try {
      await request.post(`/insured/${params.id}/plan`, {
        planId: form.getFieldValue('planId'),
        availableDate: form.getFieldValue('availableDate'),
        expiredDate: form.getFieldValue('expiredDate'),
        limit: parseInt(form.getFieldValue('limit')),
        coverageLimitPerYear: parseInt(
          form.getFieldValue('coverageLimitPerYear')
        ),
        roomRatePerMonth: parseInt(form.getFieldValue('roomRatePerMonth')),
        ipd: parseInt(form.getFieldValue('ipd')),
        opd: parseInt(form.getFieldValue('opd')),
        dentalLimit: parseInt(form.getFieldValue('dentalLimit')),
      })
      setRefresh?.((prev) => !prev)
      message.success('บันทึกข้อมูลสำเร็จ')
      setStep?.(1)
      setIsModalVisible(false)
      setSelectUserInsuranceId(undefined)
      setSelectCompanyId(undefined)
      setSelectInsuranceId(undefined)
      form.resetFields()
      setDisabled(false)
    } catch (error) {
      message.error('บันทึกข้อมูลไม่สำเร็จ')
    }
  }

  return (
    <AntModal
      centered
      visible={isModalVisible}
      style={{ borderRadius: 8, top: '30px' }}
      bodyStyle={{ overflowX: 'scroll', maxHeight: 500 }}
      width={700}
      title={
        <Typography title1 color="black90">
          {step === 1
            ? selectUserInsuranceId
              ? 'แก้ไขข้อมูล'
              : 'เพิ่มกรมธรรม์'
            : selectUserInsuranceId
            ? 'แก้ไขวงเงิน'
            : 'เพิ่มวงเงิน'}
        </Typography>
      }
      footer={null}
      onCancel={onClose}
    >
      <Form
        form={form}
        name="UserInsuranceDetail"
        layout="vertical"
        onFinish={onFinish}
      >
        <Content
          setIsModalVisible={setIsModalVisible}
          setStep={setStep}
          setSelectInsuranceId={setSelectInsuranceId}
          selectInsuranceId={selectInsuranceId}
          setSelectCompanyId={setSelectCompanyId}
          selectCompanyId={selectCompanyId}
          setSelectUserInsuranceId={setSelectUserInsuranceId}
          form={form}
          data={data}
          setRefresh={setRefresh}
          setDisabled={setDisabled}
          disabled={disabled}
        />
      </Form>
    </AntModal>
  )
}

export default ModalInsurance
