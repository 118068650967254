import React, { Dispatch, SetStateAction, useState, useEffect } from 'react'
import { Form, message, Modal as AntModal } from 'antd'
import Typography from '../../../components/Typography'
import StepClaim from './StepClaim'
import { CaseDiagnosis, ClaimBilling } from '../../../interface'
import request from '../../../utils/request'
import AlertModal from '../../../components/AlertModal'
import { scrollToTop } from '../../../utils/getName'

interface Props {
  id?: string | number
  isModalVisible: boolean
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
  refresh?: boolean
  type: 'diagnosis' | 'claim'
  setSelectId: React.Dispatch<React.SetStateAction<number | undefined>>
  status?: 'pending' | 'diagnosed' | 'approved'
}

const ModalClaim = ({
  id,
  isModalVisible,
  setIsModalVisible,
  type,
  setSelectId,
  refresh,
}: Props): JSX.Element => {
  const [form] = Form.useForm()
  const [step, setStep] = useState(1)
  const [data, setData] = useState<CaseDiagnosis>()
  const [excludeClaiming, setExcludeClaiming] = useState<ClaimBilling>()
  const pathAPI = type === 'claim' ? `/claiming/${id}` : `/diagnosis/${id}`
  const [openConfirmModal, setOpenConfirmModal] = useState(false)

  const onClose = () => {
    setIsModalVisible(false)
    scrollToTop()
    setStep(1)
    if (!id) {
      form.resetFields()
    }
    setSelectId(undefined)
  }

  const getData = async () => {
    if (id) {
      try {
        const res: CaseDiagnosis = await request.get(pathAPI)
        setData(res)
      } catch (error) {
        message.error('เรียกข้อมูลไม่สำเร็จ')
        setData(undefined)
      }
      return
    }
    setData(undefined)
  }

  const getExcludeClaiming = async () => {
    if (id) {
      try {
        const res: ClaimBilling = await request.get(`/claiming/${id}/billing/`)
        setExcludeClaiming(res)
      } catch (error) {
        console.log(error)
      }
      return
    }
    setExcludeClaiming(undefined)
  }

  useEffect(() => {
    getData()
    getExcludeClaiming()
  }, [id, refresh])

  return (
    <AntModal
      centered
      visible={isModalVisible}
      style={{ borderRadius: 8 }}
      bodyStyle={{ overflowX: 'scroll', height: 500 }}
      width={500}
      title={
        <Typography title1 color="black90">
          ผลการเคลม
        </Typography>
      }
      footer={null}
      onCancel={onClose}
    >
      <Form
        form={form}
        name="ClaimModal"
        layout="vertical"
        style={{ height: '100%' }}
      >
        <StepClaim
          data={data}
          exclude={excludeClaiming}
          setIsModalVisible={setIsModalVisible}
          setStep={setStep}
          step={step}
          form={form}
        />
      </Form>
      <AlertModal
        type="confirm"
        title="เจ้าหน้าที่บริษัทประกันเท่านั้นที่มีสิทธ์ในการอนุมัติการเคลม"
        isModalVisible={openConfirmModal}
        onCancel={() => setOpenConfirmModal(false)}
        onSubmit={() => setOpenConfirmModal(false)}
      />
    </AntModal>
  )
}

export default ModalClaim
