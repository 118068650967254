/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, FormInstance, Select, Tag } from 'antd'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
interface Props {
  form?: FormInstance<any>
  formName: string | (string | number)[]
  options: any[]
  setSelectedItems: React.Dispatch<React.SetStateAction<any[]>>
  setSelectedObj?: React.Dispatch<React.SetStateAction<any[]>>
  setSearchValue?: React.Dispatch<React.SetStateAction<string | undefined>>
  selectedItems: any[]
  isOpen: boolean
  tagColor: 'red' | 'blue'
  tagKey: string
  height?: number
  rules?: any
  label?: any
}
interface TagsProps {
  label: any
  value: any
  onClose: any
  closable: any
}

const { Option } = Select

const Symptom = ({
  form,
  formName,
  options,
  setSelectedItems,
  setSelectedObj,
  isOpen,
  tagColor,
  tagKey,
  rules,
  label,
  setSearchValue,
}: Props): JSX.Element => {
  const [openSelectTag, setOpenSelectTag] = useState(isOpen)

  useEffect(() => {
    setOpenSelectTag(isOpen)
  }, [formName])

  const handleChange = (value: any) => {
    if (value.length === 0) {
      setOpenSelectTag(!openSelectTag)
    }
    if (value.includes('all')) {
      const symptomList = form?.getFieldValue([`symptomList`])
      const cloneList = _.cloneDeep(symptomList)
      const allIcd10Code = options.map((item) => item.id)
      const symptom = _.set(cloneList, formName, allIcd10Code)
      form?.setFieldsValue({ symptomList: symptom })
    } else {
      setSelectedItems((prev) =>
        [...prev, ...value].filter(
          (v, i) => [...prev, ...value].indexOf(v) == i
        )
      )
      setSelectedObj?.((prev) =>
        [...prev, ...value].filter(
          (v, i) => [...prev, ...value].indexOf(v) == i
        )
      )
    }
  }

  const handleDeselect = (value: string) => {
    const symptomList = form?.getFieldValue([`symptomList`])
    const cloneList = _.cloneDeep(symptomList)
    const icd10Code = cloneList
      ?.map((item: any) => item.icd10Code)
      ?.flat()
      ?.filter((icd10Code: any) => icd10Code !== 'all' && icd10Code !== value)
    const symptom = _.set(cloneList, formName, icd10Code)
    form?.setFieldsValue({ symptomList: symptom })

    setSelectedItems((prev) => prev.filter((item) => item !== value))
    setSelectedObj?.((prev) => prev.filter((item) => item !== value))
  }

  const handleSearch = (value: string) => {
    setSearchValue?.(value)
  }

  const tagRender = ({ label, onClose, closable }: TagsProps) => {
    const onPreventMouseDown = (event: {
      preventDefault: () => void
      stopPropagation: () => void
    }) => {
      event.preventDefault()
      event.stopPropagation()
    }
    return (
      <Tag
        color={tagColor}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ borderRadius: 30, marginBottom: 3 }}
      >
        {label}
      </Tag>
    )
  }

  return (
    <>
      <Form.Item
        name={formName}
        style={{ margin: 0 }}
        rules={rules}
        label={label}
      >
        <Select
          placeholder=""
          mode="multiple"
          className="selectSymptomTags"
          tagRender={tagRender}
          listHeight={128}
          optionFilterProp="children"
          autoFocus={isOpen ? false : true}
          defaultOpen={isOpen ? false : true}
          onChange={handleChange}
          onDeselect={handleDeselect}
          getPopupContainer={(trigger) => trigger.parentNode}
          onSearch={handleSearch}
        >
          {options.map((item) => (
            <Option key={item.id} value={item.id}>
              {tagKey === 'icd'
                ? item.icd10Code + ' - ' + item.name
                : item[tagKey]}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </>
  )
}

export default Symptom
