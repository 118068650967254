/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { Upload } from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'
import request from '../../utils/request'

const baseUrl = process.env.REACT_APP_API_URL

interface Props {
  data: number[] | undefined
}

const DownloadLink = ({ data }: Props): JSX.Element => {
  const token = localStorage.getItem('accessToken')
  const [fileList, setFileList] = useState<UploadFile[] | any[]>([])
  const getInfo = async (data: number[] | undefined) => {
    try {
      if (data) {
        const list = await Promise.all(
          data.map(async (item) => {
            return await request
              .get(`${baseUrl}/container/${item}/info/?access_token=${token}`)
              .then((res: any) => {
                return {
                  uid: res.id,
                  name: res.originalName,
                  status: 'done',
                  response: res,
                  url: `${baseUrl}/container/${item}/download/?access_token=${token}`,
                }
              })
          })
        )
        setFileList(list)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (data?.length !== 0) {
      getInfo(data)
    } else {
      setFileList([])
    }
  }, [data])

  return (
    <Upload
      name="file"
      accept="image/*,.pdf"
      action={`${baseUrl}/container`}
      headers={{
        accept: 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      }}
      showUploadList={{
        showRemoveIcon: false,
      }}
      fileList={fileList}
    ></Upload>
  )
}

export default DownloadLink
