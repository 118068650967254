/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction } from 'react'
import { Col, Divider, Form, Row } from 'antd'
import Button from '../../../components/Button'
import Typography from '../../../components/Typography'
import { CaseDiagnosis } from '../../../interface'
import { getImage, getTitle, scrollToTop } from '../../../utils/getName'
import dayjs from 'dayjs'
import DownloadLink from '../../../components/DownloadLink'

interface Props {
  id?: string
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
  setStep?: Dispatch<SetStateAction<number>> | undefined
  data: CaseDiagnosis | undefined
}

const Step1 = ({ setStep, data }: Props): JSX.Element => {
  const today = dayjs()

  return (
    <>
      <Form.Item
        label={
          <Typography sub4 color="black">
            ผู้ทำประกัน
          </Typography>
        }
      >
        <Row align="middle" justify="space-between">
          <Col span={8}>
            <img
              style={{
                width: '90px',
                height: '90px',
                borderRadius: '50%',
                objectFit: 'cover',
              }}
              src={getImage(data?.insured.image)}
            />
          </Col>
          <Col span={16}>
            <Typography sub1 color="black">
              {`${getTitle(data?.insured.title)} ${data?.insured.firstName} ${
                data?.insured.lastName
              }`}
            </Typography>
            <Typography sub1 color="black">
              {`${dayjs(data?.insured.birthDate)
                .locale('th')
                .format('DD MMMM YYYY')}  (${today.diff(
                String(data?.insured.birthDate),
                'year'
              )}ปี)`}
            </Typography>
          </Col>
        </Row>
      </Form.Item>
      <div style={{ paddingBottom: 16 }}>
        <hr />
      </div>
      <Form.Item
        label={
          <Typography sub4 color="black65">
            วัน เวลาที่เปิดเคส
          </Typography>
        }
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography sub1 color="black">
            {data?.diagnosis.date
              ? dayjs(data?.diagnosis.date).locale('th').format('DD MMMM YYYY')
              : '-'}
          </Typography>
        </div>
      </Form.Item>
      <Form.Item
        label={
          <Typography sub4 color="black65">
            ระยะเวลา
          </Typography>
        }
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography sub1 color="black">
            {`${
              data?.diagnosis.durationDay ? data?.diagnosis.durationDay : '-'
            } วัน`}
          </Typography>
          &nbsp;&nbsp;&nbsp;
          <Typography sub1 color="black">
            {`${
              data?.diagnosis.durationOffsetHour
                ? data?.diagnosis.durationOffsetHour
                : '-'
            } ชั่วโมง`}
          </Typography>
        </div>
      </Form.Item>
      <Form.Item
        label={
          <Typography sub4 color="black65">
            อาการสำคัญ
          </Typography>
        }
      >
        <Typography sub1 color="black">
          {data?.diagnosis.importantSymptom || '-'}
        </Typography>
        <Divider style={{ borderColor: '#CDCDCD', margin: '10px 0px' }} />
        <DownloadLink data={data?.diagnosis.importantSymptomAttachments} />
      </Form.Item>
      <Form.Item
        label={
          <Typography sub4 color="black65">
            อาการบาดเจ็บสะสม
          </Typography>
        }
      >
        <Typography sub1 color="black">
          {data?.diagnosis.cumulativeInjury || '-'}
        </Typography>
        <Divider style={{ borderColor: '#CDCDCD', margin: '10px 0px' }} />
        <DownloadLink data={data?.diagnosis.cumulativeInjuryAttachments} />
      </Form.Item>
      <Row
        justify="space-between"
        align="middle"
        style={{ justifyContent: 'center', paddingBottom: 24 }}
      >
        <Button
          htmlType="button"
          title="ต่อไป"
          style={{ width: '48%' }}
          onClick={() => {
            scrollToTop()
            setStep?.(2)
          }}
        />
      </Row>
    </>
  )
}

export default Step1
