import React, { useState, Dispatch, SetStateAction, useEffect } from 'react'
import { Form, Row, Modal as AntModal, Input, message } from 'antd'
import Button from '../../components/Button'
import Typography from '../../components/Typography'
import request from '../../utils/request'
import { Company } from '../../interface'
import UploadImage from '../../components/UploadImage'

interface Props {
  id?: string | number
  isModalVisible: boolean
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
  setRefresh?: Dispatch<SetStateAction<boolean>>
}

type FormValues = {
  abbreviation: string
  name: string
  logoImage: string
}

const Modal = ({
  id,
  isModalVisible,
  setIsModalVisible,
  setRefresh,
}: Props): JSX.Element => {
  const [form] = Form.useForm()
  const [fileList, setFileList] = useState<number | undefined>()
  const [data, setData] = useState<Company>()

  const getData = async () => {
    if (id) {
      try {
        const res: Company = await request.get(`/insurance-company/${id}`)
        setData(res)
      } catch (error) {
        console.log(error)
        message.error('เรียกข้อมูลไม่สำเร็จ')
        setData(undefined)
      }
      return
    }
    setData(undefined)
  }

  useEffect(() => {
    getData()
  }, [id])

  useEffect(() => {
    form.setFieldsValue({
      abbreviation: data?.abbreviation,
      name: data?.name,
      logoImage: data?.logoImage,
    })
    setFileList(data?.logoImage)
  }, [data])

  const onClose = () => {
    setIsModalVisible(false)
    if (!id) {
      form.resetFields()
    }
  }

  const onFinish = async (values: FormValues) => {
    const { name, abbreviation } = values

    try {
      if (id) {
        await request.put(`/insurance-company/${id}`, {
          name,
          abbreviation,
          logoImage: fileList,
        })
        message.success('แก้ไขรายการสำเร็จ')
      } else {
        await request.post('/insurance-company', {
          name,
          abbreviation,
          logoImage: fileList,
        })
        message.success('เพิ่มรายการสำเร็จ')
      }
      onClose()
      setRefresh?.((prev) => !prev)
    } catch (e) {
      message.error('ดำเนินการผิดพลาด')
      console.log(e)
    }
  }

  return (
    <AntModal
      forceRender
      centered
      visible={isModalVisible}
      style={{
        borderRadius: 8,
        padding: 0,
      }}
      bodyStyle={{ overflowX: 'scroll', maxHeight: 500 }}
      width={700}
      title={
        <Typography title1 color="black90">
          {id ? 'แก้ไขรายชื่อบริษัทประกันภัย' : 'เพิ่มรายชื่อบริษัทประกันภัย'}
        </Typography>
      }
      footer={null}
      onCancel={onClose}
    >
      <Form
        form={form}
        name="CompanyModal"
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          label={
            <Typography sub4 color="black65">
              อักษรย่อ
            </Typography>
          }
          name="abbreviation"
          rules={[{ required: true, message: 'กรุณากรอกอักษรย่อ' }]}
        >
          <Input className="ant-input-class" />
        </Form.Item>
        <Form.Item
          label={
            <Typography sub4 color="black65">
              ชื่อเต็มบริษัท
            </Typography>
          }
          name="name"
          rules={[{ required: true, message: 'กรุณากรอกชื่อเต็มบริษัท' }]}
        >
          <Input className="ant-input-class" />
        </Form.Item>
        <UploadImage
          title="โลโก้บริษัท"
          formName="logoImage"
          type="logo"
          setFileList={setFileList}
          fileList={fileList}
        />
        <Row justify="center" align="middle">
          <Button htmlType="submit" title="บันทึก" style={{ width: '50%' }} />
        </Row>
      </Form>
    </AntModal>
  )
}

export default Modal
