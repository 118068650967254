import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from 'antd'

interface Props {
  children: JSX.Element
}

const AuthLayout = ({ children }: Props): JSX.Element => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout.Content
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: 'white',
        }}
      >
        {children}
      </Layout.Content>
    </Layout>
  )
}

AuthLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default AuthLayout
