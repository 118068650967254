import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import AppLayout from '../layouts/AppLayout'
import AuthLayout from '../layouts/AuthLayout'
import CompanyPage from '../pages/CompanyPage'
import InsurancePage from '../pages/InsurancePage'
import LoginPage from '../pages/LoginPage'
import LogoutPage from '../pages/LogoutPage'
import UserPage from '../pages/UserPage'
import DrugPage from '../pages/DrugPage'
import AccessibilityPage from '../pages/AccessibilityPage'
import PasswordPage from '../pages/PasswordPage'
import ForgetPasswordPage from '../pages/ForgetPasswordPage'
import ResetPasswordPage from '../pages/ResetPasswordPage'
import DiagnosticListPage from '../pages/DiagnosticListPage'
import MedicalSupplyPage from '../pages/MedicalSupplyPage'
import SymptomsPage from '../pages/SymptomsPage'
import MedicalServicePage from '../pages/MedicalServicePage'
import HospitalPage from '../pages/HospitalPage'
import ClaimPage from '../pages/ClaimPage'
import BiclaimPage from '../pages/BiclaimPage'

const Routes = (): JSX.Element => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <AuthLayout>
            <LoginPage />
          </AuthLayout>
        </Route>
        <Route path="/forget-password">
          <AuthLayout>
            <ForgetPasswordPage />
          </AuthLayout>
        </Route>
        <Route path="/reset-password">
          <AuthLayout>
            <ResetPasswordPage />
          </AuthLayout>
        </Route>
        <Route path="/logout" exact>
          <LogoutPage />
        </Route>

        <Route
          path={[
            '/company-list',
            '/drug-list',
            '/medical-supplies-list',
            '/medical-services-list',
            '/symptoms-list',
            '/insurance-list',
            '/hospital-list',
            '/diagnostic-list',
            '/claim-list',
            '/accessibility',
            '/user-list',
            '/change-password',
            '/forget-password',
            '/reset-password',
            '/bi-claim',
          ]}
        >
          <AppLayout>
            <Route path="/company-list">
              <CompanyPage />
            </Route>
            <Route path="/drug-list">
              <DrugPage />
            </Route>
            <Route path="/medical-supplies-list">
              <MedicalSupplyPage />
            </Route>
            <Route path="/medical-services-list">
              <MedicalServicePage />
            </Route>
            <Route path="/symptoms-list">
              <SymptomsPage />
            </Route>
            <Route path="/insurance-list">
              <InsurancePage />
            </Route>
            <Route path="/hospital-list">
              <HospitalPage />
            </Route>
            <Route path="/diagnostic-list">
              <DiagnosticListPage />
            </Route>
            <Route path="/claim-list">
              <ClaimPage />
            </Route>
            <Route path="/accessibility">
              <AccessibilityPage />
            </Route>
            <Route path="/user-list">
              <UserPage />
            </Route>
            <Route path="/change-password">
              <PasswordPage />
            </Route>
            <Route path="/bi-claim">
              <BiclaimPage />
            </Route>
          </AppLayout>
        </Route>
      </Switch>
    </Router>
  )
}

export default Routes
