/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Form, Row, message, Select, FormInstance } from 'antd'
import Button from '../../../../components/Button'
import Typography from '../../../../components/Typography'
import request from '../../../../utils/request'
import {
  Company,
  Insurance,
  InsurancePlan,
  UserInsurance,
} from '../../../../interface'
import dayjs from 'dayjs'
import { useParams } from 'react-router'
import DatePicker from '../../../../components/DatePicker'

interface Props {
  id?: string
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
  setStep?: Dispatch<SetStateAction<number>> | undefined
  form: FormInstance<any>
  data: UserInsurance | undefined
  setSelectUserInsuranceId: Dispatch<React.SetStateAction<number | undefined>>
  setSelectCompanyId: React.Dispatch<React.SetStateAction<number | undefined>>
  selectCompanyId: number | undefined
  setSelectInsuranceId: React.Dispatch<React.SetStateAction<number | undefined>>
  selectInsuranceId: number | undefined
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>
  disabled: boolean
}

interface Params {
  id: string
}

const { Option } = Select

const Step1 = ({
  setStep,
  setIsModalVisible,
  setSelectUserInsuranceId,
  setSelectCompanyId,
  selectCompanyId,
  setSelectInsuranceId,
  selectInsuranceId,
  form,
  data,
  setRefresh,
  setDisabled,
  disabled,
}: Props): JSX.Element => {
  const [companyList, setCompanyList] = useState<Company[]>([])
  const [insuranceList, setInsuranceList] = useState<Insurance[]>([])
  const [planList, setPlanList] = useState<InsurancePlan[]>([])
  const params = useParams<Params>()

  const getCompany = async () => {
    try {
      const res: Company[] = await request.get(`/insurance-company/all`)
      setCompanyList(res)
    } catch (error) {
      message.error('เรียกข้อมูลไม่สำเร็จ')
    }
  }

  useEffect(() => {
    getCompany()
  }, [])

  useEffect(() => {
    if (data) {
      setSelectCompanyId(data?.plan.insurance.companyId)
      setSelectInsuranceId(data?.plan.insuranceId)
      form.setFieldsValue({
        companyId: data?.plan.insurance.companyId,
        insuranceId: data?.plan.insuranceId,
        planId: data?.planId,
        availableDate: dayjs(data?.availableDate),
        expiredDate: dayjs(data?.expiredDate),
      })
    }
  }, [data])

  const getInsurance = async () => {
    try {
      if (selectCompanyId) {
        const res: Insurance[] = await request.get(
          `/insurance-company/${selectCompanyId}/insurance`
        )
        setInsuranceList(res)
      }
    } catch (error) {
      message.error('เรียกข้อมูลไม่สำเร็จ')
    }
  }

  useEffect(() => {
    getInsurance()
  }, [selectCompanyId])

  const onChangeCompany = (value: any) => {
    setSelectCompanyId(value)
    form.resetFields(['insuranceId', 'planId'])
    setSelectInsuranceId(undefined)
  }

  const getPlan = async () => {
    try {
      if (selectInsuranceId) {
        const res: InsurancePlan[] = await request.get(
          `/insurance/${selectInsuranceId}/plan/all`
        )
        setPlanList(res)
      }
    } catch (error) {
      message.error('เรียกข้อมูลไม่สำเร็จ')
    }
  }

  const validate = () => {
    if (
      form.getFieldValue('planId') &&
      form.getFieldValue('availableDate') &&
      form.getFieldValue('expiredDate')
    ) {
      setStep?.(2)
    }
  }

  useEffect(() => {
    getPlan()
  }, [selectInsuranceId])

  const onChangeInsurance = (value: any) => {
    setSelectInsuranceId(value)
    form.resetFields(['planId'])
  }

  const onFinish = async () => {
    setDisabled(true)
    try {
      await request.put(`/insured/${params.id}/plan/${data?.id}`, {
        planId: form.getFieldValue('planId'),
        availableDate: form.getFieldValue('availableDate'),
        expiredDate: form.getFieldValue('expiredDate'),
      })
      message.success('บันทึกข้อมูลสำเร็จ')
      setIsModalVisible(false)
      setSelectUserInsuranceId(undefined)
      setSelectCompanyId(undefined)
      setSelectInsuranceId(undefined)
      setRefresh?.((prev) => !prev)
      setStep?.(1)
      setDisabled(false)
    } catch (error) {
      message.error('บันทึกข้อมูลไม่สำเร็จ')
    }
  }

  return (
    <>
      <Form.Item
        label={
          <Typography sub4 color="black65">
            บริษัท
          </Typography>
        }
        name="companyId"
        rules={[{ required: true, message: 'กรุณาเลือกบริษัทประกันภัย' }]}
      >
        <Select
          onChange={onChangeCompany}
          placeholder="เลือกบริษัทประกันภัย"
          listHeight={128}
          allowClear
          getPopupContainer={(trigger) => trigger.parentNode}
        >
          {companyList?.map((x) => {
            return (
              <Option key={x.id} value={x.id}>
                {x.abbreviation}
              </Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item
        label={
          <Typography sub4 color="black65">
            ชื่อกรมธรรม์
          </Typography>
        }
        name="insuranceId"
        rules={[{ required: true, message: 'กรุณาเลือกกรมธรม์' }]}
      >
        <Select
          onChange={onChangeInsurance}
          placeholder="เลือกกรมธรม์"
          listHeight={128}
          disabled={data ? false : selectCompanyId ? false : true}
          getPopupContainer={(trigger) => trigger.parentNode}
        >
          {insuranceList?.map((x) => {
            return (
              <Option key={x.id} value={x.id}>
                {x.name}
              </Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item
        label={
          <Typography sub4 color="black65">
            ชื่อแผนการคุ้มครอง
          </Typography>
        }
        name="planId"
        rules={[{ required: true, message: 'กรุณาเลือกแผนการคุ้มครอง' }]}
      >
        <Select
          placeholder="เลือกแผนการคุ้มครอง"
          listHeight={128}
          disabled={data ? false : selectInsuranceId ? false : true}
          getPopupContainer={(trigger) => trigger.parentNode}
        >
          {planList?.map((x) => {
            return (
              <Option key={x.id} value={x.id}>
                {x.name}
              </Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item
        label={
          <Typography sub4 color="black65">
            วันที่คุ้มครอง
          </Typography>
        }
        name="availableDate"
        rules={[{ required: true, message: 'กรุณาเลือกวันที่คุ้มครอง' }]}
      >
        <DatePicker placeholder="เลือกวันที่" />
      </Form.Item>
      <Form.Item
        label={
          <Typography sub4 color="black65">
            วันที่หมดอายุ
          </Typography>
        }
        name="expiredDate"
        rules={[{ required: true, message: 'กรุณาเลือกวันที่หมดอายุ' }]}
      >
        <DatePicker placeholder="เลือกวันที่" />
      </Form.Item>
      <Row
        justify="space-between"
        align="middle"
        style={{ justifyContent: 'center' }}
      >
        {data ? (
          <Button
            htmlType="button"
            title="บันทึก"
            style={{ width: '48%' }}
            disabled={disabled}
            onClick={() => {
              onFinish()
            }}
          />
        ) : (
          <Button
            htmlType="button"
            title="ต่อไป"
            style={{ width: '48%' }}
            onClick={() => {
              validate()
            }}
          />
        )}
      </Row>
    </>
  )
}

export default Step1
