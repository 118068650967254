import React from 'react'
import { Form, Input, message } from 'antd'
import Button from '../../components/Button'
import LogoEcliaim from '../../image/LogoEcliaim.png'
import Typography from '../../components/Typography'
import request from '../../utils/request'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'qs'

interface FormValues {
  newPassword: string
  confirmPassword: string
}

const ResetPasswordPage = (): JSX.Element => {
  const history = useHistory()
  const location = useLocation()
  const querystring = qs.parse(location.search, { ignoreQueryPrefix: true })
  const onFinish = async (values: FormValues) => {
    try {
      await request.post('/admin/password/reset', {
        newPassword: values.newPassword,
        otp: querystring.otp,
        email: querystring.email,
      })
      message.success('รีเซ็ตรหัสผ่านสำเร็จ')
      history.push('/')
    } catch (e) {
      message.error('เกิดข้อผิดพลาด')
    }
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img style={{ height: 90 }} src={LogoEcliaim} />
      <Typography
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          lineHeight: '60px',
          margin: '20px 0px',
        }}
        h2
      >
        รีเซตรหัสผ่าน
      </Typography>
      <Form
        name="login"
        layout="vertical"
        initialValues={{}}
        onFinish={onFinish}
      >
        <Form.Item
          name="newPassword"
          rules={[{ required: true, message: 'รหัสผ่านใหม่' }]}
        >
          <Input.Password
            className="ant-input-class"
            placeholder="รหัสผ่านใหม่"
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          rules={[
            { required: true, message: 'ยืนยันรหัสผ่านใหม่' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('รหัสผ่านไม่ตรงกัน'))
              },
            }),
          ]}
        >
          <Input.Password
            className="ant-input-class"
            placeholder="ยืนยันรหัสผ่านใหม่"
          />
        </Form.Item>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Form.Item>
            <Button
              htmlType="submit"
              title="ยืนยัน"
              style={{ width: '350px' }}
            />
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default ResetPasswordPage
