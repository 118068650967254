import React from 'react'
import { Button as AntButton } from 'antd'
import Typography from '../Typography'

interface Props {
  title: string
  onClick?: () => void
  style?: React.CSSProperties
  type?: 'primary' | 'secondary' | 'disable' | 'danger' | 'text'
  htmlType?: 'submit' | 'reset' | 'button'
  size?: 'small' | 'middle' | 'large' | undefined
  disabled?: boolean
  iconLeft?: JSX.Element | JSX.Element[] | null
  iconRight?: JSX.Element | JSX.Element[] | null
}
const Button = ({
  title,
  onClick,
  style,
  htmlType = 'submit',
  type = 'primary',
  disabled = false,
  size = 'large',
  iconLeft = <></>,
  iconRight = <></>,
}: Props): JSX.Element => {
  return (
    <>
      {type === 'primary' ? (
        <AntButton
          className="primaryButton"
          type={'primary'}
          htmlType={htmlType}
          onClick={onClick}
          style={style}
          disabled={disabled}
          size={size}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              gap: 5,
            }}
          >
            {iconLeft && iconLeft}
            <Typography sub1 color="white">
              {title}
            </Typography>
            {iconRight && iconRight}
          </div>
        </AntButton>
      ) : type === 'secondary' ? (
        <AntButton
          className="secondaryButton"
          htmlType={htmlType}
          onClick={onClick}
          style={style}
          disabled={disabled}
          size={size}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: 5,
            }}
          >
            {iconLeft && iconLeft}
            <Typography sub1 color="secondary1">
              {title}
            </Typography>
            {iconRight && iconRight}
          </div>
        </AntButton>
      ) : type === 'disable' ? (
        <AntButton
          className="disableButton"
          htmlType={htmlType}
          onClick={onClick}
          style={style}
          disabled
          size={size}
        >
          <div style={{ flexDirection: 'row' }}>
            {iconLeft && iconLeft}
            <Typography sub1 color="secondary1">
              {title}
            </Typography>
            {iconRight && iconRight}
          </div>
        </AntButton>
      ) : type === 'danger' ? (
        <AntButton
          className="dangerButton"
          htmlType={htmlType}
          onClick={onClick}
          style={style}
          disabled={disabled}
          size={size}
        >
          <div style={{ flexDirection: 'row' }}>
            {iconLeft && iconLeft}
            <Typography sub1 color="error">
              {title}
            </Typography>
            {iconRight && iconRight}
          </div>
        </AntButton>
      ) : (
        type === 'text' && (
          <AntButton
            className="textButton"
            htmlType={htmlType}
            onClick={onClick}
            style={style}
            disabled={disabled}
            size={size}
          >
            <div style={{ flexDirection: 'row', display: 'flex', gap: 5 }}>
              {iconLeft && iconLeft}
              <Typography sub1 color="secondary1">
                {title}
              </Typography>
              {iconRight && iconRight}
            </div>
          </AntButton>
        )
      )}
    </>
  )
}

export default Button
