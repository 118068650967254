import React, {
  Dispatch,
  SetStateAction,
  useState,
  useMemo,
  useEffect,
} from 'react'
import { Form, message, Modal as AntModal } from 'antd'
import Typography from '../../../components/Typography'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'
import StepClaim from './StepClaim'
import { Admin, CaseDiagnosis, ClaimBilling } from '../../../interface'
import request from '../../../utils/request'
import AlertModal from '../../../components/AlertModal'
import dayjs from 'dayjs'
import { scrollToTop } from '../../../utils/getName'

interface Props {
  id?: string | number
  isModalVisible: boolean
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
  setRefresh?: Dispatch<SetStateAction<boolean>>
  type: 'diagnosis' | 'claim'
  setSelectId: React.Dispatch<React.SetStateAction<number | undefined>>
  status?: 'pending' | 'diagnosed' | 'approved'
}

type FormValues = {
  approvedMedicineListIds: number[]
  approvedMedicalServiceIds: number[]
  approvedMedicalSupplyIds: number[]
}

const ModalDiagnosticDetail = ({
  id,
  isModalVisible,
  setIsModalVisible,
  type,
  setRefresh,
  setSelectId,
  status,
}: Props): JSX.Element => {
  const [form] = Form.useForm()
  const [step, setStep] = useState(1)
  const [data, setData] = useState<CaseDiagnosis>()
  const [excludeClaiming, setExcludeClaiming] = useState<ClaimBilling>()
  const pathAPI = type === 'claim' ? `/claiming/${id}` : `/diagnosis/${id}`
  const [role, setRole] = useState<
    'admin' | 'history taking officer' | 'claims officer' | 'financial officer'
  >()
  const [openConfirmModal, setOpenConfirmModal] = useState(false)

  useEffect(() => {
    getRole()
  }, [])

  const getRole = async () => {
    try {
      const res: Admin = await request.get(`/admin/me`)
      setRole(res.role)
    } catch (error) {
      console.log(error)
    }
  }
  const onClose = () => {
    setIsModalVisible(false)
    scrollToTop()
    setStep(1)
    if (!id) {
      form.resetFields()
    }
    setSelectId(undefined)
  }

  const Content = useMemo(() => {
    if (step === 1) return Step1
    else if (step === 2) return Step2
    else if (step === 3) return Step3
    else if (step === 4) return Step4
    else if (step === 5) {
      if (type === 'diagnosis') return Step5
      else return StepClaim
    } else return Step1
  }, [step])

  const getData = async () => {
    if (id) {
      try {
        const res: CaseDiagnosis = await request.get(pathAPI)
        setData(res)
      } catch (error) {
        message.error('เรียกข้อมูลไม่สำเร็จ')
        setData(undefined)
      }
      return
    }
    setData(undefined)
  }

  const getExcludeClaiming = async () => {
    if (id) {
      try {
        const res: ClaimBilling = await request.get(`/claiming/${id}/billing/`)
        setExcludeClaiming(res)
      } catch (error) {
        console.log(error)
      }
      return
    }
    setExcludeClaiming(undefined)
  }

  const onFinish = async (values: FormValues) => {
    const {
      approvedMedicineListIds,
      approvedMedicalServiceIds,
      approvedMedicalSupplyIds,
    } = values
    try {
      await request.post(`/claiming/${id}/approve`, {
        approvedMedicineListIds: approvedMedicineListIds
          ? approvedMedicineListIds
          : [],
        approvedMedicalServiceIds: approvedMedicalServiceIds
          ? approvedMedicalServiceIds
          : [],
        approvedMedicalSupplyIds: approvedMedicalSupplyIds
          ? approvedMedicalSupplyIds
          : [],
      })
      message.success('เพิ่มรายการสำเร็จ')
      onClose()
      setRefresh?.((prev) => !prev)
    } catch (e) {
      if (role === 'admin') {
        setOpenConfirmModal(true)
      }
    }
  }

  useEffect(() => {
    getData()
    getExcludeClaiming()
  }, [id, status])

  useEffect(() => {
    if (data?.status === 'diagnosed') {
      form.setFieldsValue({
        symptomFactors: data.diagnosis.symptomFactors,
        isTreatable: data.diagnosis.isTreatable,
        accidentDate: dayjs(data.diagnosis.accidentDate),
        untreatableReason: data.diagnosis.untreatableReason,
      })
    }
  }, [data])

  return (
    <AntModal
      centered
      visible={isModalVisible}
      style={{ borderRadius: 8 }}
      bodyStyle={{ overflowX: 'scroll', height: 500 }}
      width={step === 5 ? 500 : 420}
      title={
        <Typography title1 color="black90">
          {step >= 5 ? 'การรักษา' : 'วินิจฉัย'}
        </Typography>
      }
      footer={null}
      onCancel={onClose}
    >
      <Form
        form={form}
        name="DiagnosisDetailModal"
        layout="vertical"
        onFinish={onFinish}
        style={{ height: '100%' }}
      >
        <Content
          data={data}
          exclude={excludeClaiming}
          setIsModalVisible={setIsModalVisible}
          setStep={setStep}
          step={step}
          form={form}
        />
      </Form>
      <AlertModal
        type="confirm"
        title="เจ้าหน้าที่บริษัทประกันเท่านั้นที่มีสิทธ์ในการอนุมัติการเคลม"
        isModalVisible={openConfirmModal}
        onCancel={() => setOpenConfirmModal(false)}
        onSubmit={() => setOpenConfirmModal(false)}
      />
    </AntModal>
  )
}

export default ModalDiagnosticDetail
