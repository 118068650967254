import { Card as AntdCard } from 'antd'
import React from 'react'

interface Props {
  children?: JSX.Element | JSX.Element[] | undefined
  style?: React.CSSProperties
  onClick?: () => void
}
const Card = ({ children, style, onClick }: Props): JSX.Element => {
  return (
    <AntdCard style={style} onClick={onClick} className="card-default">
      {children}
    </AntdCard>
  )
}

export default Card
