const theme = {
  primary1: '#2D3392',
  primary2: '#304A8F',
  secondary1: '#16AAE8',
  secondary2: '#1A75BC',
  black: '#231F20',
  black90: '#3B3B3B',
  black85: '#777777',
  black65: '#A2A2A2',
  black40: '#C0C0C0',
  black22: '#CDCDCD',
  black6: '#EFEFEF',
  white: '#FFFFFF',
  success: '#17A20D',
  awaiting: '#F3A84D',
  error: '#D9564C',
}

export default theme
