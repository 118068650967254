import React from 'react'
import { Modal as AntModal, Row } from 'antd'
import Button from '../Button'
import Typography from '../Typography'
import IconCheck from '../../image/CheckCircle.png'
import IconQuestion from '../../image/QuestionCircle.png'
import IconX from '../../image/XCircle.png'

interface Props {
  isModalVisible: boolean
  type: 'done' | 'confirm' | 'error'
  title: string
  desc?: string
  onSubmit?: () => void
  onCancel?: () => void
}

const AlertModal = ({
  isModalVisible,
  type,
  title,
  desc,
  onSubmit,
  onCancel,
}: Props): JSX.Element => {
  return (
    <AntModal
      centered
      visible={isModalVisible}
      style={{
        borderRadius: 8,
      }}
      width={350}
      footer={null}
      closable={false}
    >
      {type === 'done' ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img style={{ width: 64, height: 64 }} src={IconCheck} />
          <Typography
            title2
            style={{ margin: '20px 0px', textAlign: 'center' }}
          >
            {title}
          </Typography>
          <Button
            htmlType="button"
            type="primary"
            title="ตกลง"
            onClick={onSubmit}
          />
        </div>
      ) : type === 'confirm' ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img style={{ width: 64, height: 64 }} src={IconQuestion} />
          <Typography
            title2
            style={{
              margin: desc ? '20px 0px 0px' : '20px 0px',
              textAlign: 'center',
            }}
          >
            {title}
          </Typography>
          {desc && (
            <Typography sub1 style={{ marginBottom: 20, textAlign: 'center' }}>
              {desc}
            </Typography>
          )}
          <Row style={{ gap: 15 }}>
            {onCancel && (
              <Button
                htmlType="button"
                type="secondary"
                title="ยกเลิก"
                onClick={onCancel}
              />
            )}
            {onSubmit && (
              <Button
                htmlType="button"
                type="primary"
                title="ตกลง"
                onClick={onSubmit}
              />
            )}
          </Row>
        </div>
      ) : (
        type === 'error' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img style={{ width: 64, height: 64 }} src={IconX} />
            <Typography
              title2
              style={{
                margin: desc ? '20px 0px 0px' : '20px 0px',
                textAlign: 'center',
              }}
            >
              {title}
            </Typography>
            {desc && (
              <Typography
                sub1
                style={{ marginBottom: 20, textAlign: 'center' }}
              >
                {desc}
              </Typography>
            )}
            <Row style={{ gap: 15 }}>
              {onCancel && (
                <Button
                  htmlType="button"
                  type="secondary"
                  title="ยกเลิก"
                  onClick={onCancel}
                />
              )}
              {onSubmit && (
                <Button
                  htmlType="button"
                  type="primary"
                  title="ตกลง"
                  onClick={onSubmit}
                />
              )}
            </Row>
          </div>
        )
      )}
    </AntModal>
  )
}

export default AlertModal
