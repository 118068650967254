/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  Form,
  Row,
  Input,
  Select,
  Col,
  FormInstance,
  message,
  InputNumber,
} from 'antd'
import Button from '../../../components/Button'
import Typography from '../../../components/Typography'
import IconAdd from '../../../image/IconAdd.png'
import { CaseDiagnosis, Medicine } from '../../../interface'
import request from '../../../utils/request'
import { SelectValue } from 'antd/lib/select'
import { scrollToTop } from '../../../utils/getName'

interface Props {
  id?: string
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
  setStep?: Dispatch<SetStateAction<number>> | undefined
  form: FormInstance<any>
  medicine?: Medicine[]
  data: CaseDiagnosis | undefined
}
const { Option } = Select

const Step5 = ({
  // id,
  // setIsModalVisible,
  setStep,
  form,
  medicine,
  data,
}: Props): JSX.Element => {
  const [medicineOption, setMedicineOption] = useState<Medicine[] | undefined>(
    medicine
  )
  const [price, setPrice] = useState<number[]>([])
  const [medicineOrderIndex, setMedicineOrderIndex] = useState<number>(
    data
      ? data.diagnosis.medicineList.length === 0
        ? form.getFieldValue('medicineList')
          ? form.getFieldValue('medicineList').length
          : 1
        : form.getFieldValue('medicineList').length
      : 1
  )

  const onSearch = async (value: string) => {
    try {
      const res: Medicine[] = await request.get(`/medicine/keyword`, {
        params: {
          key: value,
        },
      })
      setMedicineOption(res)
    } catch (error) {
      message.error('เรียกข้อมูลไม่สำเร็จ')
    }
  }

  const onChange = async (value: SelectValue, index: number) => {
    try {
      const res: Medicine = await request.get(`/medicine/${value}`)
      const medicineList = form.getFieldValue('medicineList')
      const newList = medicineList.map((item: any, idx: number) =>
        idx === index
          ? {
              medicineId: res.id,
              unit: res.unit,
              quantity: 1,
              price: res.price,
            }
          : item
      )
      form.setFieldsValue({
        medicineList: newList,
      })
      setPrice(
        newList.map((item: any) => {
          return item.price / item.quantity
        })
      )
    } catch (error) {
      message.error('เรียกข้อมูลไม่สำเร็จ')
    }
  }

  useEffect(() => {
    setPrice(
      form.getFieldValue('medicineList')?.map((item: any) => {
        return item.price / item.quantity
      })
    )
  }, [data])

  const onQuantityChange = async (value: number, index: number) => {
    const medicineList = form.getFieldValue('medicineList')
    const newList = medicineList.map((item: any, idx: number) =>
      idx === index
        ? {
            medicineId: item.medicineId,
            quantity: item.quantity,
            unit: item.unit,
            price: value * price[index],
          }
        : item
    )
    form.setFieldsValue({
      medicineList: newList,
    })
  }

  const onDeleteList = async (index: number) => {
    const medicineList = form.getFieldValue('medicineList')
    if (medicineList) {
      const newList = medicineList.filter((_: any, indx: number) => {
        return indx !== index
      })
      const newPrice = price.filter((_: any, indx: number) => {
        return indx !== index
      })
      form.setFieldsValue({
        medicineList: newList,
      })
      setPrice(newPrice)
    }
  }

  const createElements = (n: number) => {
    const elements = []
    for (let i = 0; i < n; i++) {
      elements.push(
        <Row
          justify="space-between"
          style={{ marginBottom: i === n - 1 ? 0 : 20 }}
        >
          <Col span={24}>
            <Form.Item
              className="medicineList"
              style={{ margin: '10px 0px' }}
              label={
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Typography sub4 color="black65">
                    ชื่อยา
                  </Typography>
                  {medicineOrderIndex > 1 ? (
                    <a
                      onClick={() => {
                        onDeleteList(i)
                        setMedicineOrderIndex((prev) => prev - 1)
                      }}
                    >
                      <Typography sub1 color="primary1">
                        ลบรายการ
                      </Typography>
                    </a>
                  ) : null}
                </div>
              }
              name={[i, 'medicineId']}
            >
              <Select
                className="ant-input-class"
                listHeight={128}
                showSearch
                onSearch={onSearch}
                optionFilterProp="children"
                onChange={(value) => onChange(value, i)}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {medicineOption?.map((x) => {
                  return (
                    <Option key={x.id} value={x.id} title={x.commonName}>
                      {`${x.commonName} ${x.tradeNames.join(',')} (${
                        x.price
                      } บาท/${x.unit})`}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              style={{ margin: 0 }}
              label={
                <Typography sub4 color="black65">
                  ยูนิต
                </Typography>
              }
              name={[i, 'unit']}
            >
              <Input className="ant-input-class" disabled />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              style={{ margin: 0 }}
              label={
                <Typography sub4 color="black65">
                  จำนวน
                </Typography>
              }
              name={[i, 'quantity']}
            >
              <InputNumber
                style={{ width: '100%' }}
                onChange={(value) => onQuantityChange(value, i)}
                min={1}
                disabled={
                  form.getFieldValue('medicineList')
                    ? form.getFieldValue('medicineList')[i]
                      ? false
                      : true
                    : true
                }
              />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              style={{ margin: 0 }}
              label={
                <Typography sub4 color="black65">
                  ราคา
                </Typography>
              }
              name={[i, 'price']}
            >
              <Input className="ant-input-class" disabled />
            </Form.Item>
          </Col>
        </Row>
      )
    }
    return elements
  }

  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <Typography sub2 color="black">
          รายการยา
        </Typography>
        <Form.List name="medicineList">
          {() => {
            return createElements(medicineOrderIndex)
          }}
        </Form.List>
        <Button
          iconLeft={<img style={{ width: 23, height: 23 }} src={IconAdd} />}
          title="เพิ่มยา"
          htmlType="button"
          type="text"
          style={{ margin: '10px 0px', padding: 0 }}
          onClick={() => setMedicineOrderIndex((prev) => prev + 1)}
        />
      </div>
      <Row justify="space-between" align="middle">
        <Button
          htmlType="submit"
          title="ย้อนกลับ"
          type="secondary"
          style={{ width: '48%' }}
          onClick={() => {
            scrollToTop()
            setStep?.(4)
          }}
        />
        <Button
          htmlType="submit"
          title="ต่อไป"
          style={{ width: '48%' }}
          onClick={() => {
            scrollToTop()
            setStep?.(6)
          }}
        />
      </Row>
    </div>
  )
}

export default Step5
