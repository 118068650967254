import React from 'react'
import PropTypes from 'prop-types'
import { PageHeader, Button } from 'antd'
import { ArrowLeftOutlined, MenuOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router'
import { useLocalization } from '../contexts/LocalizationContext'
import Typography from '../components/Typography'
import { useMenu } from '../contexts/MenuContext'

interface Props {
  title?: string
  subTitle?: string
  editPath?: string
  createPath?: string
  listPath?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extra?: any[]
  children: JSX.Element[] | JSX.Element
  shownBack?: boolean
  backPath?: string
  radioGroup?: JSX.Element
  styleHeader?: React.CSSProperties
}

const PageLayout = ({
  title,
  subTitle = '',
  editPath,
  createPath,
  listPath,
  extra = [],
  children,
  shownBack = true,
  backPath,
  styleHeader,
  radioGroup,
}: Props): JSX.Element => {
  const { t } = useLocalization()
  const history = useHistory()
  const myExtra = []
  const { toggleMenu } = useMenu()

  if (listPath) {
    myExtra.push(
      <Button key="view" onClick={() => history.push(listPath)}>
        {t('pageLayout.viewAll')}
      </Button>
    )
  }
  if (editPath) {
    myExtra.push(
      <Button key="edit" type="primary" onClick={() => history.push(editPath)}>
        {t('pageLayout.edit')}
      </Button>
    )
  }
  if (createPath) {
    myExtra.push(
      <Button key="new" type="primary" onClick={() => history.push(createPath)}>
        {t('pageLayout.new')}
      </Button>
    )
  }
  if (radioGroup) {
    myExtra.push(radioGroup)
  }
  return (
    <>
      <div className="hamburger-button">
        <MenuOutlined
          className="menu-toggle-button"
          style={{ marginRight: 15, marginBottom: 20, fontSize: 25 }}
          onClick={() => {
            toggleMenu()
          }}
        />
      </div>
      <PageHeader
        onBack={() => (backPath ? history.push(backPath) : history.goBack())}
        backIcon={shownBack ? <ArrowLeftOutlined /> : false}
        title={
          <Typography
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              lineHeight: '60px',
            }}
            h2
          >
            {title}
          </Typography>
        }
        subTitle={subTitle}
        extra={[...extra, ...myExtra]}
        style={{
          backgroundColor: 'white',
          ...styleHeader,
        }}
        className="pageHeader-menu"
      >
        {children}
      </PageHeader>
    </>
  )
}

PageLayout.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  editPath: PropTypes.string,
  createPath: PropTypes.string,
  listPath: PropTypes.string,
  extra: PropTypes.arrayOf(PropTypes.node),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  shownBack: PropTypes.bool,
}

export default PageLayout
