/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Col, Form, FormInstance, message, Row } from 'antd'
// import { Link } from 'react-router-dom'
import Button from '../../../components/Button'
import Typography from '../../../components/Typography'
import Card from '../../../components/Card'
import request from '../../../utils/request'
import { CaseDiagnosis, Insured, UserInsurance } from '../../../interface'
import dayjs from 'dayjs'
import { getImage } from '../../../utils/getName'
require('dayjs/locale/th')

interface Props {
  id?: string | number
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
  setStep?: Dispatch<SetStateAction<number>> | undefined
  form: FormInstance<any>
  data: CaseDiagnosis | undefined
  user: Insured | undefined
}

const Step2 = ({ id, form, data, user }: Props): JSX.Element => {
  const [selectInsurance, setSelectInsurance] = useState<number | undefined>(
    data?.insuredPlanId
  )
  const [insurance, setInsurance] = useState<UserInsurance[]>([])
  // const [clickLink, setClickLink] = useState<boolean>(false)
  const today = dayjs()

  const onChange = (value: number) => {
    setSelectInsurance(value)
    form.setFieldsValue({ insuredPlanId: value })
  }

  const getTitle = (
    title: 'mr' | 'ms' | 'mrs' | 'boy' | 'girl' | undefined
  ) => {
    if (title === 'mr') {
      return 'นาย'
    } else if (title === 'ms') {
      return 'นางสาว'
    } else if (title === 'mrs') {
      return 'นาง'
    } else if (title === 'boy') {
      return 'เด็กชาย'
    } else {
      return 'เด็กหญิง'
    }
  }
  const getStatus = (date: string) => {
    const diff = dayjs(date).diff(today, 'days')
    if (diff >= 0) {
      return 'กำลังคุ้มครอง'
    } else {
      return 'หมดอายุ'
    }
  }

  const getInsurance = async () => {
    if (user?.id) {
      const insuredId = user?.id
      try {
        const res: UserInsurance[] = await request.get(
          `/insured/${insuredId}/plan/all`
        )
        setInsurance(res)
      } catch (error) {
        message.error('เรียกข้อมูลไม่สำเร็จ')
      }
    }
  }

  useEffect(() => {
    getInsurance()
  }, [user])

  return (
    <>
      <Form.Item name="en"></Form.Item>
      <Form.Item
        label={
          <Typography sub2 color="black">
            ผู้ทำประกัน
          </Typography>
        }
        name="insuredId"
      >
        <Row align="middle">
          <Col span={10}>
            <img
              style={{
                width: '100px',
                height: '100px',
                borderRadius: '50%',
                objectFit: 'cover',
              }}
              src={getImage(user?.image)}
            />
          </Col>
          <Col span={14}>
            <Typography sub1 color="black">
              {`${getTitle(user?.title)} ${user?.firstName} ${user?.lastName}`}
            </Typography>
            <Typography sub1 color="black">
              {`${dayjs(user?.birthDate)
                .locale('th')
                .format('DD MMMM YYYY')}  (${today.diff(
                String(user?.birthDate),
                'year'
              )}ปี)`}
            </Typography>
          </Col>
        </Row>
      </Form.Item>
      <div style={{ paddingBottom: 16 }}>
        <hr />
      </div>
      <Form.Item
        style={{ width: '100%' }}
        label={
          <Typography sub2 color="black">
            กรุณาเลือกกรมธรรม์
          </Typography>
        }
        name="insuredPlanId"
      >
        {/* {insurance.length === 0 && !clickLink && (
          <Link
            to={`/user-list/edit/${user?.id}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => setClickLink(true)}
          >
            กรุณาเพิ่มกรมธรรม์ก่อน
          </Link>
        )}
        {insurance.length === 0 && clickLink && (
          <div
            onClick={() => {
              getInsurance()
              setClickLink(false)
            }}
          >
            <Typography>รีโหลดข้อมูล</Typography>
          </div>
        )} */}
        {insurance.map((item, index: number) => {
          const status = getStatus(item.expiredDate)
          return (
            <Card
              key={index}
              style={{
                padding: '12px',
                margin: '0px 0px 20px',
                borderColor: selectInsurance === item.id ? '#16aae8' : '',
                borderWidth: selectInsurance === item.id ? '2px' : '',
                backgroundColor:
                  status === 'กำลังคุ้มครอง' ? 'white' : '#EFEFEF',
              }}
              onClick={() =>
                status === 'กำลังคุ้มครอง' ? onChange(item.id) : null
              }
            >
              <Row align="middle">
                <Col span={5}>
                  <img
                    style={{
                      width: '50px',
                      height: '50px',
                      borderRadius: '50%',
                      objectFit: 'cover',
                    }}
                    src={getImage(item.plan.insurance.company.logoImage)}
                  />
                </Col>
                <Col span={12}>
                  <Typography sub1 color="black">
                    {`${item.plan.insurance.name}, ${item.plan.name}`}
                  </Typography>
                  <Typography sub1 color="black">
                    {`${item.plan.maxBenefitPerYear} บาท`}
                  </Typography>
                </Col>
                <Col span={7}>
                  <Row justify="end">
                    <Typography
                      sub3
                      color={status === 'กำลังคุ้มครอง' ? 'success' : 'error'}
                    >
                      {status}
                    </Typography>
                  </Row>
                </Col>
              </Row>
            </Card>
          )
        })}
      </Form.Item>
      <Row
        justify="space-between"
        align="middle"
        style={{ justifyContent: 'center' }}
      >
        <Button
          htmlType="submit"
          title={id ? 'แก้ไขเคส' : 'เพิ่มเคส'}
          style={{ width: '48%' }}
          // onClick={onClose}
        />
      </Row>
    </>
  )
}

export default Step2
