/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react'
import PageLayout from '../../../layouts/PageLayout'
import Card from '../../../components/Card'
import Tab from '../../../components/Tabs'
import ProfilePage from './Profile'
import InsurancePage from './Insurance'
import DiseasePage from './disease'
import { Row, Col, message } from 'antd'
import { useParams } from 'react-router-dom'
import UploadPhoto from '../../../components/UploadPhoto'
import styled from '@emotion/styled'
import request from '../../../utils/request'

const baseUrl = process.env.REACT_APP_API_URL

interface Params {
  id: string
}

interface image {
  image: string
}

const CurrentImage = styled.img`
  width: 140px;
  height: 140px;
  border-radius: 50%;
  object-fit: cover;
  background: #cdcdcd;
`

const DetailPage = (): JSX.Element => {
  const params = useParams<Params>()
  const [urlId, setUrlId] = useState<string>()
  const [data, setData] = useState<image>()
  const token = localStorage.getItem('accessToken')

  const getData = async () => {
    try {
      const res: any = await request.get(`/insured/${params.id}`)
      setData(res)
    } catch (error) {
      message.error('เรียกข้อมูลไม่สำเร็จ')
      setData(undefined)
    }
  }

  useEffect(() => {
    getData()
  }, [urlId])

  const tabs = [
    {
      title: 'ข้อมูลส่วนตัว',
      key: 'ProfilePage',
      content: <ProfilePage id={params.id} />,
    },
    {
      title: 'กรมธรรม์',
      key: 'InsurancePage',
      content: <InsurancePage id={params.id} />,
    },
    {
      title: 'โรคประจำตัว',
      key: 'DiseasePage',
      content: <DiseasePage id={params.id} />,
    },
  ]

  return (
    <PageLayout title={params.id ? 'แก้ไขข้อมูลสมาชิก' : 'เพิ่มสมาชิก'}>
      <Card style={{ padding: '20px', paddingLeft: 0 }}>
        <Row>
          <Col
            xl={5}
            lg={5}
            md={6}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {urlId || data?.image ? (
              <>
                <CurrentImage
                  src={`${
                    urlId
                      ? `${baseUrl}/container/${urlId}/?access_token=${token}`
                      : `${baseUrl}/container/${data?.image}/?access_token=${token}`
                  }`}
                  alt=""
                />
              </>
            ) : (
              <>
                <div
                  style={{
                    width: '140px',
                    height: '140px',
                    borderRadius: '50%',
                    background: '#CDCDCD',
                  }}
                ></div>
              </>
            )}
            <br />
            <UploadPhoto id={params.id} setUrlId={setUrlId} />
          </Col>
          <Col xl={19} lg={19} md={18}>
            <Tab item={tabs} />
          </Col>
        </Row>
      </Card>
    </PageLayout>
  )
}

export default DetailPage
