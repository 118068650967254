/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Form, Row, Modal as AntModal, Input, message, Col, Radio } from 'antd'
import Button from '../../components/Button'
import Typography from '../../components/Typography'
import IconAdd from '../../image/IconAdd.png'
import request from '../../utils/request'
import { Symptom } from '../../interface'
import AlertModal from '../../components/AlertModal'

interface Props {
  id?: string | number
  isModalVisible: boolean
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
  setRefresh?: Dispatch<SetStateAction<boolean>>
}

type FormValues = {
  name: string
  icd10Code: string
  otherNames: string[]
  type: string
}

const Modal = ({
  id,
  isModalVisible,
  setIsModalVisible,
  setRefresh,
}: Props): JSX.Element => {
  const [form] = Form.useForm()
  const [otherNameIndex, setOtherNameIndex] = useState(1)
  const [data, setData] = useState<Symptom>()
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [radioType, setRadioType] = useState('accident')

  const getData = async () => {
    if (id) {
      try {
        const res: Symptom = await request.get(`/symptom/${id}`)
        setData(res)
      } catch (error) {
        message.error('เรียกข้อมูลไม่สำเร็จ')
        setData(undefined)
      }
      return
    }
    setData(undefined)
  }

  useEffect(() => {
    getData()
  }, [id])

  useEffect(() => {
    if (data) {
      setOtherNameIndex(data?.otherNames.length)
    } else {
      setOtherNameIndex(1)
    }
    form.setFieldsValue({
      name: data?.name,
      icd10Code: data?.icd10Code,
      otherNames: data?.otherNames,
      type: data?.type,
    })
  }, [data])

  const onClose = () => {
    setIsModalVisible(false)
    if (!id) {
      form.resetFields()
      setOtherNameIndex(1)
    }
  }

  const onFinish = async (values: FormValues) => {
    const { name, icd10Code, otherNames, type } = values
    try {
      if (id) {
        await request.put(`/symptom/${id}`, {
          name,
          icd10Code,
          otherNames: (otherNames || []).filter((item) => item),
          type,
        })
        message.success('แก้ไขรายการสำเร็จ')
      } else {
        await request.post(`/symptom`, {
          name,
          icd10Code,
          otherNames: (otherNames || []).filter((item) => item),
          type,
        })
        message.success('เพิ่มรายการสำเร็จ')
      }
      onClose()
      setRefresh?.((prev) => !prev)
    } catch (e: any) {
      message.error('ดำเนินการผิดพลาด')
      if (e.response.status === 400) {
        setOpenConfirmModal(true)
      }
    }
  }

  const createElements = (n: number) => {
    const elements = []
    for (let i = 0; i < n; i++) {
      elements.push(
        i === 0 ? (
          <Form.Item
            name={['otherNames', i]}
            className="tradeName"
            // rules={[{ required: true, message: 'กรุณากรอกชื่อเสมือน' }]}
          >
            <Input className="ant-input-class" />
          </Form.Item>
        ) : (
          <Form.Item className="tradeName">
            <Row gutter={10} align="middle">
              <Col span={22}>
                <Form.Item
                  name={['otherNames', i]}
                  noStyle
                  rules={[{ required: true, message: 'กรุณากรอกชื่อเสมือน' }]}
                >
                  <Input className="ant-input-class" />
                </Form.Item>
              </Col>
              <Col span={2}>
                <a onClick={() => setOtherNameIndex((prev) => prev - 1)}>
                  <Typography color="primary1">ลบ</Typography>
                </a>
              </Col>
            </Row>
          </Form.Item>
        )
      )
    }
    return elements
  }

  return (
    <>
      <AntModal
        centered
        visible={isModalVisible}
        style={{ borderRadius: 8 }}
        bodyStyle={{ overflowX: 'scroll', maxHeight: 500 }}
        width={700}
        title={
          <Typography title1 color="black90">
            เพิ่มรายชื่ออาการ / โรค
          </Typography>
        }
        footer={null}
        onCancel={onClose}
      >
        <Form
          form={form}
          name="SymptomModal"
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            label={
              <Typography sub4 color="black65">
                ชื่ออาการ / โรค
              </Typography>
            }
            name="name"
            rules={[{ required: true, message: 'กรุณากรอกชื่ออาการ' }]}
          >
            <Input className="ant-input-class" />
          </Form.Item>
          <Form.Item
            label={
              <Typography sub4 color="black65">
                ICD-10 CM
              </Typography>
            }
            name="icd10Code"
            rules={[{ required: true, message: 'กรุณากรอกรหัส ICD10' }]}
          >
            <Input className="ant-input-class" />
          </Form.Item>
          <Form.Item
            label={
              <Typography sub4 color="black65">
                ประเภท
              </Typography>
            }
            name="type"
            rules={[{ required: true, message: 'กรุณากรอกประเภท' }]}
          >
            <Radio.Group
              onChange={(e) => setRadioType(e.target.value)}
              value={radioType}
            >
              <Radio value={'accident'}>อุบัติเหตุ</Radio>
              <Radio value={'not accident'}>ไม่อุบัติเหตุ</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label={
              <Typography sub4 color="black65">
                ชื่อเสมือน
              </Typography>
            }
          >
            {createElements(otherNameIndex)}
            <Button
              type="text"
              htmlType="button"
              title="เพิ่มชื่อเสมือน"
              iconLeft={<img style={{ width: 23, height: 23 }} src={IconAdd} />}
              style={{ padding: 0 }}
              onClick={() => setOtherNameIndex((prev) => prev + 1)}
            />
          </Form.Item>
          <Row justify="center" align="middle">
            <Button htmlType="submit" title="บันทึก" style={{ width: '50%' }} />
          </Row>
        </Form>
      </AntModal>
      <AlertModal
        type="confirm"
        title="มีรายการนี้อยู่แล้ว"
        isModalVisible={openConfirmModal}
        onSubmit={() => {
          setOpenConfirmModal(false)
        }}
      />
    </>
  )
}

export default Modal
