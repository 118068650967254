/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useMemo } from 'react'
import {
  Form,
  Row,
  Input,
  Select,
  Col,
  message,
  InputNumber,
  Checkbox,
  Spin,
  Radio,
} from 'antd'
import PageLayout from '../../../layouts/PageLayout'
import { useHistory, useParams } from 'react-router-dom'
import Button from '../../../components/Button'
import Typography from '../../../components/Typography'
import Card from '../../../components/Card'
import AlertModal from '../../../components/AlertModal'
import DatePicker from '../../../components/DatePicker'
import request from '../../../utils/request'
import IconAdd from '../../../image/IconAdd.png'
import {
  Symptom,
  MedicineList,
  InsurancePlan,
  DiagnosisMedicalService,
} from '../../../interface'
import { SelectValue } from 'antd/lib/select'
import dayjs from 'dayjs'
import SelectTag from '../../../components/SelectTag/Symptom'
import theme from '../../../utils/theme'
import _, { uniqBy } from 'lodash'
import PlanSelect from './PlanSelect'
import MedicineSelect from './MedicineSelect'
import MedicalServiceSelect from './MedicalServiceSelect'

const { Option } = Select
const { TextArea } = Input

interface Params {
  en: string
  id: string
}

type FormValues = {
  en: string
  hn: string
  planId: number
  chiefComplaint: string
  presentIllnes: string
  admissionDateTime: string
  physicalexam: string
  name4: string
  name5: string
  name6: string
  medicineList: []
  medicineServiceList: []
  symptoms: []
  type: string
  accidentDate: string
  reasonsOfAdmission: string
}

const Detail = (): JSX.Element => {
  const [form] = Form.useForm()
  const [openAlertModal, setOpenAlertModal] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [openRequiredModal, setOpenRequiredModal] = useState(false)
  const [print, setPrint] = useState(false)
  const [price, setPrice] = useState<number[]>([])
  const [loading, setLoading] = useState(false)
  const [medicineList, setMedicineList] = useState<MedicineList[]>([])
  const [medicalServiceList, setMedicalServiceList] = useState<
    DiagnosisMedicalService[]
  >([])
  const [plan, setPlan] = useState<InsurancePlan[]>([])
  const [symptomOption, setSymptomOption] = useState<Symptom[]>([])
  const [selectedSymptom, setSelectedSymptom] = useState<number[]>([])
  const [selectedSymptomObj, setSelectedSymptomObj] = useState<any[]>([])
  const [priceRange, setPriceRange] = useState<Range[] | any[]>([])
  const [medicineOrderIndex, setMedicineOrderIndex] = useState<number>(1)
  const [medicalServiceOrderIndex, setMedicalServiceOrderIndex] =
    useState<number>(1)
  const params = useParams<Params>()
  const [refresh, setRefresh] = useState(false)
  const history = useHistory()
  const [radioType, setRadioType] = useState<string>('')
  const [admissionType, setAdmissionType] = useState<string>('')
  const [loadingPage, setLoadingPage] = useState(false)

  const [totalPriceClaim, setTotalPriceClaim] = useState<number[]>([])
  const [totalPriceNotClaim, setTotalPriceNotClaim] = useState<number[]>([])
  const [totalPriceService, setTotalPriceService] = useState<number[]>([])
  const [searchValue, setSearchValue] = useState<string>()

  const onFinish = async (values: FormValues) => {
    setLoading(true)
    const {
      admissionDateTime,
      en,
      hn,
      planId,
      chiefComplaint,
      presentIllnes,
      medicineList,
      physicalexam,
      symptoms,
      accidentDate,
      type,
      reasonsOfAdmission,
    } = values
    const medicalServices = form.getFieldValue('medicalServices')
    let response: any = ''
    try {
      if (!params?.id) {
        response = await request.post('/claiming-detail', {
          hn: hn,
          en: en,
          chiefComplaint: chiefComplaint,
          presentIllnes,
          admissionDateTime: dayjs(admissionDateTime).toISOString(),
          physicalexam: physicalexam,
          symptoms: symptoms,
          planId: planId,
          medicalServices: medicalServices?.length > 0 ? medicalServices : [],
          medicineList: medicineList?.map((item: any) => {
            return {
              medicineId: item.medicineId,
              quantity: item.quantity,
            }
          }),
          accidentDate:
            type === 'accident' ? dayjs(accidentDate).toISOString() : null,
          reasonsOfAdmission,
        })
      } else {
        response = await request.patch(`/claiming-detail/${params?.id}`, {
          hn: hn,
          en: en,
          chiefComplaint: chiefComplaint,
          presentIllnes,
          admissionDateTime: dayjs(admissionDateTime).toISOString(),
          physicalexam: physicalexam,
          symptoms: symptoms,
          planId: planId,
          medicalServices: medicalServices?.length > 0 ? medicalServices : [],
          medicineList: medicineList?.map((item: any) => {
            return {
              medicineId: item.medicineId,
              quantity: item.quantity,
            }
          }),
          accidentDate:
            type === 'accident' ? dayjs(accidentDate).toISOString() : null,
          reasonsOfAdmission,
        })
      }
      if (print) {
        onPrintSave(response)
      }
      setOpenAlertModal(true)
      setRefresh?.((prev) => !prev)
      setLoading(false)
      setPrint(false)
    } catch (e) {
      console.log(e)
      throw e
    }
  }

  const onPrintSave = async (response: any) => {
    try {
      const data = (await request.get(
        `/claiming-detail/${response.id}/document`,
        {
          responseType: 'blob',
        }
      )) as any

      const url = window.URL.createObjectURL(
        new Blob([data as BlobPart], {
          type: 'application/pdf',
        })
      )

      window.open(url)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'file.pdf') //or any other extension
      document.body.appendChild(link)
      link.click()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      message.error('Failed file.')
    }
  }

  useEffect(() => {
    getSymptom()
  }, [searchValue])

  const getSymptom = async () => {
    try {
      const res: any = await request.get(
        searchValue
          ? `/symptom?page=1&limit=100&keyword=${searchValue}`
          : `/symptom?page=1&limit=100`
      )
      setSymptomOption(res?.data)
    } catch (error) {
      // message.error('เรียกข้อมูลไม่สำเร็จ')
      console.log(error)
    }
  }

  const symptomOptions = useMemo(() => {
    return uniqBy([...symptomOption, ...selectedSymptomObj], 'id')
  }, [symptomOption, selectedSymptomObj])

  useEffect(() => {
    const getClaimingDetailList = async () => {
      setLoadingPage(true)
      try {
        const res: any = await request.get(`/claiming-detail/en/${params?.en}`)
        const symptoms = (res?.symptoms || [])?.map(
          (item: { id: number }) => item.id
        )
        setMedicineList(res?.medicineList)
        setSelectedSymptom(symptoms)
        setSelectedSymptomObj(res?.symptoms || [])

        const checkClaimable: any[] = await request.post(
          '/claiming-detail/checkClaimable',
          {
            symptoms: symptoms,
            medicines: res?.medicineList.map((item: any) => {
              return item.medicine.id
            }),
            isAccident: radioType === 'accident' ? true : false || false,
          }
        )
        const newList = res?.medicineList.map((item: any) => {
          return {
            medicineId: item.medicine.id,
            unit: item.medicine.unit,
            quantity: item.quantity,
            price: item.totalPrice,
            isClaimable: checkClaimable?.find(
              (element: any) => element.medicineId === item.medicine.id
            ).isClaimable,
          }
        })
        setPrice(
          newList.map((item: any) => {
            return item.price / item.quantity
          })
        )
        // totalPrice
        setTotalPriceClaim(
          newList.map((item: any) => {
            if (item.isClaimable) {
              return item.price
            }
          })
        )
        setTotalPriceNotClaim(
          newList.map((item: any) => {
            if (!item.isClaimable) {
              return item.price
            }
          })
        )

        setMedicineOrderIndex(newList.length)
        form.setFieldsValue({
          en: res?.en,
          hn: res?.hn,
          t: res?.vitalSigns?.t,
          p: res?.vitalSigns?.p,
          r: res?.vitalSigns?.r,
          bp: res?.vitalSigns?.bp,
          chiefComplaint: res?.chiefComplaint,
          presentIllnes: res?.presentIllnes,
          admissionDateTime: dayjs(),
          physicalexam: res?.physicalexam,
          symptoms: symptoms,
          medicineList: newList,
        })
        setLoadingPage(false)
        // eslint-disable-next-line prettier/prettier
      } catch (e: any) {
        message.error('Error1', e)
      }
    }
    const editClaimingDetailList = async () => {
      setLoadingPage(true)
      try {
        const res: any = await request.get(`/claiming-detail/${params?.id}`)
        const symptoms = (res?.symptoms || [])?.map(
          (item: { id: string }) => item.id
        )
        setMedicineList(res?.medicineList)
        setMedicalServiceList(res?.medicalServices)
        setPlan([res.plan])
        setSelectedSymptom(symptoms)
        setSelectedSymptomObj(res?.symptoms || [])
        const checkClaimable: any[] = await request.post(
          '/claiming-detail/checkClaimable',
          {
            symptoms: symptoms,
            medicines: res?.medicineList.map((item: any) => {
              return item.medicine.id
            }),
            isAccident: radioType === 'accident' ? true : false || false,
          }
        )

        const newList = res?.medicineList.map((item: any) => {
          return {
            medicineId: item.medicine.id,
            unit: item.medicine.unit,
            quantity: item.quantity,
            price: item.totalPrice,
            isClaimable: checkClaimable?.find(
              (element: any) => element.medicineId === item.medicine.id
            ).isClaimable,
          }
        })
        const newServices = res?.medicalServices.map((item: any) => {
          return {
            serviceId: item.serviceId,
            type: item.type,
            quantity: item.quantity,
            price: item.price,
          }
        })
        setPrice(
          newList.map((item: any) => {
            return item.price / item.quantity
          })
        )

        // totalPrice
        setTotalPriceClaim(
          newList.map((item: any) => {
            if (item.isClaimable) {
              return item.price
            }
          })
        )
        setTotalPriceNotClaim(
          newList.map((item: any) => {
            if (!item.isClaimable) {
              return item.price
            }
          })
        )
        setTotalPriceService(
          newServices.map((item: any) => {
            return item.price
          })
        )

        const range = res?.medicalServices.map((item: any) => {
          return {
            min:
              item.type === 'opd' ? item.service.minOpd : item.service.minIpd,
            max:
              item.type === 'opd' ? item.service.maxOpd : item.service.maxIpd,
          }
        })
        setPriceRange(range)
        if (radioType) {
          setMedicineOrderIndex(form.getFieldValue('medicineList')?.length)
          setMedicalServiceOrderIndex(
            form.getFieldValue('medicalServices')?.length
          )
          form.setFieldsValue({
            type: radioType,
          })
        } else {
          setMedicineOrderIndex(newList.length)
          setMedicalServiceOrderIndex(newServices.length)
          form.setFieldsValue({
            en: res?.en,
            hn: res?.hn,
            t: res?.vitalSigns?.t,
            p: res?.vitalSigns?.p,
            r: res?.vitalSigns?.r,
            bp: res?.vitalSigns?.bp,
            planId: res?.planId,
            chiefComplaint: res?.chiefComplaint,
            presentIllnes: res?.presentIllnes,
            admissionDateTime: dayjs(res?.admissionDateTime),
            physicalexam: res?.physicalexam,
            symptoms: symptoms,
            medicineList: newList,
            medicalServices: newServices,
            accidentDate: res?.accidentDate
              ? dayjs(res?.accidentDate)
              : undefined,
            reasonsOfAdmission: res?.reasonsOfAdmission,
            type: res?.accidentDate ? 'accident' : 'not accident',
            admissionType: res?.reasonsOfAdmission ? 'admit' : 'not admit',
          })
        }
        setAdmissionType(res?.reasonsOfAdmission ? 'admit' : 'not admit')
        setLoadingPage(false)
        // eslint-disable-next-line prettier/prettier
      } catch (e: any) {
        message.error('Error2', e)
      }
    }

    if (!params?.id) {
      getClaimingDetailList()
    } else {
      editClaimingDetailList()
    }
  }, [params, radioType])

  const onQuantityChange = async (value: number, index: number) => {
    const medicineList = form.getFieldValue('medicineList')
    const newList = medicineList.map((item: any, idx: number) =>
      idx === index
        ? {
            medicineId: item.medicineId,
            quantity: item.quantity,
            unit: item.unit,
            price: value * price[index],
            isClaimable: item.isClaimable || false,
          }
        : item
    )
    form.setFieldsValue({
      medicineList: newList,
    })
    setTotalPriceClaim(
      newList.map((item: any) => {
        if (item.isClaimable) {
          return item.price
        }
      })
    )
    setTotalPriceNotClaim(
      newList.map((item: any) => {
        if (!item.isClaimable) {
          return item.price
        }
      })
    )
  }

  const onDeleteList = async (index: number) => {
    const medicineList = form.getFieldValue('medicineList')
    if (medicineList) {
      const newList = medicineList.filter((_: any, indx: number) => {
        return indx !== index
      })
      const newPrice = price.filter((_: any, indx: number) => {
        return indx !== index
      })
      form.setFieldsValue({
        medicineList: newList,
      })
      setPrice(newPrice)
      // totalPrice
      setTotalPriceClaim(
        newList.map((item: any) => {
          if (item.isClaimable) {
            return item.price
          }
        })
      )
      setTotalPriceNotClaim(
        newList.map((item: any) => {
          if (!item.isClaimable) {
            return item.price
          }
        })
      )
    }
  }

  const onChange = async (value: any, index?: number) => {
    try {
      const res: any = await request.get(`/medicine/${value}`)
      const isClaimable =
        res?.symptoms.length > 0
          ? res?.symptoms.some((symptom: any) => {
              return selectedSymptom.some((id: any) => id === symptom.id)
            })
          : true
      const medicineList = form.getFieldValue('medicineList')
      const newList = medicineList.map((item: any, idx: number) =>
        idx === index
          ? {
              medicineId: res.id,
              unit: res.unit,
              quantity: 1,
              price: res.price,
              isClaimable: isClaimable,
            }
          : item
      )
      form.setFieldsValue({
        medicineList: newList,
      })
      setPrice(
        newList.map((item: any) => {
          return item.price / item.quantity
        })
      )
      setTotalPriceClaim(
        newList.map((item: any) => {
          if (item.isClaimable) {
            return item.price
          }
        })
      )
      setTotalPriceNotClaim(
        newList.map((item: any) => {
          if (!item.isClaimable) {
            return item.price
          }
        })
      )
    } catch (error) {
      message.error('เรียกข้อมูลไม่สำเร็จ')
    }
  }

  const createElements = (n: number) => {
    const elements = []
    for (let i = 0; i < n; i++) {
      elements.push(
        <div style={{ display: 'flex' }}>
          <Row
            justify="space-between"
            style={{ marginBottom: i === n - 1 ? 0 : 20, flex: 0.8 }}
          >
            <Col span={24}>
              <Form.Item
                className="medicineList"
                style={{ margin: '10px 0px' }}
                label={
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <Typography sub4 color="black65">
                      ชื่อยา
                    </Typography>
                    {medicineOrderIndex > 1 ? (
                      <a
                        onClick={() => {
                          onDeleteList(i)
                          setMedicineOrderIndex((prev) => prev - 1)
                        }}
                      >
                        <Typography sub1 color="primary1">
                          ลบรายการ
                        </Typography>
                      </a>
                    ) : null}
                  </div>
                }
                name={[i, 'medicineId']}
              >
                <MedicineSelect
                  onChange={onChange}
                  form={form}
                  medicineList={medicineList}
                  radioType={radioType}
                  refresh={refresh}
                  index={i}
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                style={{ margin: 0 }}
                label={
                  <Typography sub4 color="black65">
                    ยูนิต
                  </Typography>
                }
                name={[i, 'unit']}
              >
                <Input className="ant-input-class" disabled />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                style={{ margin: 0 }}
                label={
                  <Typography sub4 color="black65">
                    จำนวน
                  </Typography>
                }
                name={[i, 'quantity']}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  onChange={(value) => onQuantityChange(value, i)}
                  min={1}
                  disabled={
                    form.getFieldValue('medicineList')
                      ? form.getFieldValue('medicineList')[i]
                        ? false
                        : true
                      : true
                  }
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                style={{ margin: 0 }}
                label={
                  <Typography sub4 color="black65">
                    ราคา
                  </Typography>
                }
                name={[i, 'price']}
              >
                <Input className="ant-input-class" disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ flex: 0.2, display: 'flex', alignItems: 'center' }}>
            <Col
              span={24}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Form.Item
                style={{ margin: 0 }}
                name={[i, 'isClaimable']}
                valuePropName="checked"
              >
                <Checkbox disabled>
                  <Typography sub1>เคลมได้</Typography>
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </div>
      )
    }
    return elements
  }

  const onChangeService = async (value: any, index?: number) => {
    try {
      const res: any = await request.get(`/medical-service/${value}`)
      const medicalServices = form.getFieldValue('medicalServices')
      const newServices = medicalServices.map((item: any, idx: number) =>
        idx === index
          ? {
              serviceId: res.id,
              type: 'ipd',
              quantity: 1,
            }
          : item
      )
      form.setFieldsValue({
        medicalServices: newServices,
      })
      const range = medicalServices.map((_: any, idx: number) =>
        idx === index
          ? {
              min: res.minIpd,
              max: res.maxIpd,
            }
          : priceRange[idx]
      )
      setPriceRange(range)
      setTotalPriceService(
        newServices.map((item: any) => {
          return item.price
        })
      )
    } catch (error) {
      message.error('เรียกข้อมูลไม่สำเร็จ')
    }
  }

  const onQuantityChangeService = async (value: any, index: number) => {
    // const res: any = await request.get(`/medical-service/${value}`)
    const medicalServices = form.getFieldValue('medicalServices')
    const newServices = medicalServices.map((item: any, idx: number) =>
      idx === index
        ? {
            serviceId: item.serviceId,
            type: 'ipd',
            quantity: 1,
            price: value,
          }
        : item
    )
    form.setFieldsValue({
      medicalServices: newServices,
    })
    setTotalPriceService(
      newServices.map((item: any) => {
        return item.price
      })
    )

    // const medicalServices = form.getFieldValue('medicalServices')
    // const newServices = medicalServices.map((item: any, idx: number) =>
    //   idx === index
    //     ? {
    //         // serviceId: item.id,
    //         type: 'ipd',
    //         quantity: 1,
    //         price: value,
    //       }
    //     : item
    // )
    // form.setFieldsValue({
    //   medicalServices: newServices,
    // })
  }

  const onTypeChange = async (value: SelectValue, index: number) => {
    try {
      const medicalServices = form.getFieldValue('medicalServices')
      const res: any = await request.get(
        `/medical-service/${medicalServices[index].serviceId}`
      )
      const newServices = medicalServices.map((item: any, idx: number) =>
        idx === index
          ? {
              serviceId: res.id,
              type: value,
              quantity: 1,
            }
          : item
      )
      form.setFieldsValue({
        medicalServices: newServices,
      })
      const range = medicalServices.map((_: any, idx: number) =>
        idx === index
          ? {
              min: value === 'ipd' ? res.minIpd : res.minOpd,
              max: value === 'ipd' ? res.maxIpd : res.maxOpd,
            }
          : priceRange[idx]
      )
      setTotalPriceService(
        newServices.map((item: any) => {
          return item.price
        })
      )
      setPriceRange(range)
    } catch (error) {
      message.error('เรียกข้อมูลไม่สำเร็จ')
    }
  }

  const onDeleteListServic = async (index: number) => {
    const medicalServices = form.getFieldValue('medicalServices')
    if (medicalServices) {
      const newServices = medicalServices.filter((item: any, indx: number) => {
        return indx !== index
      })
      form.setFieldsValue({
        medicalServices: newServices,
      })
      setPriceRange((prev) => prev.filter((_, idx) => idx != index))
      setTotalPriceService(
        newServices.map((item: any) => {
          return item.price
        })
      )
    }
  }

  const createElementsService = (n: number) => {
    const elements = []
    for (let i = 0; i < n; i++) {
      elements.push(
        // <div style={{ marginBottom: i === n - 1 ? 0 : 20 }}>
        <Row
          justify="space-between"
          style={{ marginBottom: i === n - 1 ? 0 : 20, width: '100%' }}
        >
          <Col span={24}>
            <Form.Item
              className="medicineList"
              style={{ margin: '10px 0px' }}
              label={
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Typography sub4 color="black65">
                    ชื่อบริการ
                  </Typography>
                  {medicalServiceOrderIndex > 1 ? (
                    <a
                      onClick={() => {
                        onDeleteListServic(i)
                        setMedicalServiceOrderIndex((prev) => prev - 1)
                      }}
                    >
                      <Typography sub1 color="primary1">
                        ลบรายการ
                      </Typography>
                    </a>
                  ) : null}
                </div>
              }
              name={[i, 'serviceId']}
            >
              <MedicalServiceSelect
                medicalServices={medicalServiceList}
                onChange={onChangeService}
                form={form}
                refresh={refresh}
                index={i}
              />
            </Form.Item>
          </Col>
          {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}> */}
          <Col span={7}>
            <Form.Item
              style={{ margin: 0 }}
              label={
                <Typography sub4 color="black65">
                  ประเภท
                </Typography>
              }
              name={[i, 'type']}
            >
              <Select
                onChange={(value) => onTypeChange(value, i)}
                disabled={
                  form.getFieldValue('medicalServices')
                    ? form.getFieldValue('medicalServices')[i]
                      ? false
                      : true
                    : true
                }
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                <Option value="ipd">IPD</Option>
                <Option value="opd">OPD</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              style={{ margin: 0 }}
              label={
                <Typography sub4 color="black65">
                  ราคา ({`${priceRange[i]?.min} - ${priceRange[i]?.max}`})
                </Typography>
              }
              name={[i, 'price']}
              rules={[
                {
                  type: 'number',
                  message: `กรอกราคา`,
                },
              ]}
            >
              <InputNumber
                onChange={(value) => onQuantityChangeService(value, i)}
                style={{ width: '100%' }}
                disabled={
                  form.getFieldValue('medicalServices')
                    ? form.getFieldValue('medicalServices')[i]
                      ? false
                      : true
                    : true
                }
              />
            </Form.Item>
          </Col>
          {/* </div> */}
        </Row>
      )
    }
    return elements
  }

  return (
    <Spin size="large" tip="Loading..." spinning={loadingPage}>
      <PageLayout title="ใบเคลม">
        <Form
          form={form}
          name="InsuranceDetail"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={() => {
            setPrint(false)
            setOpenRequiredModal(true)
          }}
        >
          <Card style={{ padding: '20px', minHeight: 628 }}>
            <Row style={{ paddingBottom: 28 }}>
              <Form.Item
                label={
                  <Typography title2 color="black65" style={{ width: 160 }}>
                    EN:
                  </Typography>
                }
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 0,
                  width: '100%',
                }}
                name="en"
              >
                <Input className="ant-input-class" disabled />
              </Form.Item>
            </Row>
            <Row style={{ paddingBottom: 28 }}>
              <Form.Item
                label={
                  <Typography title2 color="black65" style={{ width: 160 }}>
                    HN:
                  </Typography>
                }
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 0,
                  width: '100%',
                }}
                name="hn"
              >
                <Input className="ant-input-class" disabled />
              </Form.Item>
            </Row>
            <Row style={{ paddingBottom: 28 }}>
              <Form.Item
                label={
                  <Typography title2 color="black65" style={{ width: 160 }}>
                    กรมธรรม์:
                  </Typography>
                }
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 0,
                  width: '100%',
                }}
                name="planId"
                required
                rules={[{ required: true, message: 'กรุณากรอกกรมธรรม์' }]}
              >
                <PlanSelect form={form} plan={plan} />
              </Form.Item>
            </Row>
            <Row
              style={{
                paddingBottom: 28,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Col span={4} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography title2 color="black65">
                  VitalSigns:
                </Typography>
              </Col>
              <Col
                span={20}
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Col span={4}>
                  <Form.Item
                    style={{ margin: 0 }}
                    label={
                      <Typography sub4 color="black65">
                        T
                      </Typography>
                    }
                    name="t"
                  >
                    <Input className="ant-input-class" disabled />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    style={{ margin: 0 }}
                    label={
                      <Typography sub4 color="black65">
                        P
                      </Typography>
                    }
                    name="p"
                  >
                    <Input className="ant-input-class" disabled />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    style={{ margin: 0 }}
                    label={
                      <Typography sub4 color="black65">
                        R
                      </Typography>
                    }
                    name="r"
                  >
                    <Input className="ant-input-class" disabled />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    style={{ margin: 0 }}
                    label={
                      <Typography sub4 color="black65">
                        BP
                      </Typography>
                    }
                    name="bp"
                  >
                    <Input className="ant-input-class" disabled />
                  </Form.Item>
                </Col>
              </Col>
            </Row>
            <Row style={{ paddingBottom: 28 }}>
              <Form.Item
                label={
                  <Typography title2 color="black65" style={{ width: 160 }}>
                    อาการสำคัญ:
                  </Typography>
                }
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 0,
                  width: '100%',
                }}
                name="chiefComplaint"
                required
                rules={[{ required: true, message: 'กรุณากรอกอาการสำคัญ' }]}
              >
                <Input className="ant-input-class" />
              </Form.Item>
            </Row>
            <Row style={{ paddingBottom: 28 }}>
              <Form.Item
                label={
                  <Typography title2 color="black65" style={{ width: 160 }}>
                    อาการปัจจุบัน:
                  </Typography>
                }
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 0,
                  width: '100%',
                }}
                name="presentIllnes"
                required
                rules={[{ required: true, message: 'กรุณากรอกอาการปัจจุบัน' }]}
              >
                <TextArea rows={5} />
              </Form.Item>
            </Row>
            <Row style={{ paddingBottom: 28 }}>
              <Form.Item
                label={
                  <Typography title2 color="black65" style={{ width: 160 }}>
                    วัน-เวลา:
                  </Typography>
                }
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 0,
                  width: '100%',
                }}
                name="admissionDateTime"
              >
                <DatePicker showTime placeholder="เลือกวันที่" />
              </Form.Item>
            </Row>
            <Row style={{ paddingBottom: 28 }}>
              <Form.Item
                label={
                  <Typography title2 color="black65" style={{ width: 160 }}>
                    ตรวจร่างกาย:
                  </Typography>
                }
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 0,
                  width: '100%',
                }}
                name="physicalexam"
                required
                rules={[{ required: true, message: 'กรุณากรอกตรวจร่างกาย' }]}
              >
                <Input className="ant-input-class" />
              </Form.Item>
            </Row>
            <Row style={{ paddingBottom: 28 }}>
              <Form.Item
                label={
                  <Typography title2 color="black65" style={{ width: 160 }}>
                    ประเภท
                  </Typography>
                }
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 0,
                  width: '100%',
                }}
                name="type"
                rules={[{ required: true, message: 'กรุณาเลือกประเภท' }]}
              >
                <Radio.Group
                  onChange={(e) => setRadioType(e.target.value)}
                  value={radioType}
                >
                  <Radio value={'accident'}>
                    <Typography sub1 style={{ width: 160 }}>
                      อุบัติเหตุ
                    </Typography>
                  </Radio>
                  <Radio value={'not accident'}>
                    <Typography sub1 style={{ width: 160 }}>
                      ไม่อุบัติเหตุ
                    </Typography>
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Row>
            <Row
              style={{
                paddingBottom: radioType === 'accident' ? 28 : 0,
              }}
            >
              {radioType === 'accident' ? (
                <Form.Item
                  label={
                    <Typography title2 color="black65" style={{ width: 160 }}>
                      วัน-เวลาอุบัติเหตุ:
                    </Typography>
                  }
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 0,
                    width: '100%',
                  }}
                  name="accidentDate"
                  rules={[
                    { required: true, message: 'กรุณากรอกวัน-เวลาอุบัติเหตุ' },
                  ]}
                >
                  <DatePicker
                    placeholder="เลือกวันที่"
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                  />
                </Form.Item>
              ) : null}
            </Row>
            <Row style={{ paddingBottom: 28 }}>
              <Form.Item
                label={
                  <Typography title2 color="black65" style={{ width: 160 }}>
                    admission
                  </Typography>
                }
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 0,
                  width: '100%',
                }}
                name="admissionType"
                rules={[{ required: true, message: 'กรุณาเลือกadmission' }]}
              >
                <Radio.Group
                  onChange={(e) => setAdmissionType(e.target.value)}
                  value={admissionType}
                >
                  <Radio value={'admit'}>
                    <Typography sub1 style={{ width: 160 }}>
                      admit
                    </Typography>
                  </Radio>
                  <Radio value={'not admit'}>
                    <Typography sub1 style={{ width: 160 }}>
                      ไม่ admit
                    </Typography>
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Row>
            <Row style={{ paddingBottom: admissionType === 'admit' ? 28 : 0 }}>
              {admissionType === 'admit' ? (
                <Form.Item
                  label={
                    <Typography title2 color="black65" style={{ width: 160 }}>
                      เหตุผลการ admission
                    </Typography>
                  }
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 0,
                    width: '100%',
                  }}
                  name="reasonsOfAdmission"
                  rules={[
                    { required: true, message: 'กรุณากรอกเหตุผลการ admission' },
                  ]}
                >
                  <TextArea rows={3} />
                </Form.Item>
              ) : null}
            </Row>

            <Row style={{ paddingBottom: 28 }}>
              <Form.Item
                label={
                  <Typography title2 color="black65" style={{ width: 160 }}>
                    วินิจฉัย:
                  </Typography>
                }
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 0,
                  width: '100%',
                }}
                name="symptoms"
                required
              >
                <SelectTag
                  formName="symptoms"
                  rules={[{ required: true, message: 'กรุณาเลือกวินิจฉัย' }]}
                  tagColor="blue"
                  tagKey="name"
                  options={symptomOptions}
                  setSelectedItems={setSelectedSymptom}
                  setSelectedObj={setSelectedSymptomObj}
                  selectedItems={selectedSymptom}
                  isOpen={true}
                  setSearchValue={setSearchValue}
                />
              </Form.Item>
            </Row>

            <div style={{ paddingBottom: 28 }}>
              <Form.Item
                label={
                  <Typography title2 color="black65" style={{ width: 160 }}>
                    รายการยา
                  </Typography>
                }
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: 0,
                  width: '100%',
                }}
                name="medicineList"
              >
                <Form.List name="medicineList">
                  {() => {
                    return createElements(medicineOrderIndex)
                  }}
                </Form.List>
                <Button
                  iconLeft={
                    <img style={{ width: 23, height: 23 }} src={IconAdd} />
                  }
                  title="เพิ่มยา"
                  htmlType="button"
                  type="text"
                  style={{ margin: '10px 0px', padding: 0 }}
                  onClick={() => setMedicineOrderIndex((prev) => prev + 1)}
                />
              </Form.Item>
            </div>

            <div style={{ paddingBottom: 28 }}>
              <Form.Item
                label={
                  <Typography title2 color="black65" style={{ width: '100%' }}>
                    รายการบริการทางการแพทย์
                  </Typography>
                }
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: 0,
                  width: '100%',
                }}
              >
                <Form.List name="medicalServices">
                  {() => {
                    return createElementsService(medicalServiceOrderIndex)
                  }}
                </Form.List>
                <Button
                  iconLeft={
                    <img style={{ width: 23, height: 23 }} src={IconAdd} />
                  }
                  title="เพิ่มบริการทางการแพทย์"
                  htmlType="button"
                  type="text"
                  style={{ margin: '10px 0px', padding: 0 }}
                  onClick={() =>
                    setMedicalServiceOrderIndex((prev) => prev + 1)
                  }
                />
              </Form.Item>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}
            >
              <Row style={{ paddingBottom: 28 }}>
                <Form.Item
                  label={
                    <Typography title2 color="black65" style={{ width: 200 }}>
                      ราคารวมเคลมได้:
                    </Typography>
                  }
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 0,
                    width: '100%',
                  }}
                  name="totalPriceClaim"
                >
                  {_.sumBy(totalPriceClaim)}
                </Form.Item>
              </Row>
              <Row style={{ paddingBottom: 28 }}>
                <Form.Item
                  label={
                    <Typography title2 color="black65" style={{ width: 200 }}>
                      ราคารวมเคลมไม่ได้:
                    </Typography>
                  }
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 0,
                    width: '100%',
                  }}
                  name="totalPriceNotClaim"
                >
                  {_.sumBy(totalPriceNotClaim)}
                </Form.Item>
              </Row>
              <Row style={{ paddingBottom: 28 }}>
                <Form.Item
                  label={
                    <Typography title2 color="black65" style={{ width: 300 }}>
                      ราคารวมบริการทางการแพทย์:
                    </Typography>
                  }
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 0,
                    width: '100%',
                  }}
                  name="totalPriceNotClaim"
                >
                  {_.sumBy(totalPriceService)}
                </Form.Item>
              </Row>
            </div>
            <div
              style={{
                paddingTop: 20,
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                gap: 10,
              }}
            >
              <div style={{ display: 'flex' }}>
                <Button
                  htmlType="button"
                  title="ยกเลิก"
                  type="secondary"
                  onClick={() => setOpenConfirmModal(true)}
                  style={{ width: 100, marginRight: 16 }}
                />
                <Button
                  iconLeft={loading ? <Spin size="small" /> : null}
                  disabled={loading}
                  htmlType="submit"
                  title="บันทึก"
                  style={{ width: 100 }}
                />
              </div>
              <div style={{ display: 'flex' }}>
                <Button
                  style={{
                    background: theme.primary1,
                    borderColor: theme.primary1,
                  }}
                  htmlType="submit"
                  iconLeft={loading ? <Spin size="small" /> : null}
                  disabled={loading}
                  onClick={() => {
                    setPrint(true)
                  }}
                  title="บันทึก & พิมพ์"
                />
              </div>
            </div>
          </Card>
        </Form>
        <AlertModal
          type="done"
          title={!params?.id ? 'เพิ่มใบเคลมเรียบร้อย' : 'แก้ไขใบเคลมเรียบร้อย'}
          isModalVisible={openAlertModal}
          onSubmit={() => {
            setOpenAlertModal(false)
            history.push('/bi-claim')
          }}
        />
        <AlertModal
          type="error"
          title="กรุณากรอกข้อมูลให้ครบ"
          isModalVisible={openRequiredModal}
          onSubmit={() => {
            setOpenRequiredModal(false)
          }}
        />
        <AlertModal
          type="confirm"
          title="คุณยืนยันที่จะออกจากหน้านี้ใช่หรือไม่"
          isModalVisible={openConfirmModal}
          onCancel={() => setOpenConfirmModal(false)}
          onSubmit={() => {
            setOpenConfirmModal(false)
            history.push('/bi-claim')
          }}
        />
      </PageLayout>
    </Spin>
  )
}

export default Detail
