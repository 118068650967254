/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Checkbox, Col, Form, FormInstance, Row } from 'antd'
import Button from '../../../components/Button'
import Typography from '../../../components/Typography'
import { CaseDiagnosis, ClaimBilling } from '../../../interface'
import { scrollToTop } from '../../../utils/getName'

interface Props {
  id?: string
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
  setStep?: Dispatch<SetStateAction<number>> | undefined
  data: CaseDiagnosis | undefined
  form: FormInstance<any>
  exclude?: ClaimBilling
  step?: number
}

const StepClaim = ({
  setStep,
  data,
  form,
  exclude,
  setIsModalVisible,
  step,
}: Props): JSX.Element => {
  const medicineList = exclude ? exclude.medicineList : []
  const medicalSupplies = exclude ? exclude.medicalSupplies : []
  const medicalServices = exclude ? exclude.medicalServices : []
  const isClaim = exclude?.isSymptomExcluded || exclude?.isHospitalExcluded
  const [claimPrice, setClaimPrice] = useState(0)
  const onChange = (e: any, price: number) => {
    if (e.target.checked) {
      setClaimPrice((prev) => prev + price)
    } else {
      setClaimPrice((prev) => prev - price)
    }
  }
  useEffect(() => {
    if (data?.status === 'approved') {
      form.setFieldsValue({
        approvedMedicineListIds: medicineList
          ?.filter((item: any) => item.isClaimable === true)
          .map((item: any) => item.id),
      })
      form.setFieldsValue({
        approvedMedicalServiceIds: medicalServices
          ?.filter((item: any) => item.isClaimable === true)
          .map((item: any) => item.id),
      })
      form.setFieldsValue({
        approvedMedicalSupplyIds: medicalSupplies
          ?.filter((item: any) => item.isClaimable === true)
          .map((item: any) => item.id),
      })
      setClaimPrice(data.diagnosis.claimablePrice)
    }
  }, [exclude])

  const empty = () => {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
        }}
      >
        <Col span={10}>
          <Typography sub1 color="black">
            -
          </Typography>
        </Col>
        <Col span={4}>
          <Typography sub1 color="black">
            -
          </Typography>
        </Col>
        <Col span={4}>
          <Typography sub1 color="black">
            -
          </Typography>
        </Col>
        <Col span={5}>
          <Typography sub1 color="black">
            -
          </Typography>
        </Col>
        <Col span={1}></Col>
      </div>
    )
  }

  return (
    <>
      <Row style={{ marginBottom: 20, flexDirection: 'column' }}>
        <Typography sub2 color="black">
          รายการยา
        </Typography>
        <Row style={{ padding: '10px 0px' }} gutter={5}>
          <Col span={10}>
            <Typography sub4 color="black65">
              ชื่อยา
            </Typography>
          </Col>
          <Col span={4}>
            <Typography sub4 color="black65">
              ยูนิต
            </Typography>
          </Col>
          <Col span={4}>
            <Typography sub4 color="black65">
              จำนวน
            </Typography>
          </Col>
          <Col span={5}>
            <Typography sub4 color="black65">
              ราคา
            </Typography>
          </Col>
          <Col span={1}></Col>
          <Form.Item noStyle name="approvedMedicineListIds">
            <Checkbox.Group
              name="approvedMedicineListIds"
              style={{ width: '100%' }}
              disabled={data?.status === 'approved' ? true : false}
            >
              {medicineList.length > 0
                ? medicineList?.map((item: any) => {
                    return (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          paddingTop: 5,
                          alignItems: 'flex-start',
                        }}
                        key={item.id}
                      >
                        <Col span={10}>
                          <Typography
                            sub1
                            color={item.isExcluded ? 'black40' : 'black'}
                          >
                            {item.medicine.commonName}
                          </Typography>
                        </Col>
                        <Col span={4}>
                          <Typography
                            sub1
                            color={item.isExcluded ? 'black40' : 'black'}
                          >
                            {item.medicine.unit}
                          </Typography>
                        </Col>
                        <Col span={4}>
                          <Typography
                            sub1
                            color={item.isExcluded ? 'black40' : 'black'}
                          >
                            {item.quantity}
                          </Typography>
                        </Col>
                        <Col span={5}>
                          <Typography
                            sub1
                            color={item.isExcluded ? 'black40' : 'black'}
                          >
                            {item.totalPrice}
                          </Typography>
                        </Col>
                        <Col span={1}>
                          <Checkbox
                            value={item.id}
                            disabled={item.isExcluded}
                            onChange={(e) => onChange(e, item.totalPrice)}
                          ></Checkbox>
                        </Col>
                      </div>
                    )
                  })
                : empty()}
            </Checkbox.Group>
          </Form.Item>
        </Row>
      </Row>

      <Row style={{ marginBottom: 20, flexDirection: 'column' }}>
        <Typography sub2 color="black">
          เวชภัณฑ์
        </Typography>
        <Row style={{ padding: '10px 0px' }} gutter={5}>
          <Col span={10}>
            <Typography sub4 color="black65">
              ชื่อเวชภัณฑ์
            </Typography>
          </Col>
          <Col span={4}>
            <Typography sub4 color="black65">
              ยูนิต
            </Typography>
          </Col>
          <Col span={4}>
            <Typography sub4 color="black65">
              จำนวน
            </Typography>
          </Col>
          <Col span={5}>
            <Typography sub4 color="black65">
              ราคา
            </Typography>
          </Col>
          <Col span={1}></Col>
          <Form.Item noStyle name="approvedMedicalSupplyIds">
            <Checkbox.Group
              name="approvedMedicalSupplyIds"
              style={{ width: '100%' }}
              disabled={data?.status === 'approved' ? true : false}
            >
              {medicalSupplies.length > 0
                ? medicalSupplies?.map((item: any) => {
                    return (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          paddingTop: 5,
                          alignItems: 'flex-start',
                        }}
                        key={item.id}
                      >
                        <Col span={10}>
                          <Typography
                            sub1
                            color={item.isExcluded ? 'black40' : 'black'}
                          >
                            {item.supply.commonName}
                          </Typography>
                        </Col>
                        <Col span={4}>
                          <Typography
                            sub1
                            color={item.isExcluded ? 'black40' : 'black'}
                          >
                            {item.supply.unit}
                          </Typography>
                        </Col>
                        <Col span={4}>
                          <Typography
                            sub1
                            color={item.isExcluded ? 'black40' : 'black'}
                          >
                            {item.quantity}
                          </Typography>
                        </Col>
                        <Col span={5}>
                          <Typography
                            sub1
                            color={item.isExcluded ? 'black40' : 'black'}
                          >
                            {item.totalPrice}
                          </Typography>
                        </Col>
                        <Col span={1}>
                          <Checkbox
                            value={item.id}
                            disabled={item.isExcluded}
                            onChange={(e) => onChange(e, item.totalPrice)}
                          ></Checkbox>
                        </Col>
                      </div>
                    )
                  })
                : empty()}
            </Checkbox.Group>
          </Form.Item>
        </Row>
      </Row>

      <Row style={{ marginBottom: 20, flexDirection: 'column' }}>
        <Typography sub2 color="black">
          บริการทางการแพทย์
        </Typography>
        <Row style={{ padding: '10px 0px' }} gutter={5}>
          <Col span={10}>
            <Typography sub4 color="black65">
              ชื่อบริการ
            </Typography>
          </Col>
          <Col span={3}>
            <Typography sub4 color="black65">
              ประเภท
            </Typography>
          </Col>
          <Col span={3}>
            <Typography sub4 color="black65">
              จำนวน
            </Typography>
          </Col>
          <Col span={7}>
            <Typography sub4 color="black65">
              ราคา
            </Typography>
          </Col>
          <Col span={1}></Col>
          <Form.Item noStyle name="approvedMedicalServiceIds">
            <Checkbox.Group
              name="approvedMedicalServiceIds"
              style={{ width: '100%' }}
              disabled={data?.status === 'approved' ? true : false}
            >
              {medicalServices.length > 0
                ? medicalServices?.map((item: any) => {
                    return (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          paddingTop: 5,
                          alignItems: 'flex-start',
                        }}
                        key={item.id}
                      >
                        <Col span={10}>
                          <Typography
                            sub1
                            color={item.isExcluded ? 'black40' : 'black'}
                          >
                            {item.service.name}
                          </Typography>
                        </Col>
                        <Col span={3}>
                          <Typography
                            sub1
                            color={item.isExcluded ? 'black40' : 'black'}
                          >
                            {item.type}
                          </Typography>
                        </Col>
                        <Col span={3}>
                          <Typography
                            sub1
                            color={item.isExcluded ? 'black40' : 'black'}
                          >
                            {item.quantity}
                          </Typography>
                        </Col>
                        <Col span={7}>
                          <Typography
                            sub1
                            color={item.isExcluded ? 'black40' : 'black'}
                          >
                            {item.totalPrice}
                          </Typography>
                          <Typography sub4 color="black65">
                            {item.type === 'opd'
                              ? `(${item?.service?.minOpd} - ${item?.service?.maxOpd})`
                              : `(${item?.service?.minIpd} - ${item?.service?.maxIpd})`}
                          </Typography>
                        </Col>
                        <Col span={1}>
                          <Checkbox
                            value={item.id}
                            disabled={item.isExcluded}
                            onChange={(e) => onChange(e, item.totalPrice)}
                          ></Checkbox>
                        </Col>
                      </div>
                    )
                  })
                : empty()}
            </Checkbox.Group>
          </Form.Item>
        </Row>
      </Row>

      <Row gutter={10} style={{ marginBottom: 10 }}>
        <Col span={10}>
          <Typography sub2 color="black">
            ราคาทั้งหมด
          </Typography>
        </Col>
        <Col span={14}>
          <Typography sub1 color="black">
            {`${data?.diagnosis.totalPrice} บาท`}
          </Typography>
        </Col>
      </Row>
      <Row gutter={10} style={{ marginBottom: 10 }}>
        <Col span={10}>
          <Typography sub2 color="black">
            ยอดที่เคลม
          </Typography>
        </Col>
        <Col span={14}>
          <Typography sub1 color="black">
            {`${claimPrice} บาท`}
          </Typography>
        </Col>
      </Row>
      <br />
      <br />
      <Row justify={step === 5 ? 'space-between' : 'center'} align="middle">
        {step === 5 && (
          <Button
            htmlType="button"
            title="ย้อนกลับ"
            type="secondary"
            style={{ width: '48%' }}
            onClick={() => {
              scrollToTop()
              setStep?.(4)
            }}
          />
        )}
        {data?.status === 'approved' ? (
          <Button
            htmlType="button"
            title="ปิด"
            style={{ width: '48%' }}
            onClick={() => {
              setIsModalVisible(false)
              setStep?.(1)
              scrollToTop()
            }}
          />
        ) : (
          <Button
            htmlType="submit"
            title="อนุมัติการเคลม"
            disabled={isClaim}
            style={{ width: '48%' }}
          />
        )}
      </Row>
    </>
  )
}

export default StepClaim
