/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { Form, Row, Col, Divider, Input, message, Radio, Space } from 'antd'
import PageLayout from '../../../layouts/PageLayout'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Button from '../../../components/Button'
import Typography from '../../../components/Typography'
import Card from '../../../components/Card'
import IconAdd from '../../../image/IconAdd.png'
import StepAdd from './StepAdd'
import AlertModal from '../../../components/AlertModal'
import {
  Hospital,
  Insurance,
  InsurancePlan,
  MedicalSupply,
  Medicine,
  Symptom,
} from '../../../interface'
import request from '../../../utils/request'
import InsurancePlanCard from '../../../components/InsurancePlanCard'

const { TextArea } = Input
interface Params {
  id: string
}

type FormValues = {
  name: string
  companyId: number
  mainType: 'personal' | 'group'
  subType: 'main' | 'additional'
  shortDesc: string
  desc: string
}

const Detail = (): JSX.Element => {
  const [form] = Form.useForm()
  const urlLocation = useLocation()
  const isAdd = /add/.test(urlLocation.pathname)
  const params = useParams<Params>()
  const [openPlan, setOpenPlan] = useState(false)
  const [openAlertModal, setOpenAlertModal] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [data, setData] = useState<Insurance>()
  const [insurancePlan, setInsurancePlan] = useState<
    InsurancePlan[] | undefined
  >([])
  const [medicineOption, setMedicineOption] = useState<Medicine[]>([])
  const [medicalSupplyOption, setMedicalSupplyOption] = useState<
    MedicalSupply[]
  >([])
  const [symptomOption, setSymptomOption] = useState<Symptom[]>([])
  const [hospitalOption, setHospitalOption] = useState<Hospital[]>([])
  const [refresh, setRefresh] = useState(false)
  const [valueRadio, setValueRadio] = useState('')
  const history = useHistory()

  const onFinish = async (values: FormValues) => {
    const { name, subType, mainType, shortDesc, desc } = values
    try {
      await request.put(`/insurance/${params.id}`, {
        name,
        subType,
        mainType,
        shortDesc,
        desc,
      })
    } catch (e) {
      message.error('ดำเนินการผิดพลาด')
      console.log(e)
    }
  }

  const getData = async () => {
    if (params.id) {
      try {
        const res: Insurance = await request.get(`/insurance/${params.id}`)
        setData(res)
      } catch (error) {
        message.error('เรียกข้อมูลไม่สำเร็จ')
        setData(undefined)
      }
      return
    }
    setData(undefined)
  }

  const getInsurancePlan = async () => {
    if (params.id) {
      try {
        const res: any = await request.get(`/insurance/${params.id}/plan`, {
          params: {
            page: 1,
            limit: 6,
          },
        })
        setInsurancePlan(res.data)
      } catch (error) {
        message.error('เรียกข้อมูลไม่สำเร็จ')
        setData(undefined)
      }
      return
    }
    setData(undefined)
  }

  useEffect(() => {
    getData()
    getInsurancePlan()
  }, [params.id, refresh])

  const onRadioChange = (e: any) => {
    setValueRadio(e.target.value)
  }

  const getMedicine = async () => {
    try {
      const res: Medicine[] = await request.get(`/medicine/all`)
      setMedicineOption(res)
    } catch (error) {
      message.error('เรียกข้อมูลไม่สำเร็จ')
    }
  }
  const getMedicalSupply = async () => {
    try {
      const res: MedicalSupply[] = await request.get(`/medical-supply/all`)
      setMedicalSupplyOption(res)
    } catch (error) {
      message.error('เรียกข้อมูลไม่สำเร็จ')
    }
  }

  const getSymptom = async () => {
    try {
      const res: Symptom[] = await request.get(`/symptom/all`)
      setSymptomOption(res)
    } catch (error) {
      message.error('เรียกข้อมูลไม่สำเร็จ')
    }
  }

  const getHospital = async () => {
    try {
      const res: Hospital[] = await request.get(`/hospital/all`)
      setHospitalOption(res)
    } catch (error) {
      message.error('เรียกข้อมูลไม่สำเร็จ')
    }
  }

  useEffect(() => {
    getMedicine()
    getMedicalSupply()
    getSymptom()
    getHospital()
  }, [])

  useEffect(() => {
    form.setFieldsValue({
      name: data?.name,
      companyId: data?.companyId,
      subType: data?.subType,
      shortDesc: data?.shortDesc,
      desc: data?.desc,
      mainType: data?.mainType,
    })
  }, [data])

  return (
    <PageLayout title={isAdd ? 'เพิ่มกรมธรรม์' : 'แก้ไขกรมธรรม์'}>
      <Form
        form={form}
        name="InsuranceDetail"
        layout="vertical"
        onFinish={onFinish}
      >
        <Card style={{ padding: '20px', minHeight: 628 }}>
          <Row
            justify="space-between"
            style={{ paddingBottom: 28 }}
            gutter={32}
          >
            <Col span={12}>
              <Form.Item
                label={
                  <Typography title2 color="black65" style={{ width: 160 }}>
                    ชื่อกรมธรรม์:
                  </Typography>
                }
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 0,
                }}
                name="name"
              >
                <Input className="ant-input-class" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Row>
                <Typography title2 color="black65" style={{ width: 160 }}>
                  รหัสกรมธรรม์:
                </Typography>
                <Typography title2 color="black90">
                  {`${data?.id}`}
                </Typography>
              </Row>
            </Col>
          </Row>
          <Row justify="space-between" gutter={32}>
            <Col span={12}>
              <Form.Item
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  marginBottom: valueRadio === 'agency' ? 10 : 24,
                }}
                label={
                  <Typography title2 color="black65" style={{ width: 160 }}>
                    ประเภทหลัก
                  </Typography>
                }
                name="mainType"
              >
                <Radio.Group
                  onChange={onRadioChange}
                  value={data?.mainType ? valueRadio : undefined}
                >
                  <Space style={{ width: '100%' }}>
                    <Radio style={{ fontSize: 16 }} value="personal">
                      ประกันส่วนบุคคล
                    </Radio>
                    <Radio style={{ fontSize: 16 }} value="group">
                      ประกันกลุ่ม
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  marginBottom: valueRadio === 'agency' ? 10 : 24,
                }}
                label={
                  <Typography title2 color="black65" style={{ width: 160 }}>
                    ประเภทย่อย
                  </Typography>
                }
                name="subType"
              >
                <Radio.Group
                  onChange={onRadioChange}
                  value={data?.subType ? valueRadio : undefined}
                >
                  <Space style={{ width: '100%' }}>
                    <Radio style={{ fontSize: 16 }} value="main">
                      ประกันภัยหลัก
                    </Radio>
                    <Radio style={{ fontSize: 16 }} value="additional">
                      ประกันภัยเสริม
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Row>
                <Typography title2 color="black65" style={{ width: 160 }}>
                  บริษัทประกันภัย:
                </Typography>
                <Typography title2 color="black90">
                  {`${data?.company.abbreviation}`}
                </Typography>
              </Row>
            </Col>
          </Row>
          <Divider style={{ borderColor: '#CDCDCD' }} />
          <Form.Item
            label={
              <Typography sub1 color="black65">
                คำอธิบายอย่างสั้น
              </Typography>
            }
            name="shortDesc"
          >
            <TextArea rows={3} showCount maxLength={100} />
          </Form.Item>
          <Form.Item
            label={
              <Typography sub1 color="black65">
                คำอธิบายอย่างยาว
              </Typography>
            }
            name="desc"
          >
            <TextArea rows={3} showCount maxLength={300} />
          </Form.Item>
          <Divider style={{ borderColor: '#CDCDCD' }} />
          <Row justify="space-between" align="middle">
            <Typography title2 color="black65" style={{ width: 150 }}>
              แผนคุ้มครอง
            </Typography>
            <Button
              type="text"
              iconLeft={<img style={{ width: 23, height: 23 }} src={IconAdd} />}
              onClick={() => setOpenPlan(true)}
              title="เพิ่มแผนการคุ้มครอง"
              disabled={openPlan}
            />
          </Row>
          {openPlan ? (
            <StepAdd
              setOpenPlan={setOpenPlan}
              form={form}
              insuranceId={params.id}
              medicineOption={medicineOption}
              medicalSupplyOption={medicalSupplyOption}
              symptomOption={symptomOption}
              hospitalOption={hospitalOption}
              setRefresh={setRefresh}
            />
          ) : (
            <></>
          )}
          <>
            {insurancePlan?.map((item) => {
              return (
                <InsurancePlanCard
                  key={item.id}
                  data={item}
                  form={form}
                  medicineOption={medicineOption}
                  medicalSupplyOption={medicalSupplyOption}
                  symptomOption={symptomOption}
                  hospitalOption={hospitalOption}
                  setRefresh={setRefresh}
                />
              )
            })}
          </>
        </Card>
        {!openPlan && (
          <Row justify="end" align="middle" style={{ paddingTop: 20 }}>
            <Button
              htmlType="button"
              title="ยกเลิก"
              type="secondary"
              style={{ width: 100, marginRight: 16 }}
              onClick={() => history.goBack()}
            />
            <Button
              htmlType="submit"
              title="บันทึก"
              onClick={() => setOpenAlertModal(true)}
              style={{ width: 100 }}
            />
          </Row>
        )}
      </Form>
      <AlertModal
        type="done"
        title={isAdd ? 'เพิ่มกรมธรรม์เรียบร้อย' : 'แก้ไขกรมธรรม์เรียบร้อย'}
        isModalVisible={openAlertModal}
        onSubmit={() => {
          setOpenAlertModal(false)
          history.push('/insurance-list')
        }}
      />
      <AlertModal
        type="confirm"
        title="คุณยืนยันที่จะลบแผนการคุ้มครองนี้ใช่หรือไม่"
        isModalVisible={openConfirmModal}
        onCancel={() => setOpenConfirmModal(false)}
        onSubmit={() => {
          setOpenConfirmModal(false)
        }}
      />
    </PageLayout>
  )
}

export default Detail
