/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction } from 'react'
import { Form, Row, Modal as AntModal, Input, message } from 'antd'
import Button from '../../../components/Button'
import Typography from '../../../components/Typography'
import request from '../../../utils/request'
import { useHistory, useRouteMatch } from 'react-router-dom'

interface Props {
  id?: string
  isModalVisible: boolean
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
}

type FormValues = {
  en: string
}

const Modal = ({ isModalVisible, setIsModalVisible }: Props): JSX.Element => {
  const { path } = useRouteMatch()
  const history = useHistory()
  const [form] = Form.useForm()

  const onClose = () => {
    setIsModalVisible(false)
    form.resetFields()
  }

  const onFinish = async (values: FormValues) => {
    const { en } = values
    try {
      const res: any = await request.get(`/claiming-detail/en/${en}/exist`)
      if (res?.isExist) {
        message.error('ข้อมูลซ้ำ')
      } else {
        history.push(`${path}/add/${en}`)
      }
    } catch (e) {
      message.error('ข้อมูลผิดพลาด')
    }
  }

  return (
    <AntModal
      centered
      visible={isModalVisible}
      style={{ borderRadius: 8 }}
      bodyStyle={{ overflowX: 'scroll', maxHeight: 500 }}
      width={700}
      title={
        <Typography title1 color="black90">
          {'เพิ่ม'}
        </Typography>
      }
      footer={null}
      onCancel={onClose}
    >
      <Form
        form={form}
        name="BiclaimModal"
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          label={
            <Typography sub4 color="black65">
              หมายเลขเคส (EN)
            </Typography>
          }
          name="en"
        >
          <Input className="ant-input-class" />
        </Form.Item>
        <Row justify="center" align="middle">
          <Button htmlType="submit" title="ต่อไป" style={{ width: '50%' }} />
        </Row>
      </Form>
    </AntModal>
  )
}

export default Modal
