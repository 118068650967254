/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import PageLayout from '../../../layouts/PageLayout'
import Card from '../../../components/Card'
import Button from '../../../components/Button'
import Typography from '../../../components/Typography'
import { Table, Space, message, Pagination, Form, Select } from 'antd'
import Modal from './Modal'
import { useHistory, useRouteMatch } from 'react-router'
import PlusCircle from '../../../image/PlusCircle.png'
import request from '../../../utils/request'
import { Company, Insurance } from '../../../interface'
import AlertModal from '../../../components/AlertModal'

const { Option } = Select

const List = (): JSX.Element => {
  const [form] = Form.useForm()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { path } = useRouteMatch()
  const history = useHistory()
  const [page, setPage] = useState<number>(1)
  const [total, setTotal] = useState<number>()
  const [insuranceList, setInsuranceList] = useState<Insurance[]>([])
  const [refresh, setRefresh] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [selectId, setSelectId] = useState<number | undefined>(undefined)

  const getInsuranceList = async () => {
    try {
      const res: any = await request.get('/insurance', {
        params: {
          page: page,
          limit: 6,
        },
      })
      setInsuranceList(res.data)
      setTotal(res.total)
    } catch (error) {
      console.log(error)
    }
  }

  const onDelete = async (id: number | undefined) => {
    try {
      await request.delete(`/insurance/${id}`)
      message.success('ลบสำเร็จ')
      setOpenConfirmModal(false)
      setRefresh?.((prev) => !prev)
    } catch (err) {
      message.error('ดำเนินการผิดพลาด')
      console.log(err)
    }
  }

  useEffect(() => {
    getInsuranceList()
  }, [page, refresh])

  const columns = [
    {
      title: <Typography sub1>รหัส</Typography>,
      dataIndex: 'id',
      key: 'id',
      render: (text: string) => (
        <Typography sub2>{String(text).padStart(3, '0')}</Typography>
      ),
    },
    {
      title: <Typography sub1>ชื่อกรมธรรม์</Typography>,
      dataIndex: 'name',
      key: 'name',
      render: (text: string) => (
        <Typography sub2 color="black90">
          {text}
        </Typography>
      ),
    },
    {
      title: <Typography sub1>บริษัทประกันภัย</Typography>,
      dataIndex: 'company',
      key: 'company',
      render: (record: Company) => (
        <Typography sub2 color="black90">
          {record.name}
        </Typography>
      ),
    },
    {
      title: '',
      key: 'action',
      render: (_: any, record: Insurance) => (
        <Space size="middle">
          <a onClick={() => history.push(`${path}/edit/${record.id}`)}>
            <Typography sub1 color="primary2">
              แก้ไข
            </Typography>
          </a>
          <a
            onClick={() => {
              setSelectId(record.id)
              setOpenConfirmModal(true)
            }}
          >
            <Typography sub1 color="primary2">
              ลบ
            </Typography>
          </a>
        </Space>
      ),
    },
  ]

  const onSelectChange = async () => {
    try {
      const res: any = await request.get('/insurance', {
        params: {
          page: page,
          limit: 6,
          mainType:
            form.getFieldValue('mainType') === 'ทั้งหมด'
              ? null
              : form.getFieldValue('mainType')
              ? form.getFieldValue('mainType')
              : null,
          subType:
            form.getFieldValue('subType') === 'ทั้งหมด'
              ? null
              : form.getFieldValue('subType')
              ? form.getFieldValue('subType')
              : null,
        },
      })
      setInsuranceList(res.data)
      setTotal(res.total)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <PageLayout
      title="รายชื่อกรมธรรม์"
      shownBack={false}
      radioGroup={
        <div className="row">
          <Button
            title="เพิ่มกรมธรรม์"
            type="primary"
            iconLeft={
              <img style={{ width: 23, height: 23 }} src={PlusCircle} />
            }
            onClick={() => setIsModalVisible(true)}
          />
        </div>
      }
    >
      <Card style={{ padding: '0px 16px' }}>
        <br />
        <Form form={form} name="insuranceList" layout="vertical">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ width: '50%' }}></div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '50%',
              }}
            >
              <Form.Item name="mainType" style={{ width: '48%' }}>
                <Select
                  allowClear={true}
                  placeholder="ประเภทหลัก"
                  onChange={onSelectChange}
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  <Option value="ทั้งหมด">ทั้งหมด</Option>
                  <Option value="personal">ประกันส่วนบุคคล</Option>
                  <Option value="group">ประกันกลุ่ม</Option>
                </Select>
              </Form.Item>
              <Form.Item name="subType" style={{ width: '48%' }}>
                <Select
                  allowClear={true}
                  placeholder="ประเภทย่อย"
                  onChange={onSelectChange}
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  <Option value="ทั้งหมด">ทั้งหมด</Option>
                  <Option value="main">ประกันภัยหลัก</Option>
                  <Option value="additional">ประกันภัยเสริม</Option>
                </Select>
              </Form.Item>
            </div>
          </div>
        </Form>
        <Table
          columns={columns}
          dataSource={insuranceList}
          pagination={false}
        />
        <Pagination
          current={page}
          pageSize={6}
          total={total}
          style={{ padding: '20px 0px', textAlign: 'end' }}
          onChange={(page: number) => setPage(page)}
          showSizeChanger={false}
        />
      </Card>
      <Modal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
      <AlertModal
        type="confirm"
        title="คุณยืนยันที่จะลบรายชื่อกรมธรรม์นี้ใช่หรือไม่"
        isModalVisible={openConfirmModal}
        onCancel={() => setOpenConfirmModal(false)}
        onSubmit={() => {
          onDelete(selectId)
        }}
      />
    </PageLayout>
  )
}

export default List
