/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  Form,
  Row,
  Modal as AntModal,
  Input,
  Select,
  message,
  Radio,
  Space,
} from 'antd'
import Button from '../../../components/Button'
import Typography from '../../../components/Typography'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Company } from '../../../interface'
import request from '../../../utils/request'

interface Props {
  id?: string
  isModalVisible: boolean
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
}

type FormValues = {
  name: string
  subType: 'main' | 'additional'
  mainType: 'personal' | 'group'
  companyId: number
}

const { Option } = Select

const Modal = ({
  id,
  isModalVisible,
  setIsModalVisible,
}: Props): JSX.Element => {
  const { path } = useRouteMatch()
  const history = useHistory()
  const [form] = Form.useForm()
  const [companyList, setCompanyList] = useState<Company[]>([])
  const [valueRadio, setValueRadio] = useState('')

  const onRadioChange = (e: any) => {
    setValueRadio(e.target.value)
  }

  const onClose = () => {
    setIsModalVisible(false)
    if (!id) {
      form.resetFields()
      setValueRadio('')
    }
  }

  const onFinish = async (values: FormValues) => {
    const { name, subType, mainType, companyId } = values
    try {
      const res: any = await request.post(`/insurance`, {
        name,
        subType,
        mainType,
        companyId,
      })
      message.success('เพิ่มรายการสำเร็จ')
      history.push(`${path}/add/${res.id}`)
    } catch (e) {
      message.error('ดำเนินการผิดพลาด')
    }
  }

  const getCompany = async () => {
    try {
      const res: any = await request.get(`/insurance-company/all`)
      setCompanyList(res)
    } catch (error) {
      message.error('เรียกข้อมูลไม่สำเร็จ')
    }
  }

  useEffect(() => {
    getCompany()
  }, [])

  return (
    <AntModal
      centered
      visible={isModalVisible}
      style={{ borderRadius: 8 }}
      bodyStyle={{ overflowX: 'scroll', maxHeight: 500 }}
      width={700}
      title={
        <Typography title1 color="black90">
          {'เพิ่มรายชื่อกรมธรรม์'}
        </Typography>
      }
      footer={null}
      onCancel={onClose}
    >
      <Form
        form={form}
        name="InsuranceModal"
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          label={
            <Typography sub4 color="black65">
              ชื่อกรมธรรม์
            </Typography>
          }
          name="name"
          rules={[{ required: true, message: 'กรุณากรอกชื่อกรมธรรม์' }]}
        >
          <Input className="ant-input-class" />
        </Form.Item>
        <Form.Item
          label={
            <Typography sub4 color="black65">
              บริษัทประกันภัย
            </Typography>
          }
          name="companyId"
          rules={[{ required: true, message: 'กรุณาเลือกบริษัทประกันภัย' }]}
        >
          <Select
            placeholder="บริษัทประกันภัย"
            listHeight={128}
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            {companyList.map((x) => {
              return (
                <Option key={x.id} value={x.id}>
                  {x.abbreviation}
                </Option>
              )
            })}
          </Select>
        </Form.Item>
        <Form.Item
          style={{
            width: '100%',
            marginBottom: valueRadio === 'agency' ? 10 : 24,
          }}
          label={
            <Typography sub4 color="black65">
              ประเภทหลัก
            </Typography>
          }
          name="mainType"
        >
          <Radio.Group
            onChange={onRadioChange}
            value={id ? valueRadio : undefined}
          >
            <Space direction="vertical" style={{ width: '100%' }}>
              <Radio value="personal">ประกันส่วนบุคคล</Radio>
              <Radio value="group">ประกันกลุ่ม</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          style={{
            width: '100%',
            marginBottom: valueRadio === 'agency' ? 10 : 24,
          }}
          label={
            <Typography sub4 color="black65">
              ประเภทย่อย
            </Typography>
          }
          name="subType"
        >
          <Radio.Group
            onChange={onRadioChange}
            value={id ? valueRadio : undefined}
          >
            <Space direction="vertical" style={{ width: '100%' }}>
              <Radio value="main">ประกันภัยหลัก</Radio>
              <Radio value="additional">ประกันภัยเสริม</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Row justify="center" align="middle">
          <Button htmlType="submit" title="ต่อไป" style={{ width: '50%' }} />
        </Row>
      </Form>
    </AntModal>
  )
}

export default Modal
