/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import PageLayout from '../../layouts/PageLayout'
import Card from '../../components/Card'
import Button from '../../components/Button'
import Typography from '../../components/Typography'
import { Table, Space, message, Pagination } from 'antd'
import Modal from './Modal'
import PlusCircle from '../../image/PlusCircle.png'
import request from '../../utils/request'
import { Company } from '../../interface'
import AlertModal from '../../components/AlertModal'

const CompanyPage = (): JSX.Element => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [companyList, setCompanyList] = useState<Company[]>([])
  const [selectId, setSelectId] = useState<number | undefined>(undefined)
  const [page, setPage] = useState<number>(1)
  const [total, setTotal] = useState<number>()
  const [refresh, setRefresh] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)

  const getCompanyList = async () => {
    try {
      const res: any = await request.get('/insurance-company', {
        params: {
          page: page,
          limit: 6,
        },
      })
      setCompanyList(res.data)
      setTotal(res.total)
    } catch (error) {
      console.log(error)
    }
  }

  const onDelete = async (id: number | undefined) => {
    try {
      await request.delete(`/insurance-company/${id}`)
      message.success('ลบสำเร็จ')
      setOpenConfirmModal(false)
      setRefresh?.((prev) => !prev)
    } catch (err) {
      message.error('ดำเนินการผิดพลาด')
      console.log(err)
    }
  }

  useEffect(() => {
    getCompanyList()
  }, [page, refresh])

  const columns = [
    {
      title: <Typography sub1>รหัส</Typography>,
      dataIndex: 'id',
      key: 'id',
      render: (text: string) => (
        <Typography sub2>{String(text).padStart(3, '0')}</Typography>
      ),
    },
    {
      title: <Typography sub1>อักษรย่อ</Typography>,
      dataIndex: 'abbreviation',
      key: 'abbreviation',
      render: (text: string) => (
        <Typography sub2 color="black90">
          {text}
        </Typography>
      ),
    },
    {
      title: <Typography sub1>ชื่อเต็มบริษัท</Typography>,
      dataIndex: 'name',
      key: 'name',
      render: (text: string) => (
        <Typography sub2 color="black90">
          {text}
        </Typography>
      ),
    },
    {
      title: '',
      key: 'action',
      render: (_: any, record: Company) => (
        <Space size="middle">
          <a
            onClick={() => {
              setSelectId(record.id)
              setIsModalVisible(true)
            }}
          >
            <Typography sub1 color="primary2">
              แก้ไข
            </Typography>
          </a>
          <a
            onClick={() => {
              setSelectId(record.id)
              setOpenConfirmModal(true)
            }}
          >
            <Typography sub1 color="primary2">
              ลบ
            </Typography>
          </a>
        </Space>
      ),
    },
  ]
  return (
    <PageLayout
      title="รายชื่อบริษัทประกันภัย"
      shownBack={false}
      radioGroup={
        <div className="row">
          <Button
            title="เพิ่มบริษัทประกันภัย"
            type="primary"
            iconLeft={
              <img style={{ width: 23, height: 23 }} src={PlusCircle} />
            }
            onClick={() => {
              setSelectId(undefined)
              setIsModalVisible(true)
            }}
          />
        </div>
      }
    >
      <Card style={{ padding: '0px 16px' }}>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={companyList}
          pagination={false}
        />
        <Pagination
          current={page}
          pageSize={6}
          total={total}
          style={{ padding: '20px 0px', textAlign: 'end' }}
          onChange={(page: number) => setPage(page)}
          showSizeChanger={false}
        />
      </Card>
      <Modal
        id={selectId}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        setRefresh={setRefresh}
      />
      <AlertModal
        type="confirm"
        title="คุณยืนยันที่จะลบรายชื่อบริษัทนี้ใช่หรือไม่"
        desc="ข้อมูลทุกอย่างที่เกี่ยวข้องกับบริษัทจะถูกลบออก"
        isModalVisible={openConfirmModal}
        onCancel={() => setOpenConfirmModal(false)}
        onSubmit={() => {
          onDelete(selectId)
        }}
      />
    </PageLayout>
  )
}

export default CompanyPage
