/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useState,
  Dispatch,
  SetStateAction,
  useMemo,
  useEffect,
} from 'react'
import { Form, message, Modal as AntModal } from 'antd'
import Typography from '../../../components/Typography'
import Step1 from './Step1'
import Step2 from './Step2'
import request from '../../../utils/request'
import { CaseDiagnosis, Insured } from '../../../interface'

interface Props {
  id?: string | number
  isModalVisible: boolean
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
  setRefresh?: Dispatch<SetStateAction<boolean>>
  setSelectId: React.Dispatch<React.SetStateAction<number | undefined>>
}

type FormValues = {
  insuredId: number
  insuredPlanId: number
  en: string
}

const ModalAddCase = ({
  id,
  isModalVisible,
  setIsModalVisible,
  setRefresh,
  setSelectId,
}: Props): JSX.Element => {
  const [form] = Form.useForm()
  const [step, setStep] = useState(1)
  const [data, setData] = useState<CaseDiagnosis>()
  const [user, setUser] = useState<Insured>()
  const [idCardImage, setIdCardImage] = useState<number>()
  const [insuranceCardImage, setInsuranceCardImage] = useState<number>()

  const onClose = () => {
    setIsModalVisible(false)
    setStep(1)
    if (!id) {
      form.resetFields()
    }
    setSelectId(undefined)
    setIdCardImage(undefined)
    setInsuranceCardImage(undefined)
  }

  const getData = async () => {
    if (id) {
      try {
        const res: CaseDiagnosis = await request.get(`/diagnosis/${id}`)
        setData(res)
      } catch (error) {
        console.log(error)
        message.error('เรียกข้อมูลไม่สำเร็จ')
        setData(undefined)
      }
      return
    }
    setData(undefined)
  }

  useEffect(() => {
    getData()
  }, [id])

  useEffect(() => {
    form.setFieldsValue({
      nationalId: data?.insured.nationalId || data?.insured.passportId,
      insuredPlanId: data?.insuredPlanId,
    })
    setIdCardImage(data?.idCardImage)
    setInsuranceCardImage(data?.insuranceCardImage)
  }, [data])

  const Content = useMemo(() => {
    if (step === 1) return Step1
    else if (step === 2) return Step2
    else return Step1
  }, [step])

  const onFinish = async (values: FormValues) => {
    const { insuredId, insuredPlanId, en } = values
    try {
      const payload = {
        idCardImage: idCardImage,
        insuranceCardImage: insuranceCardImage,
        insuredId,
        insuredPlanId,
        en,
      }
      if (id) {
        await request.put(`/diagnosis/${id}`, payload)
        message.success('แก้ไขรายการสำเร็จ')
      } else {
        await request.post(`/diagnosis`, payload)
        message.success('เพิ่มรายการสำเร็จ')
      }
      onClose()
      setRefresh?.((prev) => !prev)
    } catch (e) {
      message.error('ดำเนินการผิดพลาด')
      console.log(e)
    }
  }

  return (
    <AntModal
      centered
      visible={isModalVisible}
      style={{ borderRadius: 8 }}
      bodyStyle={{ overflowX: 'scroll', maxHeight: 500 }}
      width={700}
      title={
        <Typography title1 color="black90">
          {id ? 'แก้ไขเคส' : 'เพิ่มเคส'}
        </Typography>
      }
      footer={null}
      onCancel={onClose}
    >
      <Form form={form} name="CaseModal" layout="vertical" onFinish={onFinish}>
        <Content
          id={id}
          data={data}
          setIsModalVisible={setIsModalVisible}
          setStep={setStep}
          form={form}
          setUser={setUser}
          user={user}
          setIdCardImage={setIdCardImage}
          idCardImage={idCardImage}
          setInsuranceCardImage={setInsuranceCardImage}
          insuranceCardImage={insuranceCardImage}
        />
      </Form>
    </AntModal>
  )
}

export default ModalAddCase
