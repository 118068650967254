import styled from '@emotion/styled'
import React, { useState } from 'react'
import { Modal, Tag } from 'antd'
import Typography from '../../components/Typography'
interface Props {
  symptoms: { name: string }[]
}
const SiteRender = styled.div``
function ModalSymptoms({ symptoms }: Props): JSX.Element {
  const [visible, setVisible] = useState(false)
  return (
    <>
      <div style={{ cursor: 'pointer' }} onClick={() => setVisible(true)}>
        <SiteRender>
          {symptoms?.length > 0 ? (
            <Typography color="primary1" style={{ cursor: 'pointer' }}>
              โรค / อาการ
            </Typography>
          ) : (
            <Typography>-</Typography>
          )}
        </SiteRender>
      </div>
      <Modal
        centered
        bodyStyle={{ minHeight: 250 }}
        width={700}
        onCancel={() => setVisible(false)}
        visible={visible}
        footer={null}
      >
        <Typography>โรค / อาการที่เคลมยานี้ได้</Typography>
        <div
          style={{
            marginTop: 16,
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            maxHeight: 400,
            overflow: 'auto',
          }}
        >
          {symptoms.map((x, indx) => {
            return (
              <Tag color="blue" key={indx} style={{ marginBottom: 3 }}>
                {x.name}
              </Tag>
            )
          })}
        </div>
      </Modal>
    </>
  )
}

export default ModalSymptoms
