import React, { useEffect, useState } from 'react'
import { Col, Form, Input, message, Row, Select } from 'antd'
import Typography from '../../../../components/Typography'
import DatePicker from '../../../../components/DatePicker'
import dayjs from 'dayjs'
import request from '../../../../utils/request'
import { Insured } from '../../../../interface'
import Button from '../../../../components/Button'
import { useHistory } from 'react-router'

const { Option } = Select
interface Props {
  id: string
}

type FormValues = {
  nationalId: string
  passportId: string
  hn: string
  title: 'mr' | 'ms' | 'mrs' | 'boy' | 'girl'
  firstName: string
  lastName: string
  birthDate: string
  maritalStatus: 'single' | 'married' | 'widow' | 'divorced' | 'separated'
}

const profilePage = ({ id }: Props): JSX.Element => {
  const [form] = Form.useForm()
  const [data, setData] = useState<Insured>()
  const history = useHistory()

  const getData = async () => {
    try {
      const res: Insured = await request.get(`/insured/${id}`)
      setData(res)
    } catch (error) {
      message.error('เรียกข้อมูลไม่สำเร็จ')
    }
    return
  }

  useEffect(() => {
    getData()
  }, [id])

  useEffect(() => {
    form.setFieldsValue({
      nationalId: data?.nationalId,
      passportId: data?.passportId,
      hn: data?.hn,
      title: data?.title,
      firstName: data?.firstName,
      lastName: data?.lastName,
      birthDate: dayjs(data?.birthDate),
      maritalStatus: data?.maritalStatus,
    })
  }, [data])

  const onFinish = async (values: FormValues) => {
    const {
      nationalId,
      passportId,
      hn,
      title,
      firstName,
      lastName,
      birthDate,
      maritalStatus,
    } = values
    try {
      if (id) {
        await request.put(`/insured/${id}`, {
          nationalId: nationalId ? nationalId : null,
          passportId: passportId ? passportId : null,
          hn,
          title,
          firstName,
          lastName,
          birthDate,
          maritalStatus,
        })
        message.success('แก้ไขรายการสำเร็จ')
      }
      history.push('/user-list')
    } catch (e) {
      message.error('ดำเนินการผิดพลาด')
      console.log(e)
    }
  }

  return (
    <>
      <Col span={24}>
        <br />
        <Form
          form={form}
          name="ProfileDetail"
          layout="vertical"
          onFinish={onFinish}
        >
          <Row justify="start">
            <Col xl={12} lg={12} md={15}>
              <Form.Item
                label={
                  <Typography sub4 color="black65">
                    หมายเลขบัตรประชาชน
                  </Typography>
                }
                name="nationalId"
              >
                <Input className="ant-input-class" maxLength={13} />
              </Form.Item>
              <Form.Item
                label={
                  <Typography sub4 color="black65">
                    พาสปอร์ต
                  </Typography>
                }
                name="passportId"
              >
                <Input className="ant-input-class" />
              </Form.Item>
              <Form.Item
                label={
                  <Typography sub4 color="black65">
                    hn
                  </Typography>
                }
                name="hn"
                rules={[{ required: true, message: 'กรุณากรอก hn' }]}
              >
                <Input className="ant-input-class" />
              </Form.Item>
              <Form.Item
                label={
                  <Typography sub4 color="black65">
                    คำนำหน้าชื่อ
                  </Typography>
                }
                name="title"
                rules={[{ required: true, message: 'กรุณาเลือกคำนำหน้าชื่อ' }]}
              >
                <Select placeholder="เลือกคำนำหน้าชื่อ">
                  <Option value="mr">นาย</Option>
                  <Option value="ms">นางสาว</Option>
                  <Option value="mrs">นาง</Option>
                  <Option value="girl">เด็กหญิง </Option>
                  <Option value="boy">เด็กชาย</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label={
                  <Typography sub4 color="black65">
                    ชื่อจริง
                  </Typography>
                }
                name="firstName"
                rules={[{ required: true, message: 'กรุณากรอกชื่อจริง' }]}
              >
                <Input className="ant-input-class" />
              </Form.Item>
              <Form.Item
                label={
                  <Typography sub4 color="black65">
                    นามสกุล
                  </Typography>
                }
                name="lastName"
                rules={[{ required: true, message: 'กรุณากรอกนามสกุล' }]}
              >
                <Input className="ant-input-class" />
              </Form.Item>
              <Form.Item
                label={
                  <Typography sub4 color="black65">
                    วันเดือนปีเกิด
                  </Typography>
                }
                name="birthDate"
                rules={[{ required: true, message: 'กรุณากรอกวันเดือนปีเกิด' }]}
              >
                <DatePicker placeholder="เลือกวันที่" />
              </Form.Item>
              <Form.Item
                label={
                  <Typography sub4 color="black65">
                    สถานภาพสมรส
                  </Typography>
                }
                name="maritalStatus"
                rules={[{ required: true, message: 'กรุณาเลือกสถานภาพสมรส' }]}
              >
                <Select placeholder="เลือกสถานภาพสมรส">
                  <Option value="single">โสด</Option>
                  <Option value="married">สมรส</Option>
                  <Option value="widow">หม้าย</Option>
                  <Option value="divorced">หย่า</Option>
                  <Option value="separated">แยกกันอยู่</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end">
            <Col xl={9} lg={9} md={12}>
              <div style={{ display: 'flex', gap: 15 }}>
                <Button
                  title="ยกเลิก"
                  htmlType={'button'}
                  type="secondary"
                  style={{ width: '100%' }}
                  onClick={() => history.push('/user-list')}
                />
                <Button title="บันทึก" style={{ width: '100%' }} />
              </div>
            </Col>
          </Row>
        </Form>
      </Col>
    </>
  )
}

export default profilePage
