/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction } from 'react'
import { Form, Row, Input, FormInstance } from 'antd'
import Button from '../../../components/Button'
import Typography from '../../../components/Typography'
import { scrollToTop } from '../../../utils/getName'
import UploadMultipleFile from '../../../components/UploadMultipleFile'
import { CaseDiagnosis } from '../../../interface'

interface Props {
  id?: string
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
  setStep?: Dispatch<SetStateAction<number>> | undefined
  form: FormInstance<any>
  data: CaseDiagnosis | undefined
}

const { TextArea } = Input
const Step4 = ({ setStep, form, data }: Props): JSX.Element => {
  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <Form.Item
          label={
            <Typography sub4 color="black65">
              เหตุผลของการแอดมิท
            </Typography>
          }
        >
          <Form.Item noStyle name="admitReason">
            <TextArea
              rows={3}
              autoSize={{ minRows: 3, maxRows: 3 }}
              allowClear={true}
            />
          </Form.Item>
          <UploadMultipleFile
            formName="admitReasonAttachments"
            form={form}
            data={data?.diagnosis.admitReasonAttachments}
          />
        </Form.Item>
        <Form.Item
          style={{ width: '100%' }}
          label={
            <Typography sub4 color="black65">
              แผนการรักษา
            </Typography>
          }
        >
          <Form.Item noStyle name="treatmentPlan">
            <TextArea
              rows={3}
              autoSize={{ minRows: 3, maxRows: 3 }}
              allowClear={true}
            />
          </Form.Item>
          <UploadMultipleFile
            formName="treatmentPlanAttachments"
            form={form}
            data={data?.diagnosis.treatmentPlanAttachments}
          />
        </Form.Item>
      </div>
      <Row justify="space-between" align="middle">
        <Button
          htmlType="submit"
          title="ย้อนกลับ"
          type="secondary"
          style={{ width: '48%' }}
          onClick={() => {
            scrollToTop()
            setStep?.(3)
          }}
        />
        <Button
          htmlType="submit"
          title="ต่อไป"
          style={{ width: '48%' }}
          onClick={() => {
            scrollToTop()
            setStep?.(5)
          }}
        />
      </Row>
    </div>
  )
}

export default Step4
