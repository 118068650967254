/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import IconAdd from '../../../../image/IconAdd.png'
import Modal from './ModalDisease'
import Button from '../../../../components/Button'
import { Col, message, Row } from 'antd'
import Typography from '../../../../components/Typography'
import { UserSymptom } from '../../../../interface'
import request from '../../../../utils/request'
import dayjs from 'dayjs'
import AlertModal from '../../../../components/AlertModal'

interface Props {
  id: string
}
const diseasePage = ({ id }: Props): JSX.Element => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [userSymptomList, setUserSymptomList] = useState<UserSymptom[]>([])
  const [selectUserSymptomId, setSelectUserSymptomId] = useState<
    number | undefined
  >(undefined)
  const [refresh, setRefresh] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)

  const getUserSymptom = async () => {
    try {
      const res: any = await request.get(`/insured/${id}/symptom`, {
        params: {
          page: 1,
          limit: 6,
        },
      })
      setUserSymptomList(res.data)
    } catch (error) {
      message.error('เรียกข้อมูลไม่สำเร็จ')
    }
  }

  useEffect(() => {
    getUserSymptom()
  }, [refresh])

  const onDelete = async () => {
    try {
      await request.delete(`/insured/${id}/symptom/${selectUserSymptomId}`)
      message.success('ลบโรคประจำตัวสำเร็จ')
      setOpenConfirmModal(false)
      setRefresh?.((prev) => !prev)
      setSelectUserSymptomId(undefined)
    } catch (err) {
      message.error('ดำเนินการผิดพลาด')
    }
  }

  const getType = (type: 'chronic' | 'infectious' | 'congenital') => {
    if (type === 'chronic') {
      return 'โรคเรื้อรัง'
    } else if (type === 'infectious') {
      return 'โรคติดเชื้อ / อุบัติเหตุ'
    } else {
      return 'โรคที่เป็นแต่กำเนิด'
    }
  }

  return (
    <Col span={24}>
      <br />
      {userSymptomList?.map((item) => {
        return (
          <div
            key={item.id}
            style={{ padding: '30px 0px', borderBottom: '1px solid #C0C0C0' }}
          >
            <Row style={{ marginBottom: 5 }}>
              <Col span={17}>
                <Row>
                  <Col span={8}>
                    <Typography sub1>{item.symptom.name}</Typography>
                  </Col>
                  <Col span={8}>
                    <Typography sub1>{getType(item.type)}</Typography>
                  </Col>
                  <Col span={8}>
                    <Typography sub1>
                      {dayjs(item.detectionDate).format('DD/MM/YYYY')}
                    </Typography>
                  </Col>
                </Row>
              </Col>
              <Col span={7}>
                <Row justify="end">
                  <div
                    style={{
                      borderRight: '1px solid #A2A2A2',
                      padding: '0px 10px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setSelectUserSymptomId(item.id)
                      setIsModalVisible(true)
                    }}
                  >
                    <Typography sub2 color="black65">
                      แก้ไขข้อมูล
                    </Typography>
                  </div>
                  <div
                    style={{
                      padding: '0px 10px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setSelectUserSymptomId(item.id)
                      setOpenConfirmModal(true)
                    }}
                  >
                    <Typography sub2 color="black65">
                      ลบ
                    </Typography>
                  </div>
                </Row>
              </Col>
            </Row>
          </div>
        )
      })}
      <Button
        onClick={() => setIsModalVisible(true)}
        iconLeft={<img style={{ width: 23, height: 23 }} src={IconAdd} />}
        title="เพิ่มโรคประจำตัว"
        type="text"
        style={{ margin: '15px 0px', padding: 0 }}
      />
      <br />
      <br />
      <Modal
        id={id}
        selectUserSymptomId={selectUserSymptomId}
        setSelectUserSymptomId={setSelectUserSymptomId}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        setRefresh={setRefresh}
      />
      <AlertModal
        type="confirm"
        title="คุณยืนยันที่จะลบโรคประจำตัวนี้ใช่หรือไม่"
        isModalVisible={openConfirmModal}
        onCancel={() => {
          setSelectUserSymptomId(undefined)
          setOpenConfirmModal(false)
        }}
        onSubmit={() => {
          onDelete()
        }}
      />
    </Col>
  )
}

export default diseasePage
