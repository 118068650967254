/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  Form,
  Row,
  Select,
  Col,
  FormInstance,
  message,
  InputNumber,
} from 'antd'
import Button from '../../../components/Button'
import Typography from '../../../components/Typography'
import IconAdd from '../../../image/IconAdd.png'
import { CaseDiagnosis, MedicalService } from '../../../interface'
import request from '../../../utils/request'
import { SelectValue } from 'antd/lib/select'
import { scrollToTop } from '../../../utils/getName'

interface Props {
  id?: string
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
  setStep?: Dispatch<SetStateAction<number>> | undefined
  form: FormInstance<any>
  medicalService?: MedicalService[]
  data: CaseDiagnosis | undefined
}

interface Range {
  min: number
  max: number
}
const { Option } = Select

const Step7 = ({ setStep, form, medicalService, data }: Props): JSX.Element => {
  const [medicalServiceOption, setMedicalServiceOption] = useState<
    MedicalService[] | undefined
  >(medicalService)
  // const [price, setPrice] = useState<number[]>([])
  const [priceRange, setPriceRange] = useState<Range[]>([])
  const [medicalServiceOrderIndex, setMedicalServiceOrderIndex] =
    useState<number>(
      data
        ? data.diagnosis.medicalServices.length === 0
          ? form.getFieldValue('medicalServices')
            ? form.getFieldValue('medicalServices').length
            : 1
          : form.getFieldValue('medicalServices').length
        : 1
    )

  const onSearch = async (value: string) => {
    try {
      const res: MedicalService[] = await request.get(
        `/medical-service/keyword`,
        {
          params: {
            key: value,
          },
        }
      )
      setMedicalServiceOption(res)
    } catch (error) {
      message.error('เรียกข้อมูลไม่สำเร็จ')
    }
  }

  const onChange = async (value: SelectValue, index: number) => {
    try {
      const res: any = await request.get(`/medical-service/${value}`)
      const medicalServices = form.getFieldValue('medicalServices')
      const newList = medicalServices.map((item: any, idx: number) =>
        idx === index
          ? {
              serviceId: res.id,
              type: 'ipd',
              quantity: 1,
            }
          : item
      )
      form.setFieldsValue({
        medicalServices: newList,
      })
      const range = medicalServices.map((_: any, idx: number) =>
        idx === index
          ? {
              min: res.minIpd,
              max: res.maxIpd,
            }
          : priceRange[idx]
      )
      setPriceRange(range)
      // setPrice(
      //   newList.map((item: any) => {
      //     return item.price / item.quantity
      //   })
      // )
    } catch (error) {
      message.error('เรียกข้อมูลไม่สำเร็จ')
    }
  }

  const getInitialRange = async () => {
    try {
      const list: Range[] = await Promise.all(
        form
          .getFieldValue('medicalServices')
          ?.map(async (item: { serviceId: number; type: 'ipd' | 'opd' }) => {
            return await request
              .get(`/medical-service/${item.serviceId}`)
              .then((res: any) => {
                return {
                  min: item.type === 'ipd' ? res.minIpd : res.minOpd,
                  max: item.type === 'ipd' ? res.maxIpd : res.maxOpd,
                }
              })
          })
      )
      setPriceRange(list)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getInitialRange()
  }, [data])

  // const onQuantityChange = async (value: number, index: number) => {
  //   try {
  //     const medicalServices = form.getFieldValue('medicalServices')
  //     const res: any = await request.get(
  //       `/medical-service/${medicalServices[index].serviceId}`
  //     )
  //     const newList = medicalServices.map((item: any, idx: number) =>
  //       idx === index
  //         ? {
  //             serviceId: res.id,
  //             type: medicalServices[index].type,
  //             quantity: item.quantity,
  //             price: value * price[index],
  //           }
  //         : item
  //     )
  //     form.setFieldsValue({
  //       medicalServices: newList,
  //     })
  //   } catch (error) {
  //     message.error('เรียกข้อมูลไม่สำเร็จ')
  //   }
  // }

  const onTypeChange = async (value: SelectValue, index: number) => {
    try {
      const medicalServices = form.getFieldValue('medicalServices')
      const res: any = await request.get(
        `/medical-service/${medicalServices[index].serviceId}`
      )
      const newList = medicalServices.map((item: any, idx: number) =>
        idx === index
          ? {
              serviceId: res.id,
              type: value,
              quantity: 1,
            }
          : item
      )
      form.setFieldsValue({
        medicalServices: newList,
      })
      const range = medicalServices.map((_: any, idx: number) =>
        idx === index
          ? {
              min: value === 'ipd' ? res.minIpd : res.minOpd,
              max: value === 'ipd' ? res.maxIpd : res.maxOpd,
            }
          : priceRange[idx]
      )
      setPriceRange(range)
      // setPriceRange({
      //   min: value === 'ipd' ? res.minIpd : res.minOpd,
      //   max: value === 'ipd' ? res.maxIpd : res.maxOpd,
      // })
      // setPrice(
      //   newList.map((item: any) => {
      //     return item.price / item.quantity
      //   })
      // )
    } catch (error) {
      message.error('เรียกข้อมูลไม่สำเร็จ')
    }
  }

  const onDeleteList = async (index: number) => {
    const medicalServices = form.getFieldValue('medicalServices')
    if (medicalServices) {
      const newList = medicalServices.filter((item: any, indx: number) => {
        return indx !== index
      })
      // const newPrice = price.filter((_: any, indx: number) => {
      //   return indx !== index
      // })
      form.setFieldsValue({
        medicalServices: newList,
      })
      setPriceRange((prev) => prev.filter((_, idx) => idx != index))
      // setPrice(newPrice)
    }
  }

  const createElements = (n: number) => {
    const elements = []
    for (let i = 0; i < n; i++) {
      elements.push(
        <Row
          justify="space-between"
          style={{ marginBottom: i === n - 1 ? 0 : 20 }}
        >
          <Col span={24}>
            <Form.Item
              className="medicineList"
              style={{ margin: '10px 0px' }}
              label={
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Typography sub4 color="black65">
                    ชื่อบริการ
                  </Typography>
                  {medicalServiceOrderIndex > 1 ? (
                    <a
                      onClick={() => {
                        onDeleteList(i)
                        setMedicalServiceOrderIndex((prev) => prev - 1)
                      }}
                    >
                      <Typography sub1 color="primary1">
                        ลบรายการ
                      </Typography>
                    </a>
                  ) : null}
                </div>
              }
              name={[i, 'serviceId']}
            >
              <Select
                className="ant-input-class"
                listHeight={128}
                showSearch
                onSearch={onSearch}
                optionFilterProp="children"
                onChange={(value) => onChange(value, i)}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {medicalServiceOption?.map((x) => {
                  return (
                    <Option key={x.id} value={x.id} title={x.name}>
                      {`${x.name} ${x.otherNames.join(',')}`}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              style={{ margin: 0 }}
              label={
                <Typography sub4 color="black65">
                  ประเภท
                </Typography>
              }
              name={[i, 'type']}
            >
              <Select
                onChange={(value) => onTypeChange(value, i)}
                disabled={
                  form.getFieldValue('medicalServices')
                    ? form.getFieldValue('medicalServices')[i]
                      ? false
                      : true
                    : true
                }
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                <Option value="ipd">IPD</Option>
                <Option value="opd">OPD</Option>
              </Select>
            </Form.Item>
          </Col>
          {/* <Col span={7}>
            <Form.Item
              style={{ margin: 0 }}
              label={
                <Typography sub4 color="black65">
                  จำนวน
                </Typography>
              }
              name={[i, 'quantity']}
            >
              <InputNumber
                style={{ width: '100%' }}
                onChange={(value) => onQuantityChange(value, i)}
                min={1}
                disabled={
                  form.getFieldValue('medicalServices')
                    ? form.getFieldValue('medicalServices')[i]
                      ? false
                      : true
                    : true
                }
              />
            </Form.Item>
          </Col> */}
          <Col span={16}>
            <Form.Item
              style={{ margin: 0 }}
              label={
                <Typography sub4 color="black65">
                  ราคา ({`${priceRange[i]?.min} - ${priceRange[i]?.max}`})
                </Typography>
              }
              name={[i, 'price']}
              rules={[
                {
                  type: 'number',
                  message: `กรอกราคา`,
                },
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                disabled={
                  form.getFieldValue('medicalServices')
                    ? form.getFieldValue('medicalServices')[i]
                      ? false
                      : true
                    : true
                }
              />
            </Form.Item>
          </Col>
        </Row>
      )
    }
    return elements
  }

  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <Typography sub2 color="black">
          รายการบริการทางการแพทย์
        </Typography>
        <Form.List name="medicalServices">
          {() => {
            return createElements(medicalServiceOrderIndex)
          }}
        </Form.List>
        <Button
          iconLeft={<img style={{ width: 23, height: 23 }} src={IconAdd} />}
          title="เพิ่มบริการทางการแพทย์"
          htmlType="button"
          type="text"
          style={{ margin: '10px 0px', padding: 0 }}
          onClick={() => setMedicalServiceOrderIndex((prev) => prev + 1)}
        />
      </div>
      <Row justify="space-between" align="middle">
        <Button
          htmlType="submit"
          title="ย้อนกลับ"
          type="secondary"
          style={{ width: '48%' }}
          onClick={() => {
            scrollToTop()
            setStep?.(6)
          }}
        />
        <Button
          htmlType="submit"
          title="บันทึกผลวินิจฉัย"
          style={{ width: '48%' }}
          // onClick={onClose}
        />
      </Row>
    </div>
  )
}

export default Step7
