import React, { useState, useEffect } from 'react'
import { Row, Col, Divider, message } from 'antd'
import PageLayout from '../../../layouts/PageLayout'
import Button from '../../../components/Button'
import Typography from '../../../components/Typography'
import Card from '../../../components/Card'
import { ClaimList } from '../../../interface'
import request from '../../../utils/request'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import ModalDiagnosticDetail from '../../DiagnosticListPage/ModalDiagnosticDetail/ModalDiagnosticDetail'
import ModalClaim from '../../DiagnosticListPage/ModalDiagnosticDetail/ModalClaim'
import IconCheck from '../../../image/CheckCircle.svg'
import { getImage } from '../../../utils/getName'

interface Props {
  id: string
}

const Detail = (): JSX.Element => {
  const [data, setData] = useState<ClaimList>()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isModalClaim, setIsModalClaim] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const params = useParams<Props>()
  const [, setSelectId] = useState<number | undefined>(undefined)

  const getData = async () => {
    try {
      const res: ClaimList = await request.get(`/claiming/${params.id}`)
      setData(res)
    } catch (error) {
      message.error('เรียกข้อมูลไม่สำเร็จ')
    }
    return
  }

  useEffect(() => {
    getData()
  }, [params.id, refresh])

  return (
    <PageLayout
      title={`${data?.insured?.firstName} ${data?.insured?.lastName}`}
      shownBack={true}
      radioGroup={
        data?.status === 'diagnosed' ? (
          <div
            style={{
              border: '1px solid #F3A84D',
              height: 40,
              display: 'flex',
              alignItems: 'center',
              padding: 20,
              borderRadius: 8,
            }}
          >
            <Typography sub1 color="awaiting">
              รออนุมัติ
            </Typography>
          </div>
        ) : (
          <div
            style={{
              border: '1px solid #17A20D',
              height: 40,
              display: 'flex',
              padding: 10,
              alignItems: 'center',
              borderRadius: 8,
            }}
          >
            <img
              src={IconCheck}
              style={{ width: 25, height: 25, marginRight: 10 }}
            />
            <Typography sub1 color="success">
              อนุมัติแล้ว
            </Typography>
          </div>
        )
      }
    >
      <Card style={{ padding: '20px' }}>
        <Typography title2>ข้อมูลส่วนตัว</Typography>
        <br />
        <Row>
          <Col xl={4} lg={5} sm={6}>
            <img
              style={{
                width: '140px',
                height: '140px',
                borderRadius: '50%',
                objectFit: 'cover',
              }}
              src={getImage(data?.insured.image)}
            />
          </Col>
          <Col xl={12} lg={13} sm={16}>
            <Row style={{ marginBottom: 15 }}>
              <Col xl={10} lg={10} sm={12}>
                <Typography sub2 color="black65">
                  หมายเลขบัตรประชาชน
                </Typography>
              </Col>
              <Col xl={12} lg={12} sm={12}>
                <Typography sub2>{data?.insured?.nationalId}</Typography>
              </Col>
            </Row>
            <Row style={{ marginBottom: 15 }}>
              <Col xl={10} lg={10} sm={12}>
                <Typography sub2 color="black65">
                  ชื่อ
                </Typography>
              </Col>
              <Col xl={12} lg={12} sm={12} style={{ display: 'flex' }}>
                <Typography sub2>{data?.insured?.firstName}</Typography>
              </Col>
            </Row>
            <Row style={{ marginBottom: 15 }}>
              <Col xl={10} lg={10} sm={12}>
                <Typography sub2 color="black65">
                  นามสกุล
                </Typography>
              </Col>
              <Col xl={12} lg={12} sm={12}>
                <Typography sub2>{data?.insured?.lastName}</Typography>
              </Col>
            </Row>
            <Row style={{ marginBottom: 15 }}>
              <Col xl={10} lg={10} sm={12}>
                <Typography sub2 color="black65">
                  วันเดือนปีเกิด
                </Typography>
              </Col>
              <Col xl={12} lg={12} sm={12}>
                <Typography sub2>
                  {dayjs(data?.insured?.birthDate).format('DD/MM/YYYY')}
                </Typography>
              </Col>
            </Row>
            <Row style={{ marginBottom: 15 }}>
              <Col xl={10} lg={10} sm={12}>
                <Typography sub2 color="black65">
                  สถานภาพ
                </Typography>
              </Col>
              <Col xl={12} lg={12} sm={12}>
                <Typography sub2>{data?.insured?.maritalStatus}</Typography>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider style={{ borderColor: '#CDCDCD' }} />
        <Typography title2>การเคลม</Typography>
        <br />
        <Row style={{ marginBottom: 15 }}>
          <Col xl={4} lg={5} sm={6}>
            <Typography sub2 color="black65">
              การเคลม
            </Typography>
          </Col>
          <Col xl={12} lg={12} sm={12}>
            <Typography sub2>{data?.diagnosis?.hospital.name}</Typography>
          </Col>
        </Row>
        <Row style={{ marginBottom: 15 }}>
          <Col xl={4} lg={5} sm={6}>
            <Typography sub2 color="black65">
              สถานะ
            </Typography>
          </Col>
          <Col xl={12} lg={12} sm={12}>
            {data?.status === 'diagnosed' ? (
              <Typography sub2 color="awaiting">
                รออนุมัติ
              </Typography>
            ) : data?.status === 'approved' ? (
              <Typography sub2 color="success">
                อนุมัติแล้ว
              </Typography>
            ) : (
              data?.status === 'pending' && (
                <Typography sub2 color="black90">
                  {data?.status}
                </Typography>
              )
            )}
          </Col>
        </Row>
        <Row style={{ marginBottom: 15 }}>
          <Col xl={4} lg={5} sm={6}>
            <Typography sub2 color="black65">
              วันที่เคลม
            </Typography>
          </Col>
          <Col xl={12} lg={12} sm={12}>
            <Typography sub2>
              {dayjs(data?.diagnosis?.date).format('DD/MM/YYYY')}
            </Typography>
          </Col>
        </Row>
        <Row style={{ marginBottom: 15 }}>
          <Col xl={4} lg={5} sm={6}>
            <Typography sub2 color="black65">
              ประกันที่เลือก
            </Typography>
          </Col>
          <Col xl={12} lg={12} sm={12}>
            <Typography
              sub2
            >{`${data?.insuredPlan.insuranceName}, ${data?.insuredPlan.planName}`}</Typography>
          </Col>
        </Row>
        <Row style={{ marginBottom: 15 }}>
          <Col xl={4} lg={5} sm={6}>
            <Typography sub2 color="black65">
              ยอดที่เคลม
            </Typography>
          </Col>
          <Col xl={12} lg={12} sm={12}>
            <Typography
              sub2
            >{`${data?.diagnosis.claimablePrice} บาท`}</Typography>
          </Col>
        </Row>
        <Row style={{ marginBottom: 15 }}>
          <Col xl={4} lg={5} sm={6}>
            <Typography sub2 color="black65">
              ยอดที่เคลมได้
            </Typography>
          </Col>
          <Col xl={12} lg={12} sm={12}>
            <Typography sub2>{`${data?.diagnosis.totalPrice} บาท`}</Typography>
          </Col>
        </Row>
        <Row style={{ marginBottom: 15 }}>
          <Col xl={4} lg={5} sm={6}>
            <Typography sub2 color="black65">
              ผู้อนุมัติ
            </Typography>
          </Col>
          <Col xl={12} lg={12} sm={12}>
            <Typography sub2>
              {data?.approvedBy
                ? `${data?.approvedBy.firstName} ${data?.approvedBy.lastName} (${data?.insuredPlan.companyName})`
                : '-'}
            </Typography>
          </Col>
        </Row>
        <Row style={{ marginBottom: 20 }}>
          <Col xl={4} lg={5} sm={6}>
            <Typography sub2 color="black65">
              วันที่อนุมัติ
            </Typography>
          </Col>
          <Col xl={12} lg={12} sm={12}>
            <Typography sub2>
              {data?.status === 'approved'
                ? dayjs(data?.updatedAt).format('DD/MM/YYYY')
                : '-'}
            </Typography>
          </Col>
        </Row>
        <Row style={{ marginBottom: 15 }}>
          <Col xl={4} lg={5} sm={6}></Col>
          <Col xl={4} lg={4} sm={4}>
            <Button
              title="ดูผลวินิจฉัย"
              type="secondary"
              style={{ width: 130 }}
              onClick={() => setIsModalVisible(true)}
            />
          </Col>
          {data?.status === 'approved' && (
            <Col xl={4} lg={4} sm={4}>
              <Button
                title="ดูผลการเคลม"
                type="secondary"
                style={{ width: 130 }}
                onClick={() => setIsModalClaim(true)}
              />
            </Col>
          )}
        </Row>
      </Card>
      <ModalDiagnosticDetail
        id={params.id}
        setSelectId={setSelectId}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        setRefresh={setRefresh}
        type="claim"
        status={data?.status}
      />
      <ModalClaim
        id={params.id}
        setSelectId={setSelectId}
        isModalVisible={isModalClaim}
        setIsModalVisible={setIsModalClaim}
        refresh={refresh}
        type="claim"
        status={data?.status}
      />
    </PageLayout>
  )
}

export default Detail
