/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import PageLayout from '../../layouts/PageLayout'
import Card from '../../components/Card'
import Button from '../../components/Button'
import Typography from '../../components/Typography'
import { Table, Space, Input, Select, Pagination, Form, message } from 'antd'
import PlusCircle from '../../image/PlusCircle.png'
import ModalAddCase from './ModalAddCase/ModalAddCase'
import ModalDiagnostic from './ModalDiagnostic/ModalDiagnostic'
import ModalDiagnosticDetail from './ModalDiagnosticDetail/ModalDiagnosticDetail'
import { CaseDiagnosis, Hospital } from '../../interface'
import request from '../../utils/request'
import { SearchOutlined } from '@ant-design/icons'
import theme from '../../utils/theme'
import AlertModal from '../../components/AlertModal'

const { Option } = Select

const DiagnosticListPage = (): JSX.Element => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isModalDiagnostic, setIsModalDiagnostic] = useState(false)
  const [isModalDiagnosticDetail, setIsModalDiagnosticDetail] = useState(false)
  const [caseList, setCaseList] = useState<CaseDiagnosis[]>([])
  const [selectId, setSelectId] = useState<number | undefined>(undefined)
  const [selectStatus, setSelectStatus] = useState<
    CaseDiagnosis['status'] | undefined
  >(undefined)
  const [page, setPage] = useState<number>(1)
  const [total, setTotal] = useState<number>()
  const [refresh, setRefresh] = useState(false)
  const [form] = Form.useForm()
  const [hospitalList, setHospitalList] = useState<Hospital[]>([])
  const [openConfirmModal, setOpenConfirmModal] = useState(false)

  const getCaseList = async () => {
    try {
      const res: any = await request.get('/diagnosis', {
        params: {
          page: page,
          limit: 6,
        },
      })
      setCaseList(res.data)
      setTotal(res.total)
    } catch (error) {
      console.log(error)
    }
  }

  const getHospitalList = async () => {
    try {
      const res: Hospital[] = await request.get('/hospital/all')
      setHospitalList(res)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getHospitalList()
  }, [])

  useEffect(() => {
    getCaseList()
  }, [page, refresh])

  const onDelete = async (id: number | undefined) => {
    try {
      await request.delete(`/diagnosis/${id}`)
      message.success('ลบสำเร็จ')
      setOpenConfirmModal(false)
      setRefresh?.((prev) => !prev)
    } catch (err) {
      message.error('ดำเนินการผิดพลาด')
      console.log(err)
    }
  }

  const onChange = async (e: any) => {
    try {
      const res: any = await request.get('/diagnosis', {
        params: {
          page: page,
          limit: 6,
          keyword: e.target.value ? e.target.value : null,
          hospitalId: form.getFieldValue('hospitalId')
            ? form.getFieldValue('hospitalId')
            : null,
        },
      })
      setCaseList(res.data)
      setTotal(res.total)
    } catch (error) {
      console.log(error)
    }
  }

  const onSelectChange = async (value: any) => {
    try {
      const res: any = await request.get('/diagnosis', {
        params: {
          page: page,
          limit: 6,
          keyword: form.getFieldValue('search')
            ? form.getFieldValue('search')
            : null,
          hospitalId: value,
        },
      })
      setCaseList(res.data)
      setTotal(res.total)
    } catch (error) {
      console.log(error)
    }
  }

  const columns = [
    {
      title: <Typography sub1>รหัส</Typography>,
      dataIndex: 'id',
      key: 'id',
      render: (text: string) => (
        <Typography sub2>{String(text).padStart(3, '0')}</Typography>
      ),
    },
    {
      title: <Typography sub1>ชื่อ - นามสกุล</Typography>,
      dataIndex: ['firstName', 'lastName'],
      key: 'name',
      render: (_: any, record: CaseDiagnosis) => (
        <a
          onClick={() => {
            setSelectId(record.id)
            setSelectStatus(record.status)
            setIsModalDiagnosticDetail(true)
          }}
        >
          <Typography sub2 color="primary1">
            {record.insured.firstName} {record.insured.lastName}
          </Typography>
        </a>
      ),
    },
    {
      title: <Typography sub1>ผลวินิจฉัย</Typography>,
      dataIndex: 'status',
      key: 'status',
      render: (text: string, record: CaseDiagnosis) => (
        <Button
          style={{ width: 145 }}
          title={
            text === 'pending'
              ? 'บันทึกผลวินิจฉัย'
              : text === 'diagnosed'
              ? 'แก้ไขผลวินิจฉัย'
              : ''
          }
          type="secondary"
          onClick={() => {
            setSelectId(record.id)
            setIsModalDiagnostic(true)
          }}
        />
      ),
    },
    {
      title: <Typography sub1>สถานะ</Typography>,
      dataIndex: 'status',
      key: 'status',
      render: (text: string) => (
        <Typography
          sub2
          color={
            text === 'pending'
              ? `awaiting`
              : text === 'diagnosed'
              ? `success`
              : 'black90'
          }
        >
          {text === 'pending' ? 'รอผลตรวจ' : 'ตรวจแล้ว'}
        </Typography>
      ),
    },
    {
      title: '',
      key: 'action',
      render: (_: any, record: CaseDiagnosis) => (
        <Space size="middle">
          <a
            onClick={() => {
              setSelectId(record.id)
              setIsModalVisible(true)
            }}
          >
            <Typography sub1 color="primary2">
              แก้ไข
            </Typography>
          </a>
          <a
            onClick={() => {
              setSelectId(record.id)
              setOpenConfirmModal(true)
            }}
          >
            <Typography sub1 color="primary2">
              ลบ
            </Typography>
          </a>
        </Space>
      ),
    },
  ]

  return (
    <PageLayout
      title="เคสการวินิจฉัย"
      shownBack={false}
      radioGroup={
        <div className="row">
          <Button
            iconLeft={
              <img style={{ width: 23, height: 23 }} src={PlusCircle} />
            }
            title="เพิ่มเคสการวินิจฉัย"
            type="primary"
            onClick={() => {
              setSelectId(undefined)
              setIsModalVisible(true)
            }}
          />
        </div>
      }
    >
      <Card style={{ padding: '0px 16px' }}>
        <br />
        <Form form={form} name="ClaimList" layout="vertical">
          <div style={{ display: 'flex' }}>
            <Form.Item name="search" style={{ marginBottom: 0, width: '100%' }}>
              <Input
                className="ant-input-class"
                placeholder="ค้าหา ชื่อ, นามสกุล"
                allowClear
                onChange={onChange}
                addonAfter={<SearchOutlined style={{ color: theme.black40 }} />}
              />
            </Form.Item>
            <Form.Item name="hospitalId">
              <Select
                allowClear={true}
                placeholder="เลือกโรงพยาบาล"
                style={{ width: 250, marginLeft: 20 }}
                onChange={onSelectChange}
              >
                {hospitalList.map((x) => {
                  return (
                    <Option key={x.id} value={x.id}>
                      {x.name}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
          </div>
        </Form>

        <br />
        <Table columns={columns} dataSource={caseList} pagination={false} />
        <Pagination
          current={page}
          pageSize={6}
          total={total}
          style={{ padding: '20px 0px', textAlign: 'end' }}
          onChange={(page: number) => setPage(page)}
          showSizeChanger={false}
        />
      </Card>
      <ModalAddCase
        id={selectId}
        setSelectId={setSelectId}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        setRefresh={setRefresh}
      />
      <ModalDiagnostic
        id={selectId}
        setSelectId={setSelectId}
        isModalVisible={isModalDiagnostic}
        setIsModalVisible={setIsModalDiagnostic}
        setRefresh={setRefresh}
      />
      <ModalDiagnosticDetail
        id={selectId}
        setSelectId={setSelectId}
        isModalVisible={isModalDiagnosticDetail}
        setIsModalVisible={setIsModalDiagnosticDetail}
        setRefresh={setRefresh}
        type="diagnosis"
        status={selectStatus}
      />
      <AlertModal
        type="confirm"
        title="คุณยืนยันที่จะลบเคสการวินิจฉัยนี้ใช่หรือไม่"
        isModalVisible={openConfirmModal}
        onCancel={() => setOpenConfirmModal(false)}
        onSubmit={() => {
          onDelete(selectId)
        }}
      />
    </PageLayout>
  )
}

export default DiagnosticListPage
