/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction, useState } from 'react'
import { Form, Row, Modal as AntModal, Input, Select, message } from 'antd'
import Button from '../../../components/Button'
import Typography from '../../../components/Typography'
import { useHistory, useRouteMatch } from 'react-router-dom'
import request from '../../../utils/request'
import DatePicker from '../../../components/DatePicker'
import AlertModal from '../../../components/AlertModal'

interface Props {
  id?: string
  isModalVisible: boolean
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
}

type FormValues = {
  firstName: string
  lastName: string
  hn: string
  title: 'mr' | 'ms' | 'mrs' | 'boy' | 'girl'
  birthDate: string
  maritalStatus: 'single' | 'married' | 'widow' | 'divorced' | 'separated'
  nationalId: string
  passportId: string
}

const { Option } = Select

const Modal = ({
  id,
  isModalVisible,
  setIsModalVisible,
}: Props): JSX.Element => {
  const { path } = useRouteMatch()
  const history = useHistory()
  const [form] = Form.useForm()
  const [openConfirmModal, setOpenConfirmModal] = useState(false)

  const onClose = () => {
    setIsModalVisible(false)
    if (!id) {
      form.resetFields()
    }
  }

  const onFinish = async (values: FormValues) => {
    const {
      firstName,
      lastName,
      hn,
      title,
      birthDate,
      maritalStatus,
      nationalId,
      passportId,
    } = values
    try {
      const res: any = await request.post(`/insured`, {
        firstName,
        lastName,
        hn,
        title,
        birthDate,
        maritalStatus,
        nationalId: nationalId ? nationalId : null,
        passportId: passportId ? passportId : null,
      })
      message.success('เพิ่มรายการสำเร็จ')
      history.push(`${path}/add/${res.id}`)
    } catch (e: any) {
      message.error('ดำเนินการผิดพลาด')
      if (e.response.status === 400) {
        setOpenConfirmModal(true)
      }
    }
  }

  return (
    <>
      <AntModal
        centered
        visible={isModalVisible}
        style={{ borderRadius: 8 }}
        bodyStyle={{ overflowX: 'scroll', maxHeight: 500 }}
        width={700}
        title={
          <Typography title1 color="black90">
            เพิ่มรายชื่อผู้ใช้
          </Typography>
        }
        footer={null}
        onCancel={onClose}
      >
        <Form
          form={form}
          name="UserModal"
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            label={
              <Typography sub4 color="black65">
                หมายเลขบัตรประชาชน
              </Typography>
            }
            name="nationalId"
          >
            <Input className="ant-input-class" maxLength={13} />
          </Form.Item>
          <Form.Item
            label={
              <Typography sub4 color="black65">
                พาสปอร์ต
              </Typography>
            }
            name="passportId"
          >
            <Input className="ant-input-class" />
          </Form.Item>
          <Form.Item
            label={
              <Typography sub4 color="black65">
                hn
              </Typography>
            }
            name="hn"
            rules={[{ required: true, message: 'กรุณากรอก hn' }]}
          >
            <Input className="ant-input-class" />
          </Form.Item>
          <Form.Item
            label={
              <Typography sub4 color="black65">
                คำนำหน้าชื่อ
              </Typography>
            }
            name="title"
            rules={[{ required: true, message: 'กรุณาเลือกคำนำหน้าชื่อ' }]}
          >
            <Select
              placeholder="เลือกคำนำหน้าชื่อ"
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              <Option value="mr">นาย</Option>
              <Option value="ms">นางสาว</Option>
              <Option value="mrs">นาง</Option>
              <Option value="boy">เด็กชาย</Option>
              <Option value="girl">เด็กหญิง </Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={
              <Typography sub4 color="black65">
                ชื่อจริง
              </Typography>
            }
            name="firstName"
            rules={[{ required: true, message: 'กรุณากรอกชื่อจริง' }]}
          >
            <Input className="ant-input-class" />
          </Form.Item>
          <Form.Item
            label={
              <Typography sub4 color="black65">
                นามสกุล
              </Typography>
            }
            name="lastName"
            rules={[{ required: true, message: 'กรุณากรอกนามสกุล' }]}
          >
            <Input className="ant-input-class" />
          </Form.Item>
          <Form.Item
            label={
              <Typography sub4 color="black65">
                วันเดือนปีเกิด
              </Typography>
            }
            name="birthDate"
            rules={[{ required: true, message: 'กรุณากรอกวันเดือนปีเกิด' }]}
          >
            <DatePicker placeholder="เลือกวันที่" />
          </Form.Item>
          <Form.Item
            id="test"
            label={
              <Typography sub4 color="black65">
                สถานภาพสมรส
              </Typography>
            }
            name="maritalStatus"
            rules={[{ required: true, message: 'กรุณาเลือกสถานภาพสมรส' }]}
          >
            <Select
              placeholder="เลือกสถานภาพสมรส"
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              <Option value="single">โสด</Option>
              <Option value="married">สมรส</Option>
              <Option value="widow">หม้าย</Option>
              <Option value="divorced">หย่า</Option>
              <Option value="separated">แยกกันอยู่</Option>
            </Select>
          </Form.Item>
          <Row justify="center" align="middle">
            <Button htmlType="submit" title="ต่อไป" style={{ width: '50%' }} />
          </Row>
        </Form>
      </AntModal>
      <AlertModal
        type="confirm"
        title="มีหมายเลขบัตรประชาชน หรือ พาสปอร์ตอยู่แล้ว"
        isModalVisible={openConfirmModal}
        onSubmit={() => {
          setOpenConfirmModal(false)
        }}
      />
    </>
  )
}

export default Modal
