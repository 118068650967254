/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction, useState, useEffect } from 'react'
import { Form, Row, Modal as AntModal, Input, message } from 'antd'
import Button from '../../components/Button'
import Typography from '../../components/Typography'
import request from '../../utils/request'
import { Hospital } from '../../interface'
import AlertModal from '../../components/AlertModal'

interface Props {
  id?: string | number
  isModalVisible: boolean
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
  setRefresh?: Dispatch<SetStateAction<boolean>>
}

type FormValues = {
  abbreviation: string
  name: string
  phoneNumber: string
}

const Modal = ({
  id,
  isModalVisible,
  setIsModalVisible,
  setRefresh,
}: Props): JSX.Element => {
  const [form] = Form.useForm()
  const [data, setData] = useState<Hospital>()
  const [openConfirmModal, setOpenConfirmModal] = useState(false)

  const getData = async () => {
    if (id) {
      try {
        const res: Hospital = await request.get(`/hospital/${id}`)
        setData(res)
      } catch (error) {
        console.log(error)
        message.error('เรียกข้อมูลไม่สำเร็จ')
        setData(undefined)
      }
      return
    }
    setData(undefined)
  }

  useEffect(() => {
    getData()
  }, [id])

  useEffect(() => {
    form.setFieldsValue({
      name: data?.name,
      abbreviation: data?.abbreviation,
      phoneNumber: data?.phoneNumber,
    })
  }, [data])

  const onClose = () => {
    setIsModalVisible(false)
    if (!id) {
      form.resetFields()
    }
  }

  const onFinish = async (values: FormValues) => {
    const { name, abbreviation, phoneNumber } = values
    try {
      if (id) {
        await request.put(`/hospital/${id}`, {
          name,
          abbreviation,
          phoneNumber,
        })
        message.success('แก้ไขรายการสำเร็จ')
      } else {
        await request.post('/hospital', {
          name,
          abbreviation,
          phoneNumber,
        })
        message.success('เพิ่มรายการสำเร็จ')
      }
      onClose()
      setRefresh?.((prev) => !prev)
    } catch (e: any) {
      message.error('ดำเนินการผิดพลาด')
      if (e.response.status === 400) {
        setOpenConfirmModal(true)
      }
    }
  }

  return (
    <>
      <AntModal
        centered
        visible={isModalVisible}
        style={{ borderRadius: 8 }}
        bodyStyle={{ overflowX: 'scroll', maxHeight: 500 }}
        width={700}
        title={
          <Typography title1 color="black90">
            {id ? 'แก้ไขรายชื่อโรงพยาบาล' : 'เพิ่มรายชื่อโรงพยาบาล'}
          </Typography>
        }
        footer={null}
        onCancel={onClose}
      >
        <Form
          form={form}
          name="HospitalModal"
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            label={
              <Typography sub4 color="black65">
                อักษรย่อ
              </Typography>
            }
            name="abbreviation"
            rules={[
              { required: true, message: 'กรุณากรอกอักษรย่อ' },
              { min: 3, message: 'กรุณากรอกอย่างน้อย 3 ตัวอักษร' },
            ]}
          >
            <Input className="ant-input-class" />
          </Form.Item>
          <Form.Item
            label={
              <Typography sub4 color="black65">
                ชื่อเต็มโรงพยาบาล
              </Typography>
            }
            name="name"
            rules={[{ required: true, message: 'กรุณากรอกชื่อเต็มโรงพยาบาล' }]}
          >
            <Input className="ant-input-class" />
          </Form.Item>
          <Form.Item
            label={
              <Typography sub4 color="black65">
                เบอร์ติดต่อ
              </Typography>
            }
            name="phoneNumber"
            rules={[
              { required: true, message: 'กรุณากรอกเบอร์ติดต่อ' },
              { min: 9, max: 10, message: 'กรุณากรอกเบอร์ที่ถูกต้อง' },
            ]}
          >
            <Input className="ant-input-class" maxLength={10} />
          </Form.Item>
          <Row justify="center" align="middle">
            <Button htmlType="submit" title="บันทึก" style={{ width: '50%' }} />
          </Row>
        </Form>
      </AntModal>
      <AlertModal
        type="confirm"
        title="มีรายการนี้อยู่แล้ว"
        isModalVisible={openConfirmModal}
        onSubmit={() => {
          setOpenConfirmModal(false)
        }}
      />
    </>
  )
}

export default Modal
