import React, { useContext } from 'react'

interface ProviderInterface {
  children: JSX.Element
}

interface ContextInterface {
  isOpenMenu: boolean
  toggleMenu: () => void
}

const MenuContext = React.createContext<ContextInterface | null>(null)

export const MenuProvider = ({ children }: ProviderInterface): JSX.Element => {
  const [isOpenMenu, setisOpenMenu] = React.useState(false)
  const toggleMenu = () => {
    setisOpenMenu(!isOpenMenu)
  }

  return (
    <MenuContext.Provider value={{ isOpenMenu, toggleMenu }}>
      {children}
    </MenuContext.Provider>
  )
}

export const useMenu = (): ContextInterface => {
  const context = useContext(MenuContext)
  if (!context) {
    throw new Error('useSearch can be use in AuthContext only')
  }
  return context
}
