import { useState, useEffect } from 'react'

export const getTitle = (
  title: 'mr' | 'ms' | 'mrs' | 'boy' | 'girl' | undefined
): string => {
  if (title === 'mr') {
    return 'นาย'
  } else if (title === 'ms') {
    return 'นางสาว'
  } else if (title === 'mrs') {
    return 'นาง'
  } else if (title === 'boy') {
    return 'เด็กชาย'
  } else {
    return 'เด็กหญิง'
  }
}

export const scrollToTop = (): void => {
  document.getElementsByClassName('ant-modal-body')[0].scrollTo(0, 0)
}
const baseURL = process.env.REACT_APP_API_URL
const coverImage =
  'https://www.solidbackgrounds.com/images/2560x1600/2560x1600-dark-gray-solid-color-background.jpg'

export const getImage = (
  imageId: number | undefined,
  defaultImage = coverImage
): string => {
  const token = localStorage.getItem('accessToken')
  if (imageId) {
    return `${baseURL}/container/${imageId}/?access_token=${token}`
  }
  return defaultImage
}

export default function useWindowDimensions(): {
  width: number | null
  height: number | null
} {
  const hasWindow = typeof window !== 'undefined'

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null
    const height = hasWindow ? window.innerHeight : null
    return {
      width,
      height,
    }
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    if (hasWindow) {
      const handleResize = () => {
        setWindowDimensions(getWindowDimensions())
      }

      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [hasWindow])

  return windowDimensions
}
