import React, { useEffect } from 'react'
import { Form, Input, message } from 'antd'
import { Redirect, useHistory } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import Button from '../../components/Button'
import LogoEcliaim from '../../image/LogoEcliaim.png'
import Typography from '../../components/Typography'
import { PATH_KEY } from '../../utils/request'

type FormValues = {
  email: string
  password: string
}

const LoginPage = (): JSX.Element => {
  const history = useHistory()
  const {
    state: { user, userToken },
    authContext: { login, getMe },
  } = useAuth()

  const onFinish = async (values: FormValues) => {
    try {
      await login(values)
      sessionStorage.removeItem(PATH_KEY)
      message.success('Login Success')
    } catch (e) {
      message.error('Login Failed')
    }
  }

  useEffect(() => {
    getMe()
  }, [userToken])

  const isPermit = (permit: string) => {
    return user.permissions?.includes(permit)
  }

  if (user.id) {
    if (isPermit('insurance')) {
      return <Redirect to="/company-list" />
    }
    if (isPermit('insured')) {
      return <Redirect to="/user-list" />
    }
    if (isPermit('diagnosis')) {
      return <Redirect to="/diagnostic-list" />
    }
    if (isPermit('claiming')) {
      return <Redirect to="/bi-claim" />
    }
    if (isPermit('accessability')) {
      return <Redirect to="/accessibility" />
    }
    return <Redirect to="/not-found" />
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img style={{ height: 90 }} src={LogoEcliaim} />
      <Typography
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          lineHeight: '60px',
          margin: '20px 0px',
        }}
        h2
      >
        เข้าสู่ระบบ
      </Typography>
      <Form
        name="login"
        layout="vertical"
        initialValues={{}}
        onFinish={onFinish}
      >
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'กรุณากรอกอีเมล์' }]}
        >
          <Input className="ant-input-class" placeholder="example@gmail.com" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'กรุณากรอกรหัสผ่าน' }]}
        >
          <Input.Password className="ant-input-class" placeholder="********" />
        </Form.Item>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Form.Item>
            <Button title="เข้าสู่ระบบ" style={{ width: '350px' }} />
          </Form.Item>
        </div>
      </Form>
      <Form.Item>
        <Button
          title="ลืมรหัสผ่าน"
          type="text"
          style={{ width: '350px' }}
          onClick={() => history.push(`forget-password`)}
        />
      </Form.Item>
    </div>
  )
}

export default LoginPage
