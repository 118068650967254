/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction, useState } from 'react'
import { Form, Row, Input, FormInstance } from 'antd'
import Button from '../../../components/Button'
import Typography from '../../../components/Typography'
import UploadImage from '../../../components/UploadImage'
import { CaseDiagnosis, Insured } from '../../../interface'
import request from '../../../utils/request'
import AlertModal from '../../../components/AlertModal'
import { scrollToTop } from '../../../utils/getName'

interface Props {
  id?: string | number
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
  setStep?: Dispatch<SetStateAction<number>> | undefined
  form: FormInstance<any>
  data: CaseDiagnosis | undefined
  setUser: React.Dispatch<React.SetStateAction<Insured | undefined>>
  user: Insured | undefined
  setIdCardImage: React.Dispatch<React.SetStateAction<number | undefined>>
  idCardImage: number | undefined
  setInsuranceCardImage: React.Dispatch<
    React.SetStateAction<number | undefined>
  >
  insuranceCardImage: number | undefined
}

const Step1 = ({
  id,
  setStep,
  form,
  setUser,
  setIdCardImage,
  idCardImage,
  setInsuranceCardImage,
  insuranceCardImage,
}: Props): JSX.Element => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false)

  const getUser = async () => {
    const key = form.getFieldValue('nationalId')
    const en = form.getFieldValue('en')
    try {
      const res: Insured = await request.get(`/insured/identity/${key}`)
      setUser(res)
      form.setFieldsValue({ insuredId: res.id, en })
      setStep?.(2)
      scrollToTop()
    } catch (error) {
      setOpenConfirmModal(true)
      // message.error('เรียกข้อมูลไม่สำเร็จ')
    }
  }

  return (
    <>
      <Form.Item
        label={
          <Typography sub4 color="black65">
            หมายเลขบัตรประชาชน /พาสปอร์ต
          </Typography>
        }
        name="nationalId"
        rules={[{ required: true, message: 'กรุณากรอกหมายเลขบัตรประชาชน' }]}
      >
        <Input className="ant-input-class" maxLength={13} />
      </Form.Item>
      <UploadImage
        title="รูปบัตรประชาชน"
        formName="idCardImage"
        setFileList={setIdCardImage}
        fileList={idCardImage}
        type="uploadFile"
      />
      <UploadImage
        title="รูปบัตรกรมธรรม์"
        formName="insuranceCardImage"
        setFileList={setInsuranceCardImage}
        fileList={insuranceCardImage}
        type="uploadFile"
      />
      {!id && (
        <Form.Item
          label={
            <Typography sub4 color="black65">
              หมายเลข EN
            </Typography>
          }
          name="en"
        >
          <Input className="ant-input-class" placeholder="eg. Oxx-xx-xxxxxx" />
        </Form.Item>
      )}
      <Row
        justify="space-between"
        align="middle"
        style={{ justifyContent: 'center' }}
      >
        <Button
          htmlType="button"
          title="ต่อไป"
          style={{ width: '48%' }}
          onClick={() => {
            if (
              form.getFieldValue('nationalId') &&
              idCardImage &&
              insuranceCardImage
            ) {
              getUser()
            }
          }}
        />
      </Row>
      <AlertModal
        type="confirm"
        title="ไม่พบเลขบัตรที่ตรงกัน"
        isModalVisible={openConfirmModal}
        onCancel={() => setOpenConfirmModal(false)}
        onSubmit={() => setOpenConfirmModal(false)}
      />
    </>
  )
}

export default Step1
