/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  // useMemo,
  useState,
} from 'react'
import {
  Form,
  Row,
  Modal as AntModal,
  Input,
  message,
  Col,
  Checkbox,
} from 'antd'
// import { CopyToClipboard } from 'react-copy-to-clipboard'
import Button from '../../components/Button'
import Typography from '../../components/Typography'
import IconAdd from '../../image/IconAdd.png'
import request from '../../utils/request'
import { Medicine } from '../../interface'
import AlertModal from '../../components/AlertModal'
import _ from 'lodash'
import { CreateGroupForm } from './CreateGroupForm'
interface Props {
  id?: string | number
  isModalVisible: boolean
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
  setRefresh?: Dispatch<SetStateAction<boolean>>
}

type FormValues = {
  commonName: string
  tradeNames: string[]
  price: string
  unit: string
  isClaimable: string
  symptomList: string[]
}

const Modal = ({
  id,
  isModalVisible,
  setIsModalVisible,
  setRefresh,
}: Props): JSX.Element => {
  const [form] = Form.useForm()
  const [tradeNameIndex, setTradeNameIndex] = useState(1)
  const [data, setData] = useState<Medicine>()
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [copy, setCopy] = useState<any[]>([])
  const [submitting, setSubmitting] = useState(false)

  const onCopy = async () => {
    try {
      const symptomList = form.getFieldValue([`symptomList`])
      // const symptoms = form.getFieldValue('symptoms')
      setCopy(symptomList)
      message.success('คัดลอกแล้ว')
    } catch (error) {
      message.error('คัดลอกไม่สำเร็จ')
    }
  }

  // const symptomOptions = useMemo(() => {
  //   return uniqBy([...symptomOption, ...selectedSymptomObj], 'id')
  // }, [symptomOption, selectedSymptomObj])

  const getData = async () => {
    if (id) {
      try {
        const res: Medicine = await request.get(`/medicine/${id}`)
        setData(res)
      } catch (error) {
        message.error('เรียกข้อมูลไม่สำเร็จ')
        setData(undefined)
      }
      return
    }
    setData(undefined)
  }

  useEffect(() => {
    if (isModalVisible) {
      getData()
    }
  }, [id, isModalVisible])

  useEffect(() => {
    if (data) {
      setTradeNameIndex(data?.tradeNames.length)
    } else {
      setTradeNameIndex(1)
    }

    const symptoms = (data?.symptoms || [])?.map((item) => ({
      ...item,
      mainGroup: item.icd10Code[0],
    }))
    const symptomGroups = _.groupBy(symptoms, 'mainGroup')
    const result = Object.keys(symptomGroups).map((key: string) => {
      const code = symptomGroups[key].map((item) => item.id)
      return { mainGroup: key, icd10Code: code }
    })

    const isClaimable = []
    if (data?.isClaimableAccidentOnly) {
      isClaimable.push('isClaimableAccidentOnly')
    }
    if (data?.isClaimableNoAccidentOnly) {
      isClaimable.push('isClaimableNoAccidentOnly')
    }

    form.setFieldsValue({
      commonName: data?.commonName,
      tradeNames: data?.tradeNames,
      price: data?.price,
      unit: data?.unit,
      symptomList: result,
      isClaimable,
    })

    // setSelectedICD(symptoms)
    // setSelectedIcdObj(data?.symptoms || [])
  }, [data])

  const onClose = () => {
    setIsModalVisible(false)
    form.resetFields()
    setTradeNameIndex(1)
  }

  const onFinish = async (values: FormValues) => {
    const { commonName, tradeNames, price, unit, isClaimable, symptomList } =
      values
    const icd10Code = symptomList
      .map((item: any) => {
        return item.icd10Code
      })
      .flat()
      .filter((icd10) => icd10 !== 'all')
    const isClaimableAccidentOnly = isClaimable?.includes(
      'isClaimableAccidentOnly'
    )
    const isClaimableNoAccidentOnly = isClaimable?.includes(
      'isClaimableNoAccidentOnly'
    )
    try {
      setSubmitting(true)
      if (id) {
        await request.put(`/medicine/${id}`, {
          commonName,
          tradeNames:
            tradeNames?.filter((item) => item).length > 0 ? tradeNames : [],
          price: parseInt(price),
          unit,
          isClaimableAccidentOnly,
          isClaimableNoAccidentOnly,
          symptoms: _.uniq(icd10Code),
        })
        message.success('แก้ไขรายการสำเร็จ')
      } else {
        await request.post(`/medicine`, {
          commonName,
          tradeNames:
            tradeNames?.filter((item) => item).length > 0 ? tradeNames : [],
          price: parseInt(price),
          unit,
          isClaimableAccidentOnly,
          isClaimableNoAccidentOnly,
          symptoms: _.uniq(icd10Code),
        })
        message.success('เพิ่มรายการสำเร็จ')
      }
      onClose()
      setRefresh?.((prev) => !prev)
    } catch (e: any) {
      message.error('ดำเนินการผิดพลาด')
      if (e?.response?.status === 400) {
        setOpenConfirmModal(true)
      }
    } finally {
      setSubmitting(false)
    }
  }

  const createElements = (n: number) => {
    const elements = []
    for (let i = 0; i < n; i++) {
      elements.push(
        i === 0 ? (
          <Form.Item name={['tradeNames', i]} className="tradeName">
            <Input className="ant-input-class" />
          </Form.Item>
        ) : (
          <Form.Item className="tradeName">
            <Row gutter={10} align="middle">
              <Col span={22}>
                <Form.Item name={['tradeNames', i]} noStyle>
                  <Input className="ant-input-class" />
                </Form.Item>
              </Col>
              <Col span={2}>
                <a onClick={() => setTradeNameIndex((prev) => prev - 1)}>
                  <Typography color="primary1">ลบ</Typography>
                </a>
              </Col>
            </Row>
          </Form.Item>
        )
      )
    }
    return elements
  }

  return (
    <>
      <AntModal
        centered
        visible={isModalVisible}
        style={{ borderRadius: 8 }}
        bodyStyle={{ overflowX: 'scroll', maxHeight: 500 }}
        width={700}
        title={
          <Typography title1 color="black90">
            {id ? 'แก้ไขรายชื่อยา' : 'เพิ่มรายชื่อยา'}
          </Typography>
        }
        footer={null}
        onCancel={onClose}
      >
        <Form
          form={form}
          name="MedicineModal"
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            label={
              <Typography sub4 color="black65">
                ชื่อสามัญ
              </Typography>
            }
            name="commonName"
            rules={[{ required: true, message: 'กรุณากรอกชื่อสามัญ' }]}
          >
            <Input className="ant-input-class" />
          </Form.Item>
          <Form.Item
            label={
              <Typography sub4 color="black65">
                ราคา (บาท)
              </Typography>
            }
            name="price"
            rules={[{ required: true, message: 'กรุณากรอกราคา' }]}
          >
            <Input
              className="ant-input-class"
              type="number"
              min={0}
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item
            label={
              <Typography sub4 color="black65">
                หน่วย
              </Typography>
            }
            name="unit"
            rules={[{ required: true, message: 'กรุณากรอกหน่วย' }]}
          >
            <Input className="ant-input-class" />
          </Form.Item>
          <Form.Item
            label={
              <Typography sub4 color="black65">
                ชื่อทางการค้า
              </Typography>
            }
          >
            {createElements(tradeNameIndex)}
            <Button
              type="text"
              htmlType="button"
              title="เพิ่มชื่อทางการค้า"
              iconLeft={<img style={{ width: 23, height: 23 }} src={IconAdd} />}
              style={{ padding: 0 }}
              onClick={() => setTradeNameIndex((prev) => prev + 1)}
            />
          </Form.Item>
          <CreateGroupForm form={form} onCopy={onCopy} copy={copy} />
          <Form.Item
            name="isClaimable"
            label={
              <Typography sub4 color="black90">
                เคสที่เบิกได้
              </Typography>
            }
          >
            <Checkbox.Group>
              <Col>
                <Checkbox value="isClaimableAccidentOnly">
                  <Typography sub4 color="black65">
                    เบิกได้เฉพาะอุบัติเหตุ
                  </Typography>
                </Checkbox>
              </Col>
              <Col>
                <Checkbox value="isClaimableNoAccidentOnly">
                  <Typography sub4 color="black65">
                    เบิกได้เฉพาะไม่อุบัติเหตุ
                  </Typography>
                </Checkbox>
              </Col>
            </Checkbox.Group>
          </Form.Item>
          <Row justify="center" align="middle">
            <Button
              htmlType="submit"
              title="บันทึก"
              style={{ width: '50%' }}
              disabled={submitting}
            />
          </Row>
        </Form>
      </AntModal>
      <AlertModal
        type="confirm"
        title="มีรายการนี้อยู่แล้ว"
        isModalVisible={openConfirmModal}
        onSubmit={() => {
          setOpenConfirmModal(false)
        }}
      />
    </>
  )
}

export default Modal
