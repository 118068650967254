/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react'
import PageLayout from '../../../layouts/PageLayout'
import Card from '../../../components/Card'
import Typography from '../../../components/Typography'
import dayjs from 'dayjs'
import {
  Table,
  Space,
  Input,
  Form,
  Select,
  message,
  Pagination,
  DatePicker,
} from 'antd'
import { useHistory, useRouteMatch } from 'react-router'
import { ClaimList } from '../../../interface'
import request from '../../../utils/request'
import { Hospital } from '../../../interface'
import { SearchOutlined } from '@ant-design/icons'
import theme from '../../../utils/theme'
import AlertModal from '../../../components/AlertModal'

const { Option } = Select

const List = (): JSX.Element => {
  const [form] = Form.useForm()
  const { path } = useRouteMatch()
  const history = useHistory()
  const [claimList, setClaimList] = useState<ClaimList[]>([])
  const [page, setPage] = useState<number>(1)
  const [total, setTotal] = useState<number>()
  const [refresh, setRefresh] = useState(false)
  const [hospitalList, setHospitalList] = useState<Hospital[]>([])
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [selectId, setSelectId] = useState<number | undefined>(undefined)

  const getClaimList = async () => {
    try {
      const res: any = await request.get('/claiming', {
        params: {
          page: page,
          limit: 6,
          keyword: form.getFieldValue('search'),
          hospitalId: form.getFieldValue('hospitalId')
            ? form.getFieldValue('hospitalId')
            : null,
          date: form.getFieldValue('date')
            ? form.getFieldValue('date').toISOString()
            : null,
        },
      })
      setClaimList(res.data)
      setTotal(res.total)
    } catch (error) {
      console.log(error)
    }
  }

  const getHospitalList = async () => {
    try {
      const res: Hospital[] = await request.get('/hospital/all')
      setHospitalList(res)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getHospitalList()
  }, [])

  useEffect(() => {
    form.setFieldsValue({ date: dayjs() })
  }, [])

  useEffect(() => {
    getClaimList()
  }, [page, refresh])

  const onDelete = async (id: number | undefined) => {
    try {
      await request.delete(`/claiming/${id}/diagnosis`)
      message.success('ลบสำเร็จ')
      setOpenConfirmModal(false)
      setRefresh?.((prev) => !prev)
    } catch (err) {
      message.error('ดำเนินการผิดพลาด')
      console.log(err)
    }
  }

  const onChange = async (e: any) => {
    try {
      const res: any = await request.get('/claiming', {
        params: {
          page: page,
          limit: 6,
          keyword: e.target.value ? e.target.value : null,
          hospitalId: form.getFieldValue('hospitalId')
            ? form.getFieldValue('hospitalId')
            : null,
          date: form.getFieldValue('date')
            ? form.getFieldValue('date').toISOString()
            : null,
        },
      })
      setClaimList(res.data)
      setTotal(res.total)
    } catch (error) {
      console.log(error)
    }
  }

  const onSelectChange = async (value: any) => {
    try {
      const res: any = await request.get('/claiming', {
        params: {
          page: page,
          limit: 6,
          keyword: form.getFieldValue('search')
            ? form.getFieldValue('search')
            : null,
          hospitalId: value,
          date: form.getFieldValue('date')
            ? form.getFieldValue('date').toISOString()
            : null,
        },
      })
      setClaimList(res.data)
      setTotal(res.total)
    } catch (error) {
      console.log(error)
    }
  }

  const onChangeDate = async (date: any) => {
    try {
      const params = {
        page: page,
        limit: 6,
        keyword: form.getFieldValue('search')
          ? form.getFieldValue('search')
          : null,
        hospitalId: form.getFieldValue('hospitalId') || null,
        date: date ? date.toISOString() : null,
      }
      const res: any = await request.get('/claiming', { params })
      setClaimList(res.data)
      setTotal(res.total)
    } catch (error) {
      console.log(error)
    }
  }

  const columns = [
    {
      title: <Typography sub1>หมายเลขเคส</Typography>,
      dataIndex: 'id',
      key: 'id',
      render: (text: string) => (
        <Typography sub2>{String(text).padStart(3, '0')}</Typography>
      ),
    },
    {
      title: <Typography sub1>ชื่อ - นามสกุล</Typography>,
      dataIndex: 'firstName',
      key: 'firstName',
      render: (_: any, record: ClaimList) => (
        <a onClick={() => history.push(`${path}/add/${record.id}`)}>
          <Typography sub2 color="primary1">
            {record.insured.firstName} {record.insured.lastName}
          </Typography>
        </a>
      ),
    },
    {
      title: <Typography sub1>โรงพยาบาล</Typography>,
      dataIndex: 'hospitalId',
      key: 'hospitalId',
      render: (_: string, record: ClaimList) => (
        <Typography sub2 color="black90">
          {
            hospitalList.filter(
              (item) => item.id === record?.diagnosis?.hospitalId
            )?.[0]?.name
          }
        </Typography>
      ),
    },
    {
      title: <Typography sub1>สถานะ</Typography>,
      key: 'status',
      dataIndex: 'status',
      render: (text: string) =>
        text === 'diagnosed' ? (
          <Typography sub2 color="awaiting">
            รออนุมัติ
          </Typography>
        ) : text === 'approved' ? (
          <Typography sub2 color="success">
            อนุมัติแล้ว
          </Typography>
        ) : (
          text === 'pending' && (
            <Typography sub2 color="black90">
              {text}
            </Typography>
          )
        ),
    },
    {
      title: '',
      key: 'action',
      render: (record: ClaimList) => (
        <Space size="middle">
          <a
            onClick={() => {
              setSelectId(record.id)
              setOpenConfirmModal(true)
            }}
          >
            <Typography sub1 color="primary2">
              ลบ
            </Typography>
          </a>
        </Space>
      ),
    },
  ]

  return (
    <PageLayout title="รายการเคลม" shownBack={false}>
      <Card style={{ padding: '0px 16px' }}>
        <br />
        <Form form={form} name="ClaimList" layout="vertical">
          <div style={{ display: 'flex' }}>
            <Form.Item name="search" style={{ marginBottom: 0, width: '100%' }}>
              <Input
                className="ant-input-class"
                placeholder="ค้นหา ชื่อ, นามสกุล"
                allowClear
                onChange={onChange}
                addonAfter={<SearchOutlined style={{ color: theme.black40 }} />}
              />
            </Form.Item>
            <Form.Item name="hospitalId">
              <Select
                allowClear={true}
                placeholder="เลือกโรงพยาบาล"
                style={{ width: 250, marginLeft: 20 }}
                onChange={onSelectChange}
              >
                {hospitalList.map((x) => {
                  return (
                    <Option key={x.id} value={x.id}>
                      {x.name}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item name="date">
              <DatePicker
                onChange={onChangeDate}
                style={{ width: 180, marginLeft: 20 }}
                placeholder="เลือกวันที่"
                allowClear
              />
            </Form.Item>
          </div>
        </Form>
        <br />
        <Table columns={columns} dataSource={claimList} pagination={false} />
        <Pagination
          current={page}
          pageSize={6}
          total={total}
          style={{ padding: '20px 0px', textAlign: 'end' }}
          onChange={(page: number) => setPage(page)}
          showSizeChanger={false}
        />
      </Card>
      <AlertModal
        type="confirm"
        title="คุณยืนยันที่จะลบรายการเคลมนี้ใช่หรือไม่"
        isModalVisible={openConfirmModal}
        onCancel={() => setOpenConfirmModal(false)}
        onSubmit={() => {
          onDelete(selectId)
        }}
      />
    </PageLayout>
  )
}

export default List
