import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import Detail from './Detail'
import List from './List'

const UserPage = (): JSX.Element => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={path}>
        <List />
      </Route>
      <Route exact path={`${path}/add/:id`}>
        <Detail />
      </Route>
      <Route exact path={`${path}/edit/:id`}>
        <Detail />
      </Route>
    </Switch>
  )
}

export default UserPage
