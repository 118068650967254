/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { Form, Row, Col, Input, Steps, FormInstance, message } from 'antd'
import Button from '../../../components/Button'
import Typography from '../../../components/Typography'
import AlertModal from '../../../components/AlertModal'
import request from '../../../utils/request'
import SelectTag from '../../../components/SelectTag'
import { Hospital, MedicalSupply, Medicine, Symptom } from '../../../interface'
import useWindowDimensions from '../../../utils/getName'

const { TextArea } = Input
const { Step } = Steps
interface Props {
  setOpenPlan: React.Dispatch<React.SetStateAction<boolean>>
  form: FormInstance<any>
  insuranceId: string
  medicineOption: Medicine[]
  medicalSupplyOption: MedicalSupply[]
  symptomOption: Symptom[]
  hospitalOption: Hospital[]
  setRefresh?: React.Dispatch<React.SetStateAction<boolean>>
}

const StepAdd = ({
  setOpenPlan,
  form,
  insuranceId,
  medicineOption,
  medicalSupplyOption,
  symptomOption,
  hospitalOption,
  setRefresh,
}: Props): JSX.Element => {
  const [currentStep, setCurrentStep] = useState(0)
  const [openAlertModal, setOpenAlertModal] = useState(false)
  const [selectedMedicine, setSelectedMedicine] = useState<number[]>([])
  const [selectedMedicalSupply, setSelectedMedicalSupply] = useState<number[]>(
    []
  )
  const [selectedSymptom, setSelectedSymptom] = useState<number[]>([])
  const [selectedHospital, setSelectedHospital] = useState<number[]>([])

  const { width } = useWindowDimensions()

  const onFinish = async () => {
    try {
      await request.post(`/insurance/${insuranceId}/plan`, {
        name: form.getFieldValue('namePlanAdd'),
        shortDesc: form.getFieldValue('shortDescPlanAdd'),
        maxBenefitPerYear: parseInt(form.getFieldValue('maxBenefitPerYearAdd')),
        roomRatePerNight: parseInt(form.getFieldValue('roomRatePerNightAdd')),
        ipdPerYear: parseInt(form.getFieldValue('ipdPerYearAdd')),
        opdPerYear: parseInt(form.getFieldValue('opdPerYearAdd')),
        dentalLimit: parseInt(form.getFieldValue('dentalLimitAdd')),
        accidentDurationHour: parseInt(
          form.getFieldValue('accidentDurationHourAdd')
        ),
        includedSymptoms: form.getFieldValue('includedSymptomsAdd') || [],
        excludedSymptoms: form.getFieldValue('excludedSymptomsAdd') || [],
        includedMedicines: form.getFieldValue('includedMedicinesAdd') || [],
        excludedMedicines: form.getFieldValue('excludedMedicinesAdd') || [],
        includedMedicalSupplies:
          form.getFieldValue('includedMedicalSuppliesAdd') || [],
        excludedMedicalSupplies:
          form.getFieldValue('excludedMedicalSuppliesAdd') || [],
        includedHospitals: form.getFieldValue('includedHospitalsAdd') || [],
        excludedHospitals: form.getFieldValue('excludedHospitalsAdd') || [],
      })
      setOpenAlertModal(true)
      setRefresh?.((prev) => !prev)
    } catch (error) {
      message.error('บันทึกข้อมูลไม่สำเร็จ')
    }
  }

  const onCancel = () => {
    setSelectedMedicine([])
    setSelectedMedicalSupply([])
    setSelectedSymptom([])
    setSelectedHospital([])
    form.resetFields([
      'namePlanAdd',
      'shortDescPlanAdd',
      'maxBenefitPerYearAdd',
      'roomRatePerNightAdd',
      'ipdPerYearAdd',
      'opdPerYearAdd',
      'dentalLimitAdd',
      'accidentDurationHourAdd',
      'includedSymptomsAdd',
      'excludedSymptomsAdd',
      'includedMedicinesAdd',
      'excludedMedicinesAdd',
      'includedMedicalSuppliesAdd',
      'excludedMedicalSuppliesAdd',
      'includedHospitalsAdd',
      'excludedHospitalsAdd',
    ])
    setOpenPlan(false)
  }

  const stepsContent = [
    {
      content: (
        <>
          <Form.Item
            label={
              <Typography sub1 color="black65">
                แผนคุ้มครอง
              </Typography>
            }
            name="namePlanAdd"
            rules={[{ required: true, message: 'กรุณากรอกชื่อแผนคุ้มครอง' }]}
          >
            <Input className="ant-input-class" />
          </Form.Item>
          <Form.Item
            label={
              <Typography sub1 color="black65">
                คำอธิบายแผนการคุ้มครองอย่างสั้น
              </Typography>
            }
            name="shortDescPlanAdd"
          >
            <TextArea rows={3} />
          </Form.Item>
          <Row justify="space-between" gutter={32}>
            <Col span={12}>
              <Form.Item
                label={
                  <Typography sub1 color="black65">
                    ผลประโยชน์สูงสุด (ต่อปี)
                  </Typography>
                }
                name="maxBenefitPerYearAdd"
                rules={[
                  { required: true, message: 'กรุณากรอกผลประโยชน์สูงสุด' },
                ]}
              >
                <Input
                  className="ant-input-class"
                  addonAfter="บาท"
                  type="number"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <Typography sub1 color="black65">
                    ค่าห้อง (ต่อคืน)
                  </Typography>
                }
                name="roomRatePerNightAdd"
              >
                <Input
                  className="ant-input-class"
                  addonAfter="บาท"
                  type="number"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" gutter={32}>
            <Col span={12}>
              <Form.Item
                label={
                  <Typography sub1 color="black65">
                    IPD
                  </Typography>
                }
                name="ipdPerYearAdd"
              >
                <Input
                  className="ant-input-class"
                  addonAfter="จำนวนครั้งต่อปี"
                  type="number"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <Typography sub1 color="black65">
                    OPD
                  </Typography>
                }
                name="opdPerYearAdd"
              >
                <Input
                  className="ant-input-class"
                  addonAfter="จำนวนครั้งต่อปี"
                  type="number"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" gutter={32}>
            <Col span={12}>
              <Form.Item
                label={
                  <Typography sub1 color="black65">
                    วงเงินทันตกรรม
                  </Typography>
                }
                name="dentalLimitAdd"
              >
                <Input
                  className="ant-input-class"
                  addonAfter="จำนวนครั้งต่อปี"
                  type="number"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <Typography sub1 color="black65">
                    ระยะเวลาการเกิดอุบัติเหตุ (ชั่วโมง)
                  </Typography>
                }
                name="accidentDurationHourAdd"
              >
                <Input
                  className="ant-input-class"
                  addonAfter="ชั่วโมง"
                  type="number"
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
    {
      content: (
        <>
          <Row justify="space-between" gutter={32}>
            <Col span={12}>
              <Form.Item
                label={
                  <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography sub1 color="black65">
                      ยาที่
                    </Typography>
                    <Typography title1 color="error">
                      ไม่รวม
                    </Typography>
                    <Typography sub1 color="black65">
                      ในกรมธรรม์
                    </Typography>
                  </div>
                }
              >
                <SelectTag
                  title="เพิ่มรายการยา"
                  formName="excludedMedicinesAdd"
                  tagColor="red"
                  tagKey="commonName"
                  options={medicineOption}
                  setSelectedItems={setSelectedMedicine}
                  selectedItems={selectedMedicine}
                  isOpen={
                    form.getFieldValue('excludedMedicinesAdd')?.length > 0
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography sub1 color="black65">
                      ยาที่
                    </Typography>
                    <Typography title1 color="primary1">
                      รวม
                    </Typography>
                    <Typography sub1 color="black65">
                      ในกรมธรรม์
                    </Typography>
                  </div>
                }
              >
                <SelectTag
                  title="เพิ่มรายการยา"
                  formName="includedMedicinesAdd"
                  tagColor="blue"
                  tagKey="commonName"
                  options={medicineOption}
                  setSelectedItems={setSelectedMedicine}
                  selectedItems={selectedMedicine}
                  isOpen={
                    form.getFieldValue('includedMedicinesAdd')?.length > 0
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
    {
      content: (
        <>
          <Row justify="space-between" gutter={32}>
            <Col span={12}>
              <Form.Item
                label={
                  <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography sub1 color="black65">
                      เวชภัณฑ์ที่
                    </Typography>
                    <Typography title1 color="error">
                      ไม่รวม
                    </Typography>
                    <Typography sub1 color="black65">
                      ในกรมธรรม์
                    </Typography>
                  </div>
                }
              >
                <SelectTag
                  title="เพิ่มรายการเวชภัณฑ์"
                  formName="excludedMedicalSuppliesAdd"
                  tagColor="red"
                  tagKey="commonName"
                  options={medicalSupplyOption}
                  setSelectedItems={setSelectedMedicalSupply}
                  selectedItems={selectedMedicalSupply}
                  isOpen={
                    form.getFieldValue('excludedMedicalSuppliesAdd')?.length > 0
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography sub1 color="black65">
                      เวชภัณฑ์ที่
                    </Typography>
                    <Typography title1 color="primary1">
                      รวม
                    </Typography>
                    <Typography sub1 color="black65">
                      ในกรมธรรม์
                    </Typography>
                  </div>
                }
              >
                <SelectTag
                  title="เพิ่มรายการเวชภัณฑ์"
                  formName="includedMedicalSuppliesAdd"
                  tagColor="blue"
                  tagKey="commonName"
                  options={medicalSupplyOption}
                  setSelectedItems={setSelectedMedicalSupply}
                  selectedItems={selectedMedicalSupply}
                  isOpen={
                    form.getFieldValue('includedMedicalSuppliesAdd')?.length > 0
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
    {
      content: (
        <>
          <Row justify="space-between" gutter={32}>
            <Col span={12}>
              <Form.Item
                label={
                  <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography sub1 color="black65">
                      อาการ / โรคที่
                    </Typography>
                    <Typography title1 color="error">
                      ไม่รวม
                    </Typography>
                    <Typography sub1 color="black65">
                      ในกรมธรรม์
                    </Typography>
                  </div>
                }
              >
                <SelectTag
                  title="เพิ่มอาการ / โรค"
                  formName="excludedSymptomsAdd"
                  tagColor="red"
                  tagKey="name"
                  options={symptomOption}
                  setSelectedItems={setSelectedSymptom}
                  selectedItems={selectedSymptom}
                  isOpen={form.getFieldValue('excludedSymptomsAdd')?.length > 0}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography sub1 color="black65">
                      อาการ / โรคที่
                    </Typography>
                    <Typography title1 color="primary1">
                      รวม
                    </Typography>
                    <Typography sub1 color="black65">
                      ในกรมธรรม์
                    </Typography>
                  </div>
                }
              >
                <SelectTag
                  title="เพิ่มอาการ / โรค"
                  formName="includedSymptomsAdd"
                  tagColor="blue"
                  tagKey="name"
                  options={symptomOption}
                  setSelectedItems={setSelectedSymptom}
                  selectedItems={selectedSymptom}
                  isOpen={form.getFieldValue('includedSymptomsAdd')?.length > 0}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
    {
      content: (
        <>
          <Row justify="space-between" gutter={32}>
            <Col span={12}>
              <Form.Item
                label={
                  <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography sub1 color="black65">
                      โรงพยาบาลที่
                    </Typography>
                    <Typography title1 color="error">
                      ไม่รวม
                    </Typography>
                    <Typography sub1 color="black65">
                      ในกรมธรรม์
                    </Typography>
                  </div>
                }
              >
                <SelectTag
                  title="เพิ่มโรงพยาบาล"
                  formName="excludedHospitalsAdd"
                  tagColor="red"
                  tagKey="name"
                  options={hospitalOption}
                  setSelectedItems={setSelectedHospital}
                  selectedItems={selectedHospital}
                  isOpen={
                    form.getFieldValue('excludedHospitalsAdd')?.length > 0
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography sub1 color="black65">
                      โรงพยาบาลที่
                    </Typography>
                    <Typography title1 color="primary1">
                      รวม
                    </Typography>
                    <Typography sub1 color="black65">
                      ในกรมธรรม์
                    </Typography>
                  </div>
                }
              >
                <SelectTag
                  title="เพิ่มโรงพยาบาล"
                  formName="includedHospitalsAdd"
                  tagColor="blue"
                  tagKey="name"
                  options={hospitalOption}
                  setSelectedItems={setSelectedHospital}
                  selectedItems={selectedHospital}
                  isOpen={
                    form.getFieldValue('includedHospitalsAdd')?.length > 0
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
  ]

  return (
    <>
      <div
        style={{
          backgroundColor: '#F2FBFF',
          borderRadius: 8,
          padding: '0px 15px',
        }}
      >
        <Steps
          current={currentStep}
          labelPlacement={width && width <= 1024 ? 'vertical' : 'horizontal'}
          // onChange={(current) => setCurrentStep(current)}
          style={{ padding: '30px 0px 40px' }}
        >
          <Step title="แผนการคุ้มครอง" />
          <Step title="ยา" />
          <Step title="เวชภัณฑ์" />
          <Step title="อาการ / โรค" />
          <Step title="โรงพยาบาล" />
        </Steps>

        {stepsContent[currentStep].content}
      </div>
      <Row
        justify={currentStep === 0 ? 'space-between' : 'end'}
        align="middle"
        style={{ paddingTop: 20 }}
      >
        {currentStep === 0 && (
          <Button
            htmlType="button"
            title="ลบแผนการคุ้มครอง"
            type="danger"
            onClick={() => {
              onCancel()
            }}
          />
        )}
        <Row>
          {currentStep !== 0 && (
            <Button
              htmlType="button"
              title="ย้อนกลับ"
              type="secondary"
              onClick={() => setCurrentStep(currentStep - 1)}
              style={{ width: 100, marginRight: 16 }}
            />
          )}
          {currentStep !== 4 ? (
            <Button
              htmlType="button"
              title="ถัดไป"
              onClick={() => {
                if (
                  form.getFieldValue('namePlanAdd') &&
                  form.getFieldValue('maxBenefitPerYearAdd')
                )
                  setCurrentStep(currentStep + 1)
              }}
              style={{ width: 100 }}
            />
          ) : (
            <Button
              htmlType="button"
              title="บันทึก"
              onClick={() => {
                onFinish()
              }}
              style={{ width: 100 }}
            />
          )}
        </Row>
      </Row>
      <AlertModal
        type="done"
        title="เพิ่มแผนการคุ้มครองเรียบร้อย"
        isModalVisible={openAlertModal}
        onSubmit={() => {
          setOpenAlertModal(false)
          onCancel()
        }}
      />
    </>
  )
}

export default StepAdd
