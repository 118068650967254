/* eslint-disable @typescript-eslint/no-explicit-any */
import { Col, Row, FormInstance, message } from 'antd'
import React, { useState } from 'react'
import Card from '../Card'
import Typography from '../Typography'
import DeleteIcon from '../../image/DeleteIcon.svg'
import StepEdit from '../../pages/InsurancePage/Detail/StepEdit'
import {
  Hospital,
  InsurancePlan,
  MedicalSupply,
  Medicine,
  Symptom,
} from '../../interface'
import AlertModal from '../../components/AlertModal'
import request from '../../utils/request'

interface Props {
  data: InsurancePlan
  form: FormInstance<any>
  medicineOption: Medicine[]
  medicalSupplyOption: MedicalSupply[]
  symptomOption: Symptom[]
  hospitalOption: Hospital[]
  setRefresh?: React.Dispatch<React.SetStateAction<boolean>>
}
const InsurancePlanCard = ({
  data,
  form,
  medicineOption,
  medicalSupplyOption,
  symptomOption,
  hospitalOption,
  setRefresh,
}: Props): JSX.Element => {
  const [toggle, setToggle] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [planSelect, setPlanSelect] = useState<InsurancePlan>()

  const onDelete = async () => {
    try {
      await request.delete(`/insurance/${data.insuranceId}/plan/${data.id}`)
      message.success('ลบแผนคุ้มครองสำเร็จ')
      setOpenConfirmModal(false)
      setRefresh?.((prev) => !prev)
    } catch (err) {
      message.error('ดำเนินการผิดพลาด')
      console.log(err)
    }
  }
  const getPlanSelect = async () => {
    try {
      const res: InsurancePlan = await request.get(
        `/insurance/${data.insuranceId}/plan/${data.id}`
      )
      setPlanSelect(res)
    } catch (error) {
      message.error('เรียกข้อมูลไม่สำเร็จ')
    }
  }

  return (
    <>
      <Card style={{ marginTop: 20, padding: '0px 16px' }}>
        <Row justify="space-between" align="middle">
          <Col
            span={22}
            onClick={() => {
              getPlanSelect()
              setToggle(!toggle)
            }}
            style={{ padding: '20px 0px' }}
          >
            <Row style={{ gap: 50 }}>
              <Typography title2 color="black90">
                {data.name}
              </Typography>
              <Typography title2 color="black90">
                {`${data.maxBenefitPerYear} บาท`}
              </Typography>
            </Row>
          </Col>
          <Col span={2} style={{ textAlign: 'end' }}>
            <img
              style={{ width: 17, height: 18, zIndex: 100, cursor: 'pointer' }}
              src={DeleteIcon}
              onClick={() => setOpenConfirmModal(true)}
            />
          </Col>
        </Row>
        {toggle ? (
          <StepEdit
            planSelect={planSelect}
            data={data}
            form={form}
            medicineOption={medicineOption}
            medicalSupplyOption={medicalSupplyOption}
            symptomOption={symptomOption}
            hospitalOption={hospitalOption}
            setToggle={setToggle}
            setRefresh={setRefresh}
          />
        ) : (
          <></>
        )}
      </Card>
      <AlertModal
        type="confirm"
        title="คุณยืนยันที่จะลบแผนการคุ้มครองนี้ใช่หรือไม่"
        isModalVisible={openConfirmModal}
        onCancel={() => setOpenConfirmModal(false)}
        onSubmit={() => {
          onDelete()
        }}
      />
    </>
  )
}

export default InsurancePlanCard
