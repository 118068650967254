import React, { useState, useEffect } from 'react'
import { Row, Select, Spin } from 'antd'
import debounce from 'lodash/debounce'
import { OptionType } from '../../interface'
import { SelectValue } from 'antd/lib/select'
import Decimal from 'decimal.js'

const { Option } = Select

interface Props {
  fetchOptions: (search: string, page: number) => void
  handleSelect: (value: SelectValue) => void
  placeholder?: string
  value?: string | number
  data: OptionType[]
  fetching: boolean
  loadingPage: boolean
  hasMorePage: boolean
}

const SearchSelect: React.FC<Props> = ({
  fetchOptions,
  handleSelect,
  placeholder,
  value,
  data,
  fetching,
  loadingPage,
  hasMorePage,
}) => {
  const [page, setPage] = useState<number>(1)

  const debouncedFetchOptions = debounce((search: string, page: number) => {
    fetchOptions(search, page)
  }, 500)

  useEffect(() => {
    if (loadingPage) {
      debouncedFetchOptions('', page)
    }
  }, [page, loadingPage])

  const handleSearch = (value: string) => {
    setPage(1)
    debouncedFetchOptions(value, 1)
  }

  const handlePopupScroll: React.UIEventHandler<HTMLDivElement> = (e) => {
    const target = e.target as HTMLDivElement
    if (
      new Decimal(target.scrollTop)
        .add(target.clientHeight)
        .ceil()
        .toNumber() === target.scrollHeight
    ) {
      if (hasMorePage) {
        setPage((prevPage) => prevPage + 1)
      }
    }
  }

  return (
    <Select
      className="ant-input-class"
      listHeight={128}
      value={value}
      showSearch
      placeholder={placeholder}
      notFoundContent={
        fetching ? (
          <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Spin size="small" />
          </Row>
        ) : undefined
      }
      filterOption={false}
      onSearch={handleSearch}
      onPopupScroll={handlePopupScroll}
      onSelect={handleSelect}
      optionFilterProp="children"
      getPopupContainer={(trigger) => trigger.parentNode}
    >
      {data.map((d: OptionType) => (
        <Option key={d.value} value={d.value}>
          {d.label}
        </Option>
      ))}
      {(fetching || loadingPage) && hasMorePage && (
        <Option key="loading" value="" disabled>
          <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Spin size="small" />
          </Row>
        </Option>
      )}
    </Select>
  )
}

export default SearchSelect
