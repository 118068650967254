/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  Dispatch,
  SetStateAction,
  useState,
  useMemo,
  useEffect,
} from 'react'
import { Form, message, Modal as AntModal } from 'antd'
import Typography from '../../../components/Typography'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'
import {
  CaseDiagnosis,
  Hospital,
  MedicalService,
  MedicalSupply,
  Medicine,
} from '../../../interface'
import request from '../../../utils/request'
import dayjs from 'dayjs'
import { scrollToTop } from '../../../utils/getName'
import Step6 from './Step6'
import Step7 from './Step7'

interface Props {
  id?: string | number
  isModalVisible: boolean
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
  setRefresh?: Dispatch<SetStateAction<boolean>>
  setSelectId: React.Dispatch<React.SetStateAction<number | undefined>>
}

const ModalDiagnostic = ({
  id,
  isModalVisible,
  setIsModalVisible,
  setRefresh,
  setSelectId,
}: Props): JSX.Element => {
  const [form] = Form.useForm()
  const [step, setStep] = useState(1)
  const [data, setData] = useState<CaseDiagnosis>()
  const [medicine, setMedicine] = useState<Medicine[]>([])
  const [medicalSupply, setMedicalSupply] = useState<MedicalSupply[]>([])
  const [medicalService, setMedicalService] = useState<MedicalService[]>([])
  const [hospital, setHospital] = useState<Hospital[]>([])
  const [selectedSymptom, setSelectedSymptom] = useState<number[]>([])

  const onClose = () => {
    setIsModalVisible(false)
    scrollToTop()
    setStep(1)
    form.resetFields()
    setSelectId(undefined)
  }

  const Content = useMemo(() => {
    if (step === 1) return Step1
    else if (step === 2) return Step2
    else if (step === 3) return Step3
    else if (step === 4) return Step4
    else if (step === 5) return Step5
    else if (step === 6) return Step6
    else if (step === 7) return Step7
    else return Step1
  }, [step])

  const getData = async () => {
    if (id) {
      try {
        const res: CaseDiagnosis = await request.get(`/diagnosis/${id}`)
        setData(res)
      } catch (error) {
        message.error('เรียกข้อมูลไม่สำเร็จ')
        setData(undefined)
      }
      return
    }
    setData(undefined)
  }

  useEffect(() => {
    getData()
  }, [id])

  useEffect(() => {
    if (data?.status === 'diagnosed') {
      const symptomArray = data.diagnosis.initialDiagnosis?.map(
        (item: { id: number }) => item.id
      )
      form.setFieldsValue({
        date: dayjs(data?.diagnosis.date),
        durationDay: data.diagnosis.durationDay,
        durationOffsetHour: data.diagnosis.durationOffsetHour,
        importantSymptom: data.diagnosis.importantSymptom,
        importantSymptomAttachments: data.diagnosis.importantSymptomAttachments,
        cumulativeInjury: data.diagnosis.cumulativeInjury,
        cumulativeInjuryAttachments: data.diagnosis.cumulativeInjuryAttachments,
        physicalInjury: data.diagnosis.physicalInjury,
        physicalInjuryAttachments: data.diagnosis.physicalInjuryAttachments,
        treatmentHistory: data.diagnosis.physicalInjury,
        treatmentHistoryAttachments: data.diagnosis.treatmentHistoryAttachments,
        symptomFactors: data.diagnosis.symptomFactors,
        accidentDate: data.diagnosis.accidentDate
          ? dayjs(data.diagnosis.accidentDate)
          : null,
        otherCondition: data.diagnosis.otherCondition,
        otherConditionAttachments: data.diagnosis.otherConditionAttachments,
        initialDiagnosis: symptomArray,
        initialDiagnosisAttachments: data.diagnosis.initialDiagnosisAttachments,
        isTreatable: data.diagnosis.isTreatable,
        untreatableReason: data.diagnosis.untreatableReason,
        admitReason: data.diagnosis.admitReason,
        admitReasonAttachments: data.diagnosis.admitReasonAttachments,
        treatmentPlan: data.diagnosis.treatmentPlan,
        treatmentPlanAttachments: data.diagnosis.treatmentPlanAttachments,
        hospitalId: data.diagnosis.hospitalId,
        medicineList: data?.diagnosis.medicineList.map((item: any) => {
          return {
            medicineId: item.medicineId,
            quantity: item.quantity,
            price: item.totalPrice,
            unit: item.medicine.unit,
          }
        }),
        medicalSupplies: data?.diagnosis.medicalSupplies.map((item: any) => {
          return {
            supplyId: item.supplyId,
            quantity: item.quantity,
            price: item.totalPrice,
            unit: item.supply.unit,
          }
        }),
        medicalServices: data?.diagnosis.medicalServices.map((item: any) => {
          return {
            serviceId: item.serviceId,
            quantity: item.quantity,
            price: item.totalPrice,
            type: item.type,
          }
        }),
      })
      setSelectedSymptom(symptomArray)
    } else {
      form.resetFields()
      form.setFieldsValue({
        date: dayjs(),
        isTreatable: true,
      })
    }
  }, [data])

  const getMedicineAll = async () => {
    try {
      const res: Medicine[] = await request.get(`/medicine/all`)
      setMedicine(res)
    } catch (error) {
      message.error('เรียกข้อมูลไม่สำเร็จ')
    }
  }
  const getMedicalSupplyAll = async () => {
    try {
      const res: MedicalSupply[] = await request.get(`/medical-supply/all`)
      setMedicalSupply(res)
    } catch (error) {
      message.error('เรียกข้อมูลไม่สำเร็จ')
    }
  }
  const getMedicalServiceAll = async () => {
    try {
      const res: MedicalService[] = await request.get(`/medical-service/all`)
      setMedicalService(res)
    } catch (error) {
      message.error('เรียกข้อมูลไม่สำเร็จ')
    }
  }

  const getHospitalAll = async () => {
    try {
      const res: Hospital[] = await request.get(`/hospital/all`)
      setHospital(res)
    } catch (error) {
      message.error('เรียกข้อมูลไม่สำเร็จ')
    }
  }

  useEffect(() => {
    getMedicineAll()
    getMedicalSupplyAll()
    getMedicalServiceAll()
    getHospitalAll()
  }, [])

  const convertData = (key: string) => {
    return form.getFieldValue(key)?.length === 0
      ? form.getFieldValue(key)
      : form
          .getFieldValue(key)
          ?.some((element: any) => typeof element === 'number')
      ? form.getFieldValue(key)
      : form.getFieldValue(key)?.map((item: any) => {
          return item?.response?.id
        }) || []
  }
  const convertDateTime = (date: any, time: any) => {
    if (date && form.getFieldValue('symptomFactors').includes('accident')) {
      const newDate = dayjs(date).format('YYYY-MM-DD')
      const newTime = time ? dayjs(time).format('HH:mm:ss') : '00:00:00'
      return dayjs(`${newDate} ${newTime}`).toISOString()
    } else {
      return null
    }
  }

  const onFinish = async () => {
    try {
      await request.put(`/diagnosis/${id}/diagnosis`, {
        date: form.getFieldValue('date'),
        durationDay: parseInt(form.getFieldValue('durationDay')) || null,
        durationOffsetHour:
          parseInt(form.getFieldValue('durationOffsetHour')) || null,
        hospitalId: form.getFieldValue('hospitalId'),
        importantSymptom: form.getFieldValue('importantSymptom') || null,
        importantSymptomAttachments: convertData('importantSymptomAttachments'),
        cumulativeInjury: form.getFieldValue('cumulativeInjury') || null,
        cumulativeInjuryAttachments: convertData('cumulativeInjuryAttachments'),
        physicalInjury: form.getFieldValue('physicalInjury') || null,
        physicalInjuryAttachments: convertData('physicalInjuryAttachments'),
        treatmentHistory: form.getFieldValue('treatmentHistory') || null,
        treatmentHistoryAttachments: convertData('treatmentHistoryAttachments'),
        symptomFactors: form.getFieldValue('symptomFactors') || [],
        accidentDate: convertDateTime(
          form.getFieldValue('accidentDate'),
          form.getFieldValue('accidentTime')
        ),
        otherCondition: form.getFieldValue('otherCondition') || null,
        otherConditionAttachments:
          convertData('otherConditionAttachments') || [],
        initialDiagnosis: form.getFieldValue('initialDiagnosis') || null,
        initialDiagnosisAttachments:
          convertData('initialDiagnosisAttachments') || [],
        isTreatable: form.getFieldValue('isTreatable'),
        untreatableReason: form.getFieldValue('untreatableReason') || '',
        admitReason: form.getFieldValue('admitReason') || null,
        admitReasonAttachments: convertData('admitReasonAttachments') || [],
        treatmentPlan: form.getFieldValue('treatmentPlan') || null,
        treatmentPlanAttachments: convertData('treatmentPlanAttachments') || [],
        medicineList:
          form.getFieldValue('medicineList')?.map((item: any) => {
            return {
              medicineId: item.medicineId,
              quantity: item.quantity,
            }
          }) || [],
        medicalSupplies:
          form.getFieldValue('medicalSupplies')?.map((item: any) => {
            return {
              supplyId: item.supplyId,
              quantity: item.quantity,
            }
          }) || [],
        medicalServices: form.getFieldValue('medicalServices') || [],
      })
      message.success('เพิ่มรายการสำเร็จ')
      onClose()
      setRefresh?.((prev) => !prev)
    } catch (e: any) {
      message.error('ดำเนินการผิดพลาด')
      console.log(e)
    }
  }

  return (
    <AntModal
      centered
      visible={isModalVisible}
      style={{ borderRadius: 8 }}
      bodyStyle={{ overflowX: 'scroll', height: 500 }}
      width={700}
      title={
        <Typography title1 color="black90">
          {data?.status === 'diagnosed'
            ? step >= 5
              ? 'แก้ไขการรักษา'
              : 'แก้ไขผลวินิจฉัย'
            : step >= 5
            ? 'การรักษา'
            : 'วินิจฉัย'}
        </Typography>
      }
      footer={null}
      onCancel={onClose}
    >
      <Form
        form={form}
        name="DiagnosisModal"
        layout="vertical"
        onFinish={onFinish}
        style={{ height: '100%' }}
      >
        <Content
          data={data}
          setIsModalVisible={setIsModalVisible}
          setStep={setStep}
          form={form}
          medicine={medicine}
          medicalSupply={medicalSupply}
          medicalService={medicalService}
          hospital={hospital}
          setSelectedSymptom={setSelectedSymptom}
          selectedSymptom={selectedSymptom}
        />
      </Form>
    </AntModal>
  )
}

export default ModalDiagnostic
