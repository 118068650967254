import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Col, Row } from 'antd'

interface Props {
  item: {
    title: string
    key: string
    content: JSX.Element
  }[]
  tabBar?: JSX.Element
  initialtab?: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setAddbutton?: any
}

const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #c2c2c2;
`
const TabMenu = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 0px 2px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  flex-direction: column;
  cursor: pointer;
`
const TabLine = styled.div`
  width: 100%;
  height: 5px;
  position: relative;
  bottom: -5px;
  z-index: 10;
`

const Tab = ({ item, setAddbutton, initialtab = 0 }: Props): JSX.Element => {
  const [selectIndex, setSelectIndex] = useState(initialtab)

  return (
    <>
      <Row>
        <Col xl={24} lg={24} md={24}>
          <TabContainer>
            {item.map((i, index) => {
              return (
                <Col xl={4} lg={4} md={5} key={i.key}>
                  <TabMenu
                    onClick={() => {
                      setSelectIndex(index)
                      if (setAddbutton) {
                        setAddbutton(index)
                      }
                    }}
                    style={{
                      color: selectIndex === index ? '#24488E' : '#A2A2A2',
                      fontWeight: selectIndex === index ? 700 : 400,
                    }}
                  >
                    {i.title}
                    <TabLine
                      style={{
                        backgroundColor:
                          selectIndex === index ? '#304A8F' : 'transparent',
                      }}
                    ></TabLine>
                  </TabMenu>
                </Col>
              )
            })}
          </TabContainer>
        </Col>
        {item[selectIndex].content}
      </Row>
    </>
  )
}

export default Tab
