/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import SearchSelect from '../../../components/SearchSelect'
import { MedicineList, OptionType } from '../../../interface'
import request from '../../../utils/request'
import { FormInstance, message } from 'antd'
import { SelectValue } from 'antd/lib/select'
import _ from 'lodash'

interface Props {
  onChange: (value: SelectValue, index?: number) => void
  form: FormInstance<any>
  medicineList: MedicineList[]
  radioType: string
  refresh: boolean
  index: number
}

const MedicineSelect: React.FC<Props> = ({
  onChange,
  form,
  medicineList,
  radioType,
  refresh,
  index,
}) => {
  const [value, setValue] = useState<number | undefined>(undefined)
  const [dataMedicine, setDataMedicine] = useState<OptionType[]>([])
  const [fetchingMedicine, setFetchingMedicine] = useState<boolean>(false)
  const [hasMorePageMedicine, setHasMorePageMedicine] = useState<boolean>(false)

  useEffect(() => {
    const medicine = form.getFieldValue(['medicineList', index, 'medicineId'])
    if (medicine) {
      setValue(medicine)
    }
  }, [form.getFieldValue(['medicineList', index, 'medicineId'])])

  useEffect(() => {
    if (medicineList.length > 0) {
      if (index < medicineList.length) {
        setDataMedicine(
          medicineList.map((item) => ({
            value: item.medicine.id,
            label: item.medicine.commonName,
          }))
        )
      }
    }
    fetchOptionsMedicine('', 1)
  }, [medicineList, radioType, refresh, form])

  const fetchOptionsMedicine = async (
    search: string,
    page: number
  ): Promise<void> => {
    try {
      setFetchingMedicine(true)
      const res: any = await request.get(`/medicine`, {
        params: {
          page: page,
          limit: 10,
          keyword: search ? search : undefined,
        },
      })
      const newOptions = res.data.map((item: any) => ({
        value: item.id,
        label: `${item.commonName} ${item.tradeNames.join(',')} (${
          item.price
        } บาท/${item.unit})`,
      }))
      setDataMedicine((prevData: OptionType[]) => {
        const uniqArray = [...prevData, ...newOptions]
        return _.uniqBy(uniqArray, 'value')
      })
      setHasMorePageMedicine(res.hasMorePage)
    } catch (err) {
      message.error('เรียกข้อมูลไม่สำเร็จ')
    } finally {
      setFetchingMedicine(false)
    }
  }

  return (
    <SearchSelect
      placeholder="กรุณาเลือกยา"
      fetchOptions={fetchOptionsMedicine}
      handleSelect={onChange}
      value={value}
      data={dataMedicine}
      fetching={fetchingMedicine}
      loadingPage={medicineList.length > 0 ? true : false}
      hasMorePage={hasMorePageMedicine}
    />
  )
}

export default MedicineSelect
