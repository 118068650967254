/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import SearchSelect from '../../../components/SearchSelect'
import { InsurancePlan, OptionType } from '../../../interface'
import request from '../../../utils/request'
import { FormInstance, message } from 'antd'
import { SelectValue } from 'antd/lib/select'
import { useParams } from 'react-router-dom'
import _ from 'lodash'

interface Props {
  form: FormInstance<any>
  plan: InsurancePlan[]
}

interface Params {
  en: string
  id: string
}

const PlanSelect: React.FC<Props> = ({ form, plan }) => {
  const params = useParams<Params>()
  const [value, setValue] = useState<number | undefined>(undefined)
  const [dataInsurancePlans, setDataInsurancePlans] = useState<OptionType[]>([])
  const [fetchingInsurancePlans, setFetchingInsurancePlans] =
    useState<boolean>(false)
  const [hasMorePageInsurancePlans, setHasMorePageInsurancePlans] =
    useState<boolean>(false)

  useEffect(() => {
    const planId = form.getFieldValue('planId')
    if (planId) {
      setValue(planId)
    }
  }, [form.getFieldValue('planId')])

  useEffect(() => {
    if (params?.id) {
      if (plan.length > 0) {
        setDataInsurancePlans([
          {
            value: plan[0].id,
            label: plan[0].name,
          },
        ])
      }
      if (form.getFieldValue('hn')) {
        fetchOptionsInsurancePlans('', 1)
      }
    }
  }, [params, form.getFieldValue('hn')])

  const onInsurancePlansSelect = async (value: SelectValue) => {
    form.setFieldsValue({ planId: value })
  }

  const fetchOptionsInsurancePlans = async (
    search: string,
    page: number
  ): Promise<void> => {
    try {
      setFetchingInsurancePlans(true)
      const res: any = await request.get(
        `/insured/${form.getFieldValue('hn')}/list-insurance-plan`,
        {
          params: {
            page: page,
            limit: 10,
            keyword: search ? search : undefined,
          },
        }
      )
      const newOptions = res.data.map((item: any) => ({
        value: item.planId,
        label: item.planName,
      }))
      setDataInsurancePlans((prevData: OptionType[]) => {
        const uniqArray = [...prevData, ...newOptions]
        return _.uniqBy(uniqArray, 'value')
      })
      setHasMorePageInsurancePlans(res.hasMorePage)
    } catch (err) {
      message.error('เรียกข้อมูลไม่สำเร็จ')
    } finally {
      setFetchingInsurancePlans(false)
    }
  }

  return (
    <SearchSelect
      placeholder="กรุณาเลือกกรมธรรม์"
      fetchOptions={fetchOptionsInsurancePlans}
      handleSelect={onInsurancePlansSelect}
      value={value}
      data={dataInsurancePlans}
      fetching={fetchingInsurancePlans}
      loadingPage={form.getFieldValue('hn') !== undefined ? true : false}
      hasMorePage={hasMorePageInsurancePlans}
    />
  )
}

export default PlanSelect
