/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Select, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import Button from '../Button'
import IconAdd from '../../image/IconAdd.png'
interface Props {
  formName: string
  options: any[]
  setSelectedItems: React.Dispatch<React.SetStateAction<any[]>>
  selectedItems: any[]
  isOpen: boolean
  tagColor: 'red' | 'blue'
  tagKey: string
  title: string
  height?: number
}
interface TagsProps {
  label: any
  value: any
  onClose: any
  closable: any
}

const { Option } = Select

const SelectTag = ({
  title,
  formName,
  options,
  setSelectedItems,
  selectedItems,
  isOpen,
  tagColor,
  tagKey,
  height = 250,
}: Props): JSX.Element => {
  const [openSelectTag, setOpenSelectTag] = useState(isOpen)

  useEffect(() => {
    setOpenSelectTag(isOpen)
  }, [formName])

  const filteredOptions = options.filter((o) => !selectedItems.includes(o.id))

  const handleChange = (value: any) => {
    if (value.length === 0) {
      setOpenSelectTag(!openSelectTag)
    }
    setSelectedItems((prev) =>
      [...prev, ...value].filter((v, i) => [...prev, ...value].indexOf(v) == i)
    )
  }

  const handleDeselect = (value: string) => {
    setSelectedItems((prev) => prev.filter((item) => item !== value))
  }

  const showInput = () => {
    setOpenSelectTag(!openSelectTag)
  }

  const tagRender = ({ label, onClose, closable }: TagsProps) => {
    const onPreventMouseDown = (event: {
      preventDefault: () => void
      stopPropagation: () => void
    }) => {
      event.preventDefault()
      event.stopPropagation()
    }
    return (
      <Tag
        color={tagColor}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ borderRadius: 30, marginBottom: 3 }}
      >
        {options.map((i) => {
          if (i.id === label) {
            return i[tagKey]
          }
        })}
      </Tag>
    )
  }

  return (
    <div
      style={{
        border: '1px solid #d9d9d9',
        borderRadius: 8,
        minHeight: height,
        maxHeight: height,
        overflow: 'scroll',
      }}
    >
      {openSelectTag && (
        <Form.Item
          name={formName}
          style={{ padding: 10, paddingBottom: 0, margin: 0 }}
        >
          <Select
            placeholder=""
            mode="multiple"
            className="selectTags"
            tagRender={tagRender}
            listHeight={128}
            optionFilterProp="children"
            autoFocus={isOpen ? false : true}
            defaultOpen={isOpen ? false : true}
            onChange={handleChange}
            onDeselect={handleDeselect}
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            {filteredOptions.map((item) => (
              <Option key={item.id} value={item.id}>
                {item[tagKey]}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {!openSelectTag && (
        <Button
          type="text"
          htmlType="button"
          iconLeft={<img style={{ width: 23, height: 23 }} src={IconAdd} />}
          style={{ padding: '10px 0px 0px 10px' }}
          title={title}
          onClick={() => showInput()}
        />
      )}
    </div>
  )
}

export default SelectTag
