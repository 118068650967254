/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction, useState } from 'react'
import { Form, Row, Input, Checkbox, Col, FormInstance } from 'antd'
import Button from '../../../components/Button'
import Typography from '../../../components/Typography'
import DatePicker from '../../../components/DatePicker'
// import IconAdd from '../../../image/IconAdd.png'
import { scrollToTop } from '../../../utils/getName'
import TimePicker from '../../../components/TimePicker'
import UploadMultipleFile from '../../../components/UploadMultipleFile'
import { CaseDiagnosis } from '../../../interface'

interface Props {
  id?: string
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
  setStep?: Dispatch<SetStateAction<number>> | undefined
  form: FormInstance<any>
  data: CaseDiagnosis | undefined
}

const { TextArea } = Input

const Step2 = ({ setStep, form, data }: Props): JSX.Element => {
  const [isAccident, setIsAccident] = useState(
    form?.getFieldValue('symptomFactors')?.includes('accident')
  )

  const onChange = (e: any) => {
    setIsAccident(e.target.checked)
  }

  return (
    <>
      <Form.Item
        label={
          <Typography sub4 color="black65">
            อาการบาดเจ็บทางร่างกาย
          </Typography>
        }
      >
        <Form.Item noStyle name="physicalInjury">
          <TextArea
            rows={3}
            autoSize={{ minRows: 3, maxRows: 3 }}
            allowClear={true}
          />
        </Form.Item>
        <UploadMultipleFile
          formName="physicalInjuryAttachments"
          form={form}
          data={data?.diagnosis.physicalInjuryAttachments}
        />
      </Form.Item>
      <Form.Item
        style={{ width: '100%' }}
        label={
          <Typography sub4 color="black65">
            ประวัติการรักษา (วันและสถานที่)
          </Typography>
        }
      >
        <Form.Item noStyle name="treatmentHistory">
          <TextArea
            rows={3}
            autoSize={{ minRows: 3, maxRows: 3 }}
            allowClear={true}
          />
        </Form.Item>
        <UploadMultipleFile
          formName="treatmentHistoryAttachments"
          form={form}
          data={data?.diagnosis.treatmentHistoryAttachments}
        />
      </Form.Item>
      <Form.Item
        style={{ width: '100%' }}
        label={
          <Typography sub4 color="black65">
            อาการป่วยนี้เกี่ยวข้องกับปัจจัยต่อไปนี้
          </Typography>
        }
        name="symptomFactors"
      >
        <Checkbox.Group style={{ width: '100%', lineHeight: 2 }}>
          <Row>
            <Col span={24}>
              <Checkbox value="pregnancy">
                <Typography sub2 color="black">
                  Pregnancy/ Childbirth/ Infertility/ Cacesarem section/
                  Miscarriage
                </Typography>
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="drug">
                <Typography sub2 color="black">
                  Influence of Drugs/ Alcohol
                </Typography>
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="mental">
                <Typography sub2 color="black">
                  Nervous/ Mental/ Emotional/ Sleeping disorder
                </Typography>
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="cosmetic">
                <Typography sub2 color="black">
                  Cosmetic reason/ Dental care/ Refractive errors correction
                </Typography>
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="aids">
                <Typography sub2 color="black">
                  AIDS
                </Typography>
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="congenital" style={{ marginBottom: 5 }}>
                <Typography sub2 color="black">
                  Cognenital/ Hereditary disease
                </Typography>
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox value="accident" onChange={onChange}>
                <Row align="middle">
                  <Col span={12} style={{ marginBottom: 10 }}>
                    <Typography sub2 color="black">
                      Date of accident
                    </Typography>
                  </Col>
                  <Col span={12} style={{ marginBottom: 10 }}>
                    <Form.Item
                      name="accidentDate"
                      noStyle
                      rules={[
                        {
                          required: isAccident,
                          message: 'กรุณาเลือกวันที่และเวลา',
                        },
                      ]}
                    >
                      <DatePicker disabled={!isAccident} />
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ marginBottom: 10 }}>
                    <Typography sub2 color="black">
                      Time of accident
                    </Typography>
                  </Col>
                  <Col span={12} style={{ marginBottom: 10 }}>
                    <Form.Item name="accidentTime" noStyle>
                      <TimePicker disabled={!isAccident} />
                    </Form.Item>
                  </Col>
                </Row>
              </Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </Form.Item>
      <Row justify="space-between" align="middle" style={{ paddingBottom: 24 }}>
        <Button
          htmlType="submit"
          title="ย้อนกลับ"
          type="secondary"
          style={{ width: '48%' }}
          onClick={() => {
            scrollToTop()
            setStep?.(1)
          }}
        />
        <Button
          htmlType="submit"
          title="ต่อไป"
          style={{ width: '48%' }}
          onClick={() => {
            if (isAccident === true) {
              if (form.getFieldValue('accidentDate')) {
                scrollToTop()
                setStep?.(3)
              }
            } else {
              scrollToTop()
              setStep?.(3)
            }
          }}
        />
      </Row>
    </>
  )
}

export default Step2
