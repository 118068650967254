/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction } from 'react'
import { Form, Row, Input, FormInstance, Col, Select } from 'antd'
import Button from '../../../components/Button'
import Typography from '../../../components/Typography'
import DatePicker from '../../../components/DatePicker'
import { CaseDiagnosis, Hospital } from '../../../interface'
import { getImage, getTitle, scrollToTop } from '../../../utils/getName'
import dayjs from 'dayjs'
import UploadMultipleFile from '../../../components/UploadMultipleFile'

interface Props {
  id?: string
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
  setStep?: Dispatch<SetStateAction<number>> | undefined
  form: FormInstance<any>
  data: CaseDiagnosis | undefined
  hospital?: Hospital[]
}
const { Option } = Select
const { TextArea } = Input

const Step1 = ({ setStep, data, hospital, form }: Props): JSX.Element => {
  const today = dayjs()

  return (
    <>
      <Form.Item
        label={
          <Typography sub2 color="black">
            ผู้ทำประกัน
          </Typography>
        }
        name="insuredId"
      >
        <Row align="middle" justify="space-between">
          <Col span={8}>
            <img
              style={{
                width: '90px',
                height: '90px',
                borderRadius: '50%',
                objectFit: 'cover',
              }}
              src={getImage(data?.insured.image)}
            />
          </Col>
          <Col span={16}>
            <Typography sub1 color="black">
              {`${getTitle(data?.insured.title)} ${data?.insured.firstName} ${
                data?.insured.lastName
              }`}
            </Typography>
            <Typography sub1 color="black">
              {`${dayjs(data?.insured.birthDate)
                .locale('th')
                .format('DD MMMM YYYY')}  (${today.diff(
                String(data?.insured.birthDate),
                'year'
              )}ปี)`}
            </Typography>
          </Col>
        </Row>
      </Form.Item>
      <div style={{ paddingBottom: 16 }}>
        <hr />
      </div>
      <Form.Item
        label={
          <Typography sub4 color="black65">
            วัน เวลาที่เปิดเคส
          </Typography>
        }
        name="date"
        rules={[{ required: true, message: 'กรุณากรอก วัน เวลาที่เปิดเคส' }]}
      >
        <DatePicker
          placeholder="เลือกวันที่"
          showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
        />
      </Form.Item>
      <Form.Item
        label={
          <Typography sub4 color="black65">
            โรงพยาบาลที่เปิดเคส
          </Typography>
        }
        name="hospitalId"
        rules={[{ required: true, message: 'กรุณาเลือกโรงพยาบาล' }]}
      >
        <Select
          placeholder="เลือกโรงพยาบาล"
          getPopupContainer={(trigger) => trigger.parentNode}
        >
          {hospital?.map((x) => {
            return (
              <Option key={x.id} value={x.id}>
                {x.name}
              </Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item
        label={
          <Typography sub4 color="black65">
            ระยะเวลา
          </Typography>
        }
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="durationDay">
              <Input
                className="ant-input-class"
                type="number"
                addonAfter="วัน"
                min={0}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="durationOffsetHour">
              <Input
                className="ant-input-class"
                type="number"
                addonAfter="ชั่วโมง"
                min={0}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
      <Form.Item
        label={
          <Typography sub4 color="black65">
            อาการสำคัญ
          </Typography>
        }
      >
        <Form.Item noStyle name="importantSymptom">
          <TextArea
            rows={3}
            autoSize={{ minRows: 3, maxRows: 3 }}
            allowClear={true}
          />
        </Form.Item>
        <UploadMultipleFile
          formName="importantSymptomAttachments"
          form={form}
          data={data?.diagnosis.importantSymptomAttachments}
        />
      </Form.Item>
      <Form.Item
        label={
          <Typography sub4 color="black65">
            อาการบาดเจ็บสะสม
          </Typography>
        }
      >
        <Form.Item noStyle name="cumulativeInjury">
          <TextArea
            rows={3}
            autoSize={{ minRows: 3, maxRows: 3 }}
            allowClear={true}
          />
        </Form.Item>
        <UploadMultipleFile
          formName="cumulativeInjuryAttachments"
          form={form}
          data={data?.diagnosis.cumulativeInjuryAttachments}
        />
      </Form.Item>
      <Row
        justify="space-between"
        align="middle"
        style={{ justifyContent: 'center', paddingBottom: 24 }}
      >
        <Button
          htmlType="submit"
          title="ต่อไป"
          style={{ width: '48%' }}
          onClick={() => {
            if (
              form.getFieldValue('date') &&
              form.getFieldValue('hospitalId')
            ) {
              setStep?.(2)
              scrollToTop()
            }
          }}
        />
      </Row>
    </>
  )
}

export default Step1
