import React from 'react'
import styled from '@emotion/styled'

interface Props {
  children: string | string[] | undefined
  color?: string
  style?: React.CSSProperties
  h1?: boolean
  h2?: boolean
  title1?: boolean
  title2?: boolean
  sub1?: boolean
  sub2?: boolean
  sub3?: boolean
  sub4?: boolean
  body?: boolean
}

interface TextProps {
  color?: string
  h1?: boolean
  h2?: boolean
  title1?: boolean
  title2?: boolean
  sub1?: boolean
  sub2?: boolean
  sub3?: boolean
  sub4?: boolean
  body?: boolean
}

const AntdText = styled.div<TextProps>`
  ${({ h1, h2, title1, title2, sub1, sub2, sub3, sub4, body }) => {
    if (h1)
      return `
      font-size: 38px;
      font-weight: 700;
      line-height:40px;
    `
    if (h2)
      return `
      font-size: 32px;
      font-weight: 700;
      line-height:40px;
    `
    if (title1)
      return `
      font-size: 24px;
      font-weight: 500;
      line-height:32px;
    `
    if (title2)
      return `
      font-size: 20px;
      font-weight: 500;
      line-height:28px;
    `
    if (sub1)
      return `
      font-size: 16px;
      font-weight: 500;
      line-height:24px;
    `
    if (sub2)
      return `
      font-size: 16px;
      font-weight: 400;
      line-height:24px;
    `
    if (sub3)
      return `
      font-size: 14px;
      font-weight: 500;
      line-height:24px;
    `
    if (sub4)
      return `
      font-size: 14px;
      font-weight: 400;
      line-height:22px;
    `
    if (body)
      return `
      font-size: 12px;
      font-weight: 400;
      line-height:20px;
    `
    return ``
  }}

  color: ${({ theme, color }) => {
    if (color === 'primary1') {
      return `${theme.primary1}`
    } else if (color === 'primary2') {
      return `${theme.primary2}`
    } else if (color === 'secondary1') {
      return `${theme.secondary1}`
    } else if (color === 'secondary2') {
      return `${theme.secondary2}`
    } else if (color === 'white') {
      return `${theme.white}`
    } else if (color === 'success') {
      return `${theme.success}`
    } else if (color === 'awaiting') {
      return `${theme.awaiting}`
    } else if (color === 'error') {
      return `${theme.error}`
    } else if (color === 'black90') {
      return `${theme.black90}`
    } else if (color === 'black85') {
      return `${theme.black85}`
    } else if (color === 'black65') {
      return `${theme.black65}`
    } else if (color === 'black40') {
      return `${theme.black40}`
    }
    return `${theme.black}`
  }}
`

const Typography = ({
  color,
  children,
  h1,
  h2,
  title1,
  title2,
  sub1,
  sub2,
  sub3,
  sub4,
  body,
  style,
}: Props): JSX.Element => {
  return (
    <AntdText
      color={color}
      h1={h1}
      h2={h2}
      title1={title1}
      title2={title2}
      sub1={sub1}
      sub2={sub2}
      sub3={sub3}
      sub4={sub4}
      body={body}
      style={style}
    >
      {children}
    </AntdText>
  )
}

export default Typography
