import React from 'react'
import { ThemeProvider } from '@emotion/react'
import { LocalizationProvider } from './contexts/LocalizationContext'
import { AuthProvider } from './contexts/AuthContext'
import { MenuProvider } from './contexts/MenuContext'
import theme from './utils/theme'
import Routes from './routes'
import './App.less'

function App(): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider>
        <MenuProvider>
          <AuthProvider>
            <Routes />
          </AuthProvider>
        </MenuProvider>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
