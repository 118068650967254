/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { Form, Row, Col, Input, Steps, FormInstance, message } from 'antd'
import Button from '../../../components/Button'
import Typography from '../../../components/Typography'
import AlertModal from '../../../components/AlertModal'
import request from '../../../utils/request'
import {
  Hospital,
  InsurancePlan,
  MedicalSupply,
  Medicine,
  Symptom,
} from '../../../interface'
import SelectTag from '../../../components/SelectTag'
import useWindowDimensions from '../../../utils/getName'

const { TextArea } = Input
const { Step } = Steps

interface Props {
  data: any
  form: FormInstance<any>
  medicineOption: Medicine[]
  medicalSupplyOption: MedicalSupply[]
  symptomOption: Symptom[]
  hospitalOption: Hospital[]
  setToggle: React.Dispatch<React.SetStateAction<boolean>>
  setRefresh?: React.Dispatch<React.SetStateAction<boolean>>
  planSelect: InsurancePlan | undefined
}

const StepEdit = ({
  data,
  form,
  medicineOption,
  medicalSupplyOption,
  symptomOption,
  hospitalOption,
  setToggle,
  setRefresh,
  planSelect,
}: Props): JSX.Element => {
  const [currentStep, setCurrentStep] = useState(0)
  const [openAlertModal, setOpenAlertModal] = useState(false)
  const [selectedMedicine, setSelectedMedicine] = useState<number[]>([])
  const [selectedMedicalSupply, setSelectedMedicalSupply] = useState<number[]>(
    []
  )
  const [selectedSymptom, setSelectedSymptom] = useState<number[]>([])
  const [selectedHospital, setSelectedHospital] = useState<number[]>([])
  const { width } = useWindowDimensions()

  useEffect(() => {
    form.setFieldsValue({
      [`namePlan${data.id}`]: data?.name,
      [`shortDescPlan${data.id}`]: data?.shortDesc,
      [`maxBenefitPerYear${data.id}`]: data?.maxBenefitPerYear,
      [`roomRatePerNight${data.id}`]: data?.roomRatePerNight,
      [`ipdPerYear${data.id}`]: data?.ipdPerYear,
      [`opdPerYear${data.id}`]: data?.opdPerYear,
      [`dentalLimit${data.id}`]: data?.dentalLimit,
      [`accidentDurationHour${data.id}`]: data?.accidentDurationHour,
    })
    if (planSelect) {
      form.setFieldsValue({
        [`excludedMedicines${data.id}`]: planSelect?.excludedMedicines.map(
          (i) => i.id
        ),
        [`includedMedicines${data.id}`]: planSelect?.includedMedicines.map(
          (i) => i.id
        ),
        [`excludedMedicalSupplies${data.id}`]:
          planSelect?.excludedMedicalSupplies.map((i) => i.id),
        [`includedMedicalSupplies${data.id}`]:
          planSelect?.includedMedicalSupplies.map((i) => i.id),
        [`excludedSymptoms${data.id}`]: planSelect?.excludedSymptoms.map(
          (i) => i.id
        ),
        [`includedSymptoms${data.id}`]: planSelect?.includedSymptoms.map(
          (i) => i.id
        ),
        [`excludedHospitals${data.id}`]: planSelect?.excludedHospitals.map(
          (i) => i.id
        ),
        [`includedHospitals${data.id}`]: planSelect?.includedHospitals.map(
          (i) => i.id
        ),
      })
      setSelectedMedicine(
        planSelect?.excludedMedicines
          .map((i) => i.id)
          .concat(planSelect?.includedMedicines.map((i) => i.id))
      )
      setSelectedMedicalSupply(
        planSelect?.excludedMedicalSupplies
          .map((i) => i.id)
          .concat(planSelect?.includedMedicalSupplies.map((i) => i.id))
      )
      setSelectedSymptom(
        planSelect?.excludedSymptoms
          .map((i) => i.id)
          .concat(planSelect?.includedSymptoms.map((i) => i.id))
      )
      setSelectedHospital(
        planSelect?.excludedHospitals
          .map((i) => i.id)
          .concat(planSelect?.includedHospitals.map((i) => i.id))
      )
    }
  }, [data, planSelect])

  const onFinish = async () => {
    try {
      await request.put(`/insurance/${data.insuranceId}/plan/${data.id}`, {
        name: form.getFieldValue(`namePlan${data.id}`),
        shortDesc: form.getFieldValue(`shortDescPlan${data.id}`),
        maxBenefitPerYear: parseInt(
          form.getFieldValue(`maxBenefitPerYear${data.id}`)
        ),
        roomRatePerNight: parseInt(
          form.getFieldValue(`roomRatePerNight${data.id}`)
        ),
        ipdPerYear: parseInt(form.getFieldValue(`ipdPerYear${data.id}`)),
        opdPerYear: parseInt(form.getFieldValue(`opdPerYear${data.id}`)),
        dentalLimit: parseInt(form.getFieldValue(`dentalLimit${data.id}`)),
        accidentDurationHour: parseInt(
          form.getFieldValue(`accidentDurationHour${data.id}`)
        ),
        includedSymptoms:
          form.getFieldValue(`includedSymptoms${data.id}`) || [],
        excludedSymptoms:
          form.getFieldValue(`excludedSymptoms${data.id}`) || [],
        includedMedicines:
          form.getFieldValue(`includedMedicines${data.id}`) || [],
        excludedMedicines:
          form.getFieldValue(`excludedMedicines${data.id}`) || [],
        includedMedicalSupplies:
          form.getFieldValue(`includedMedicalSupplies${data.id}`) || [],
        excludedMedicalSupplies:
          form.getFieldValue(`excludedMedicalSupplies${data.id}`) || [],
        includedHospitals:
          form.getFieldValue(`includedHospitals${data.id}`) || [],
        excludedHospitals:
          form.getFieldValue(`excludedHospitals${data.id}`) || [],
      })
      setOpenAlertModal(true)
      setRefresh?.((prev) => !prev)
    } catch (error) {
      message.error('บันทึกข้อมูลไม่สำเร็จ')
    }
  }

  const stepsContent = [
    {
      content: (
        <>
          <Form.Item
            label={
              <Typography sub1 color="black65">
                แผนคุ้มครอง
              </Typography>
            }
            name={`namePlan${data.id}`}
          >
            <Input className="ant-input-class" />
          </Form.Item>
          <Form.Item
            label={
              <Typography sub1 color="black65">
                คำอธิบายแผนการคุ้มครองอย่างสั้น
              </Typography>
            }
            name={`shortDescPlan${data.id}`}
          >
            <TextArea rows={3} />
          </Form.Item>
          <Row justify="space-between" gutter={32}>
            <Col span={12}>
              <Form.Item
                label={
                  <Typography sub1 color="black65">
                    ผลประโยชน์สูงสุด (ต่อปี)
                  </Typography>
                }
                name={`maxBenefitPerYear${data.id}`}
              >
                <Input className="ant-input-class" addonAfter="บาท" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <Typography sub1 color="black65">
                    ค่าห้อง (ต่อคืน)
                  </Typography>
                }
                name={`roomRatePerNight${data.id}`}
              >
                <Input className="ant-input-class" addonAfter="บาท" />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" gutter={32}>
            <Col span={12}>
              <Form.Item
                label={
                  <Typography sub1 color="black65">
                    IPD
                  </Typography>
                }
                name={`ipdPerYear${data.id}`}
              >
                <Input
                  className="ant-input-class"
                  addonAfter="จำนวนครั้งต่อปี"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <Typography sub1 color="black65">
                    OPD
                  </Typography>
                }
                name={`opdPerYear${data.id}`}
              >
                <Input
                  className="ant-input-class"
                  addonAfter="จำนวนครั้งต่อปี"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" gutter={32}>
            <Col span={12}>
              <Form.Item
                label={
                  <Typography sub1 color="black65">
                    วงเงินทันตกรรม
                  </Typography>
                }
                name={`dentalLimit${data.id}`}
              >
                <Input
                  className="ant-input-class"
                  addonAfter="จำนวนครั้งต่อปี"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <Typography sub1 color="black65">
                    ระยะเวลาการเกิดอุบัติเหตุ (ชั่วโมง)
                  </Typography>
                }
                name={`accidentDurationHour${data.id}`}
              >
                <Input className="ant-input-class" addonAfter="ชั่วโมง" />
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
    {
      content: (
        <>
          <Row justify="space-between" gutter={32}>
            <Col span={12}>
              <Form.Item
                label={
                  <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography sub1 color="black65">
                      ยาที่
                    </Typography>
                    <Typography title1 color="error">
                      ไม่รวม
                    </Typography>
                    <Typography sub1 color="black65">
                      ในกรมธรรม์
                    </Typography>
                  </div>
                }
              >
                <SelectTag
                  title="เพิ่มรายการยา"
                  formName={`excludedMedicines${data.id}`}
                  tagColor="red"
                  tagKey="commonName"
                  options={medicineOption}
                  setSelectedItems={setSelectedMedicine}
                  selectedItems={selectedMedicine}
                  isOpen={
                    form.getFieldValue(`excludedMedicines${data.id}`)?.length >
                    0
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography sub1 color="black65">
                      ยาที่
                    </Typography>
                    <Typography title1 color="primary1">
                      รวม
                    </Typography>
                    <Typography sub1 color="black65">
                      ในกรมธรรม์
                    </Typography>
                  </div>
                }
              >
                <SelectTag
                  title="เพิ่มรายการยา"
                  formName={`includedMedicines${data.id}`}
                  tagColor="blue"
                  tagKey="commonName"
                  options={medicineOption}
                  setSelectedItems={setSelectedMedicine}
                  selectedItems={selectedMedicine}
                  isOpen={
                    form.getFieldValue(`includedMedicines${data.id}`)?.length >
                    0
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
    {
      content: (
        <>
          <Row justify="space-between" gutter={32}>
            <Col span={12}>
              <Form.Item
                label={
                  <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography sub1 color="black65">
                      เวชภัณฑ์ที่
                    </Typography>
                    <Typography title1 color="error">
                      ไม่รวม
                    </Typography>
                    <Typography sub1 color="black65">
                      ในกรมธรรม์
                    </Typography>
                  </div>
                }
              >
                <SelectTag
                  title="เพิ่มรายการเวชภัณฑ์"
                  formName={`excludedMedicalSupplies${data.id}`}
                  tagColor="red"
                  tagKey="commonName"
                  options={medicalSupplyOption}
                  setSelectedItems={setSelectedMedicalSupply}
                  selectedItems={selectedMedicalSupply}
                  isOpen={
                    form.getFieldValue(`excludedMedicalSupplies${data.id}`)
                      ?.length > 0
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography sub1 color="black65">
                      เวชภัณฑ์ที่
                    </Typography>
                    <Typography title1 color="primary1">
                      รวม
                    </Typography>
                    <Typography sub1 color="black65">
                      ในกรมธรรม์
                    </Typography>
                  </div>
                }
              >
                <SelectTag
                  title="เพิ่มรายการเวชภัณฑ์"
                  formName={`includedMedicalSupplies${data.id}`}
                  tagColor="blue"
                  tagKey="commonName"
                  options={medicalSupplyOption}
                  setSelectedItems={setSelectedMedicalSupply}
                  selectedItems={selectedMedicalSupply}
                  isOpen={
                    form.getFieldValue(`includedMedicalSupplies${data.id}`)
                      ?.length > 0
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
    {
      content: (
        <>
          <Row justify="space-between" gutter={32}>
            <Col span={12}>
              <Form.Item
                label={
                  <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography sub1 color="black65">
                      อาการ / โรคที่
                    </Typography>
                    <Typography title1 color="error">
                      ไม่รวม
                    </Typography>
                    <Typography sub1 color="black65">
                      ในกรมธรรม์
                    </Typography>
                  </div>
                }
              >
                <SelectTag
                  title="เพิ่มอาการ / โรค"
                  formName={`excludedSymptoms${data.id}`}
                  tagColor="red"
                  tagKey="name"
                  options={symptomOption}
                  setSelectedItems={setSelectedSymptom}
                  selectedItems={selectedSymptom}
                  isOpen={
                    form.getFieldValue(`excludedSymptoms${data.id}`)?.length > 0
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography sub1 color="black65">
                      อาการ / โรคที่
                    </Typography>
                    <Typography title1 color="primary1">
                      รวม
                    </Typography>
                    <Typography sub1 color="black65">
                      ในกรมธรรม์
                    </Typography>
                  </div>
                }
              >
                <SelectTag
                  title="เพิ่มอาการ / โรค"
                  formName={`includedSymptoms${data.id}`}
                  tagColor="blue"
                  tagKey="name"
                  options={symptomOption}
                  setSelectedItems={setSelectedSymptom}
                  selectedItems={selectedSymptom}
                  isOpen={
                    form.getFieldValue(`includedSymptoms${data.id}`)?.length > 0
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
    {
      content: (
        <>
          <Row justify="space-between" gutter={32}>
            <Col span={12}>
              <Form.Item
                label={
                  <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography sub1 color="black65">
                      โรงพยาบาลที่
                    </Typography>
                    <Typography title1 color="error">
                      ไม่รวม
                    </Typography>
                    <Typography sub1 color="black65">
                      ในกรมธรรม์
                    </Typography>
                  </div>
                }
              >
                <SelectTag
                  title="เพิ่มโรงพยาบาล"
                  formName={`excludedHospitals${data.id}`}
                  tagColor="red"
                  tagKey="name"
                  options={hospitalOption}
                  setSelectedItems={setSelectedHospital}
                  selectedItems={selectedHospital}
                  isOpen={
                    form.getFieldValue(`excludedHospitals${data.id}`)?.length >
                    0
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography sub1 color="black65">
                      โรงพยาบาลที่
                    </Typography>
                    <Typography title1 color="primary1">
                      รวม
                    </Typography>
                    <Typography sub1 color="black65">
                      ในกรมธรรม์
                    </Typography>
                  </div>
                }
              >
                <SelectTag
                  title="เพิ่มโรงพยาบาล"
                  formName={`includedHospitals${data.id}`}
                  tagColor="blue"
                  tagKey="name"
                  options={hospitalOption}
                  setSelectedItems={setSelectedHospital}
                  selectedItems={selectedHospital}
                  isOpen={
                    form.getFieldValue(`includedHospitals${data.id}`)?.length >
                    0
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
  ]

  return (
    <>
      <Steps
        current={currentStep}
        onChange={(current) => setCurrentStep(current)}
        style={{ margin: '0px 0px 20px' }}
        className="stepEdit"
        labelPlacement={width && width <= 1024 ? 'vertical' : 'horizontal'}
      >
        <Step title="แผนการคุ้มครอง" status="finish" />
        <Step title="ยา" status="finish" />
        <Step title="เวชภัณฑ์" status="finish" />
        <Step title="อาการ / โรค" status="finish" />
        <Step title="โรงพยาบาล" status="finish" />
      </Steps>
      {stepsContent[currentStep].content}
      <Row justify={'end'} align="middle" style={{ padding: '10px 0px 30px' }}>
        {/* <Button
          htmlType="button"
          title="ยกเลิก"
          type="secondary"
          style={{ width: 100, marginRight: 16 }}
        /> */}
        <Button
          htmlType="button"
          title="บันทึก"
          onClick={() => onFinish()}
          style={{ width: 100 }}
        />
      </Row>
      <AlertModal
        type="done"
        title="แก้ไขแผนการคุ้มครองเรียบร้อย"
        isModalVisible={openAlertModal}
        onSubmit={() => {
          setOpenAlertModal(false)
          setToggle(false)
        }}
      />
    </>
  )
}

export default StepEdit
