/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import SearchSelect from '../../../components/SearchSelect'
import { DiagnosisMedicalService, OptionType } from '../../../interface'
import request from '../../../utils/request'
import { FormInstance, message } from 'antd'
import { SelectValue } from 'antd/lib/select'
import _ from 'lodash'

interface Props {
  onChange: (value: SelectValue, index?: number) => void
  form: FormInstance<any>
  medicalServices: DiagnosisMedicalService[]
  refresh: boolean
  index: number
}

const MedicalServiceSelect: React.FC<Props> = ({
  onChange,
  form,
  medicalServices,
  refresh,
  index,
}) => {
  const [value, setValue] = useState<number | undefined>(undefined)
  const [dataMedicalService, setDataMedicalService] = useState<OptionType[]>([])
  const [fetchingMedicalService, setFetchingMedicalService] =
    useState<boolean>(false)
  const [hasMorePageMedicalService, setHasMorePageMedicalService] =
    useState<boolean>(false)

  useEffect(() => {
    const medicalService = form.getFieldValue([
      'medicalServices',
      index,
      'serviceId',
    ])
    if (medicalService) {
      setValue(medicalService)
    }
  }, [form.getFieldValue(['medicalServices', index, 'serviceId'])])

  useEffect(() => {
    if (medicalServices.length > 0) {
      if (index < medicalServices.length) {
        setDataMedicalService(
          medicalServices.map((item) => ({
            value: item.service.id,
            label: item.service.name,
          }))
        )
      }
    }
    fetchOptionsMedicalService('', 1)
  }, [medicalServices, refresh])

  const fetchOptionsMedicalService = async (
    search: string,
    page: number
  ): Promise<void> => {
    try {
      setFetchingMedicalService(true)
      const res: any = await request.get(`/medical-service`, {
        params: {
          page: page,
          limit: 10,
          keyword: search ? search : undefined,
        },
      })
      const newOptions = res.data.map((item: any) => ({
        value: item.id,
        label: `${item.name} ${item.otherNames.join(',')}`,
      }))
      setDataMedicalService((prevData: OptionType[]) => {
        const uniqArray = [...prevData, ...newOptions]
        return _.uniqBy(uniqArray, 'value')
      })
      setHasMorePageMedicalService(res.hasMorePage)
    } catch (err) {
      message.error('เรียกข้อมูลไม่สำเร็จ')
    } finally {
      setFetchingMedicalService(false)
    }
  }

  return (
    <SearchSelect
      placeholder="กรุณาเลือกบริการ"
      fetchOptions={fetchOptionsMedicalService}
      handleSelect={onChange}
      value={value}
      data={dataMedicalService}
      fetching={fetchingMedicalService}
      loadingPage={true}
      hasMorePage={hasMorePageMedicalService}
    />
  )
}

export default MedicalServiceSelect
