/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { Form, Row, Input, FormInstance, message } from 'antd'
import Button from '../../../../components/Button'
import Typography from '../../../../components/Typography'
import { UserInsurance } from '../../../../interface'
import request from '../../../../utils/request'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'

interface Props {
  id?: string
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
  setStep?: Dispatch<SetStateAction<number>> | undefined
  form: FormInstance<any>
  data: UserInsurance | undefined
  setSelectUserInsuranceId: Dispatch<React.SetStateAction<number | undefined>>
  setSelectCompanyId: React.Dispatch<React.SetStateAction<number | undefined>>
  setSelectInsuranceId: React.Dispatch<React.SetStateAction<number | undefined>>
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>
  disabled: boolean
}

interface Params {
  id: string
}

const Step2 = ({
  setIsModalVisible,
  setStep,
  form,
  data,
  setSelectUserInsuranceId,
  setSelectCompanyId,
  setSelectInsuranceId,
  setRefresh,
  setDisabled,
  disabled,
}: Props): JSX.Element => {
  const params = useParams<Params>()
  // const [disabled, setDisabled] = useState(false)
  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        limit: data?.limit,
        coverageLimitPerYear: data?.coverageLimitPerYear,
        roomRatePerMonth: data?.roomRatePerMonth,
        ipd: data?.ipd,
        opd: data?.opd,
        dentalLimit: data?.dentalLimit,
      })
    }
  }, [data])

  const onFinish = async () => {
    setDisabled(true)
    try {
      await request.put(`/insured/${params.id}/plan/${data?.id}`, {
        planId: data?.planId,
        availableDate: dayjs(data?.availableDate),
        expiredDate: dayjs(data?.expiredDate),
        limit: parseInt(form.getFieldValue('limit')),
        coverageLimitPerYear: parseInt(
          form.getFieldValue('coverageLimitPerYear')
        ),
        roomRatePerMonth: parseInt(form.getFieldValue('roomRatePerMonth')),
        ipd: parseInt(form.getFieldValue('ipd')),
        opd: parseInt(form.getFieldValue('opd')),
        dentalLimit: parseInt(form.getFieldValue('dentalLimit')),
      })
      message.success('บันทึกข้อมูลสำเร็จ')
      setIsModalVisible(false)
      setSelectUserInsuranceId(undefined)
      setSelectCompanyId(undefined)
      setSelectInsuranceId(undefined)
      setRefresh?.((prev) => !prev)
      setStep?.(1)
      setDisabled(false)
    } catch (error) {
      message.error('บันทึกข้อมูลไม่สำเร็จ')
    }
  }

  return (
    <>
      <Form.Item
        label={
          <Typography sub4 color="black65">
            วงเงิน
          </Typography>
        }
        name="limit"
      >
        <Input className="ant-input-class" addonAfter="บาท" type="number" />
      </Form.Item>
      <Form.Item
        label={
          <Typography sub4 color="black65">
            วงเงินคุ้มครอง (ต่อปี)
          </Typography>
        }
        name="coverageLimitPerYear"
      >
        <Input className="ant-input-class" addonAfter="บาท" type="number" />
      </Form.Item>
      <Form.Item
        label={
          <Typography sub4 color="black65">
            ค่าห้อง (ต่อเดือน)
          </Typography>
        }
        name="roomRatePerMonth"
      >
        <Input className="ant-input-class" addonAfter="บาท" type="number" />
      </Form.Item>
      <Form.Item
        label={
          <Typography sub4 color="black65">
            IPD
          </Typography>
        }
        name="ipd"
      >
        <Input className="ant-input-class" addonAfter="บาท" type="number" />
      </Form.Item>
      <Form.Item
        label={
          <Typography sub4 color="black65">
            OPD
          </Typography>
        }
        name="opd"
      >
        <Input className="ant-input-class" addonAfter="บาท" type="number" />
      </Form.Item>
      <Form.Item
        label={
          <Typography sub4 color="black65">
            ค่าทันตกรรม
          </Typography>
        }
        name="dentalLimit"
      >
        <Input className="ant-input-class" addonAfter="บาท" type="number" />
      </Form.Item>
      <Row
        justify="space-between"
        align="middle"
        style={{ justifyContent: 'center' }}
      >
        {data ? (
          <Button
            htmlType="button"
            title="บันทึก"
            style={{ width: '48%' }}
            disabled={disabled}
            onClick={() => {
              onFinish()
            }}
          />
        ) : (
          <Button
            htmlType="submit"
            title="บันทึก"
            style={{ width: '48%' }}
            disabled={disabled}
            // onClick={() => {

            // }}
          />
        )}
      </Row>
    </>
  )
}

export default Step2
