/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Form, Row, Modal as AntModal, Input, Col, message } from 'antd'
import Button from '../../components/Button'
import Typography from '../../components/Typography'
import IconAdd from '../../image/IconAdd.png'
import request from '../../utils/request'
import { MedicalSupply } from '../../interface'
import AlertModal from '../../components/AlertModal'

interface Props {
  id?: string | number
  isModalVisible: boolean
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
  setRefresh?: Dispatch<SetStateAction<boolean>>
}

type FormValues = {
  commonName: string
  tradeNames: string
  price: string
  unit: string
}

const Modal = ({
  id,
  isModalVisible,
  setIsModalVisible,
  setRefresh,
}: Props): JSX.Element => {
  const [form] = Form.useForm()
  const [tradeNameIndex, setTradeNameIndex] = useState(1)
  const [data, setData] = useState<MedicalSupply>()
  const [openConfirmModal, setOpenConfirmModal] = useState(false)

  const getData = async () => {
    if (id) {
      try {
        const res: MedicalSupply = await request.get(`/medical-supply/${id}`)
        setData(res)
      } catch (error) {
        message.error('เรียกข้อมูลไม่สำเร็จ')
        setData(undefined)
      }
      return
    }
    setData(undefined)
  }

  useEffect(() => {
    getData()
  }, [id])

  useEffect(() => {
    form.setFieldsValue({
      commonName: data?.commonName,
      tradeNames: data?.tradeNames,
      price: data?.price,
      unit: data?.unit,
    })
  }, [data])

  const onClose = () => {
    setIsModalVisible(false)
    if (!id) {
      form.resetFields()
      setTradeNameIndex(1)
    }
  }

  const onFinish = async (values: FormValues) => {
    const { commonName, tradeNames, price, unit } = values
    try {
      if (id) {
        await request.put(`/medical-supply/${id}`, {
          commonName,
          tradeNames,
          price: parseInt(price),
          unit,
        })
        message.success('แก้ไขรายการสำเร็จ')
      } else {
        await request.post(`/medical-supply`, {
          commonName,
          tradeNames,
          price: parseInt(price),
          unit,
        })
        message.success('เพิ่มรายการสำเร็จ')
      }
      onClose()
      setRefresh?.((prev) => !prev)
    } catch (e: any) {
      message.error('ดำเนินการผิดพลาด')
      if (e.response.status === 400) {
        setOpenConfirmModal(true)
      }
    }
  }

  const createElements = (n: number) => {
    const elements = []
    for (let i = 0; i < n; i++) {
      elements.push(
        i === 0 ? (
          <Form.Item
            name={['tradeNames', i]}
            className="tradeName"
            rules={[{ required: true, message: 'กรุณากรอกชื่อทางการค้า' }]}
          >
            <Input className="ant-input-class" />
          </Form.Item>
        ) : (
          <Form.Item className="tradeName">
            <Row gutter={10} align="middle">
              <Col span={22}>
                <Form.Item
                  name={['tradeNames', i]}
                  rules={[
                    { required: true, message: 'กรุณากรอกชื่อทางการค้า' },
                  ]}
                  noStyle
                >
                  <Input className="ant-input-class" />
                </Form.Item>
              </Col>
              <Col span={2}>
                <a onClick={() => setTradeNameIndex((prev) => prev - 1)}>
                  <Typography color="primary1">ลบ</Typography>
                </a>
              </Col>
            </Row>
          </Form.Item>
        )
      )
    }
    return elements
  }
  return (
    <>
      <AntModal
        centered
        visible={isModalVisible}
        style={{ borderRadius: 8 }}
        bodyStyle={{ overflowX: 'scroll', maxHeight: 500 }}
        width={700}
        title={
          <Typography title1 color="black90">
            {id ? 'แก้ไขรายชื่อเวชภัณฑ์' : 'เพิ่มรายชื่อเวชภัณฑ์'}
          </Typography>
        }
        footer={null}
        onCancel={onClose}
      >
        <Form
          form={form}
          name="MedicalSupplyModal"
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            label={
              <Typography sub4 color="black65">
                ชื่อสามัญ
              </Typography>
            }
            name="commonName"
            rules={[{ required: true, message: 'กรุณากรอกชื่อสามัญ' }]}
          >
            <Input className="ant-input-class" />
          </Form.Item>
          <Form.Item
            label={
              <Typography sub4 color="black65">
                ราคา (บาท)
              </Typography>
            }
            name="price"
            rules={[{ required: true, message: 'กรุณากรอกราคา' }]}
          >
            <Input
              className="ant-input-class"
              type="number"
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item
            label={
              <Typography sub4 color="black65">
                หน่วย
              </Typography>
            }
            name="unit"
            rules={[{ required: true, message: 'กรุณากรอกหน่วย' }]}
          >
            <Input className="ant-input-class" />
          </Form.Item>
          <Form.Item
            label={
              <Typography sub4 color="black65">
                ชื่อทางการค้า
              </Typography>
            }
            required={true}
          >
            {createElements(tradeNameIndex)}
            <Button
              type="text"
              htmlType="button"
              title="เพิ่มชื่อทางการค้า"
              iconLeft={<img style={{ width: 23, height: 23 }} src={IconAdd} />}
              style={{ padding: 0 }}
              onClick={() => setTradeNameIndex((prev) => prev + 1)}
            />
          </Form.Item>
          <Row justify="center" align="middle">
            <Button htmlType="submit" title="บันทึก" style={{ width: '50%' }} />
          </Row>
        </Form>
      </AntModal>
      <AlertModal
        type="confirm"
        title="มีรายการนี้อยู่แล้ว"
        isModalVisible={openConfirmModal}
        onSubmit={() => {
          setOpenConfirmModal(false)
        }}
      />
    </>
  )
}

export default Modal
