/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import IconAdd from '../../../../image/IconAdd.png'
import Modal from './ModalInsurance'
import Button from '../../../../components/Button'
import { Col, message, Row } from 'antd'
import Typography from '../../../../components/Typography'
import request from '../../../../utils/request'
import { UserInsurance } from '../../../../interface'
import AlertModal from '../../../../components/AlertModal'
import dayjs from 'dayjs'

interface Props {
  id: string
}
const insurancePage = ({ id }: Props): JSX.Element => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [userInsuranceList, setUserInsuranceList] = useState<UserInsurance[]>(
    []
  )
  const [step, setStep] = useState(1)
  const [selectUserInsuranceId, setSelectUserInsuranceId] = useState<
    number | undefined
  >(undefined)
  const [refresh, setRefresh] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)

  const getUserInsurance = async () => {
    try {
      const res: any = await request.get(`/insured/${id}/plan`, {
        params: {
          page: 1,
          limit: 6,
        },
      })
      setUserInsuranceList(res.data)
    } catch (error) {
      message.error('เรียกข้อมูลไม่สำเร็จ')
    }
  }

  useEffect(() => {
    getUserInsurance()
  }, [refresh])

  const onDelete = async () => {
    try {
      await request.delete(`/insured/${id}/plan/${selectUserInsuranceId}`)
      message.success('ลบกรมธรรม์สำเร็จ')
      setOpenConfirmModal(false)
      setRefresh?.((prev) => !prev)
      setSelectUserInsuranceId(undefined)
    } catch (err) {
      message.error('ดำเนินการผิดพลาด')
    }
  }

  return (
    <Col span={24}>
      <br />
      {userInsuranceList?.map((item) => {
        const status = dayjs(item.expiredDate).isSameOrAfter(dayjs(), 'day')
          ? 'กำลังคุ้มครอง'
          : 'หมดอายุ'
        return (
          <div
            key={item.id}
            style={{ padding: '30px 0px', borderBottom: '1px solid #C0C0C0' }}
          >
            <Row justify="space-between" style={{ marginBottom: 5 }}>
              <Typography sub1>
                {`${item.plan.insurance.company.abbreviation}, ${item.plan.insurance.name}`}
              </Typography>
              <Row>
                <div
                  style={{
                    borderRight: '1px solid #A2A2A2',
                    padding: '0px 10px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setStep(2)
                    setSelectUserInsuranceId(item.id)
                    setIsModalVisible(true)
                  }}
                >
                  <Typography sub2 color="black65">
                    แก้ไขวงเงิน
                  </Typography>
                </div>
                <div
                  style={{
                    borderRight: '1px solid #A2A2A2',
                    padding: '0px 10px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setStep(1)
                    setSelectUserInsuranceId(item.id)
                    setIsModalVisible(true)
                  }}
                >
                  <Typography sub2 color="black65">
                    แก้ไขข้อมูล
                  </Typography>
                </div>
                <div
                  style={{
                    padding: '0px 10px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setOpenConfirmModal(true)
                    setSelectUserInsuranceId(item.id)
                  }}
                >
                  <Typography sub2 color="black65">
                    ลบ
                  </Typography>
                </div>
              </Row>
            </Row>
            <Row>
              <Col span={6}>
                <Typography sub1>
                  {dayjs(item.availableDate).format('DD/MM/YYYY')}
                </Typography>
              </Col>
              <Col span={6}>
                <Typography sub1>
                  {dayjs(item.expiredDate).format('DD/MM/YYYY')}
                </Typography>
              </Col>
              <Col span={6}>
                <Typography
                  sub1
                  color={status === 'กำลังคุ้มครอง' ? 'success' : 'awaiting'}
                >
                  {status}
                </Typography>
              </Col>
            </Row>
          </div>
        )
      })}
      <Button
        onClick={() => setIsModalVisible(true)}
        iconLeft={<img style={{ width: 23, height: 23 }} src={IconAdd} />}
        title="เพิ่มกรมธรรม์"
        type="text"
        style={{ margin: '15px 0px', padding: 0 }}
      />
      <br />
      <br />
      <Modal
        selectUserInsuranceId={selectUserInsuranceId}
        setSelectUserInsuranceId={setSelectUserInsuranceId}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        step={step}
        setStep={setStep}
        setRefresh={setRefresh}
      />
      <AlertModal
        type="confirm"
        title="คุณยืนยันที่จะลบกรมธรรม์นี้ใช่หรือไม่"
        isModalVisible={openConfirmModal}
        onCancel={() => {
          setSelectUserInsuranceId(undefined)
          setOpenConfirmModal(false)
        }}
        onSubmit={() => {
          onDelete()
        }}
      />
    </Col>
  )
}

export default insurancePage
