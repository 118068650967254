/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { Upload, message, Form } from 'antd'
import styled from '@emotion/styled'
import { PlusOutlined } from '@ant-design/icons'
import Typography from '../Typography'

const baseUrl = process.env.REACT_APP_API_URL

const ImageUpload = styled.div`
  cursor: pointer;
  border: 1px dashed #cccccc;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const CurrentImage = styled.img`
  border: 1px dashed #cccccc;
  border-radius: 8px;
  object-fit: 'cover';
`

const UploadButton = ({ type = 'logo' }: Props): JSX.Element => (
  <ImageUpload
    style={
      type === 'logo'
        ? { width: 140, height: 140 }
        : { width: 340, height: 255 }
    }
  >
    <PlusOutlined style={{ fontSize: '30px', color: '#777777' }} />
    <Typography color="black85" style={{ marginTop: 10, padding: '0px 10px' }}>
      {type === 'logo'
        ? `*รูปภาพขนาด 1:1 หรือ 140 x 140 px`
        : `*รูปภาพขนาด 4:3 หรือ 225 x 170 px`}
    </Typography>
  </ImageUpload>
)
interface UrlState {
  mimetype: string
  originalname: string
  size: number
  storageUrl: string
  id: number
}

interface Props {
  onChange?: (
    response:
      | {
          mimetype: string
          originalname: string
          size: number
          storageUrl: string
        }
      | null
      | undefined
  ) => void
  fileList?: number
  setFileList?: any
  type?: 'logo' | 'uploadFile'
  title?: string
  formName?: string
}

const UploadImage = ({
  onChange,
  setFileList,
  fileList,
  type,
  formName,
  title,
}: Props): JSX.Element => {
  const [url, setUrl] = useState<UrlState | null>(null)
  const token = localStorage.getItem('accessToken')
  const onImageChange = async ({ file }: any) => {
    if (file.response) {
      setUrl(file.response)
      setFileList(file.response.id)
    }
  }
  const beforeUpload = (file: { type: string; size: number }) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!')
    }
    return isJpgOrPng && isLt2M
  }

  const normFile = (e: { file: any; fileList: any }) => {
    if (e.file.response) {
      return e.fileList
    }
  }
  return (
    <Form.Item
      style={{ width: '100%' }}
      label={
        <Typography sub4 color="black65">
          {title}
        </Typography>
      }
      name={formName}
      rules={[{ required: true, message: 'กรุณาแนบรูป' }]}
      getValueFromEvent={(e) => normFile(e)}
    >
      <Upload
        name="file"
        style={{ display: 'flex' }}
        action={`${baseUrl}/container`}
        beforeUpload={beforeUpload}
        showUploadList={false}
        headers={{
          accept: 'application/json',
          Authorization: token ? `Bearer ${token}` : '',
        }}
        onChange={(info) => {
          const { response } = info.file
          onImageChange(info)
          onChange?.(response)
        }}
        maxCount={1}
        accept="image/*"
      >
        {fileList ? (
          <CurrentImage
            style={
              type === 'logo'
                ? { width: 140, height: 140 }
                : { width: 340, height: 255 }
            }
            src={`${baseUrl}/container/${
              fileList ? fileList : url?.id
            }/?access_token=${token}`}
            alt=""
          />
        ) : (
          <UploadButton type={type} />
        )}
      </Upload>
    </Form.Item>
  )
}

export default UploadImage
