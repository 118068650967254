import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Link, Redirect, useLocation, useHistory } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import { useMenu } from '../contexts/MenuContext'
import Logo from '../image/LogoEcliaim.png'
import { useAuth } from '../contexts/AuthContext'
import request, { PATH_KEY } from '../utils/request'

interface DivProps {
  isOpenMenu?: boolean
}

const Div = styled.div<DivProps>`
  background-color: white;
  transform: translateX(0%);
  @media (max-width: 1024px) {
    background-color: white;
    transform: ${({ isOpenMenu }) =>
      isOpenMenu ? 'translateX(0%)' : 'translateX(-100%)'};
  }
`
const Background = styled.div<DivProps>`
  @media (max-width: 1024px) {
    z-index: 250;
    position: fixed;
    inset: 0px;
    opacity: ${({ isOpenMenu }) => (isOpenMenu ? 1 : 0)};
    visibility: ${({ isOpenMenu }) => (isOpenMenu ? 'visible' : 'hidden')};
    transition: opacity 0.3s ease-out 0s, visibility 0.3s ease-out 0s;
    background-color: rgba(0, 0, 0, 0.3);
  }
`

const { Sider, Content, Header } = Layout
const { SubMenu } = Menu

interface Props {
  children: JSX.Element[]
}

interface SubmenuInterface {
  key: string
  title: string
  subMenu?: SubmenuItemInterface[]
  titleOnClick?(): void
  collapsible?: boolean
}
interface SubmenuItemInterface {
  path: string
  title: string
  key: string
  subMenu?: SubmenuItemInterface[]
  collapsible?: boolean
}

const AppLayout = ({ children }: Props): JSX.Element => {
  const location = useLocation()
  const history = useHistory()
  const { isOpenMenu, toggleMenu } = useMenu()
  const {
    state: { user, userToken },
  } = useAuth()
  const session = sessionStorage.getItem(PATH_KEY)

  useEffect(() => {
    const syncData = async () => {
      try {
        await request.post('/claiming-detail/sync-data')
      } catch (error) {
        console.log(error)
      }
    }
    syncData()
  }, [])

  useEffect(() => {
    sessionStorage.setItem(PATH_KEY, `${location.pathname}${location.search}`)
  }, [location])

  const menuTitle = {
    companyList: 'รายชื่อบริษัทประกันภัย',
    drugList: 'รายชื่อยา',
    medicalSuppliesList: 'รายชื่อเวชภัณฑ์',
    medicalServicesList: 'รายชื่อบริการทางการแพทย์',
    symptomsList: 'รายชื่ออาการ / โรค',
    insuranceList: 'รายชื่อกรมธรรม์',
    hospitalList: 'รายชื่อโรงพยาบาล',
    diagnosisList: 'รายชื่อการวินิจฉัย',
    diagnosticCase: 'เคสการวินิจฉัย',
  }

  const insuranceMenu = [
    {
      key: '/company-list',
      path: '/company-list',
      title: menuTitle.companyList,
    },
    {
      key: '/insurance-list',
      path: '/insurance-list',
      title: menuTitle.insuranceList,
    },
    {
      key: '/drug-list',
      path: '/drug-list',
      title: menuTitle.drugList,
    },
    {
      key: '/medical-supplies-list',
      path: '/medical-supplies-list',
      title: menuTitle.medicalSuppliesList,
    },
    {
      key: '/symptoms-list',
      path: '/symptoms-list',
      title: menuTitle.symptomsList,
    },
    {
      key: '/medical-services-list',
      path: '/medical-services-list',
      title: menuTitle.medicalServicesList,
    },
    {
      key: '/hospital-list',
      path: '/hospital-list',
      title: menuTitle.hospitalList,
    },
  ]

  const renderSubmenu = ({
    key,
    title,
    subMenu = [],
  }: SubmenuInterface): JSX.Element => {
    return (
      <SubMenu key={key} title={title}>
        {subMenu.map((item: SubmenuInterface & SubmenuItemInterface) =>
          item.subMenu ? (
            renderSubmenu({
              key: item.key,
              title: item.title,
              subMenu: item.subMenu,
            })
          ) : (
            <Menu.Item key={item.key}>
              <Link to={item.path}>{item.title}</Link>
            </Menu.Item>
          )
        )}
      </SubMenu>
    )
  }

  const isPermit = (permit: string) => {
    return user.permissions?.includes(permit)
  }

  if (!userToken) {
    return <Redirect to="/" />
  }

  useEffect(() => {
    if (session) {
      return history.push(session)
    }
  }, [])

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Background
        isOpenMenu={isOpenMenu}
        onClick={() => toggleMenu()}
      ></Background>
      <Div className="menu-collapse" isOpenMenu={isOpenMenu}>
        <Sider theme="light" width={256}>
          <img
            className="logo-menu"
            src={Logo}
            style={{
              width: '100%',
              height: 'auto',
              padding: 10,
            }}
          />
          <Menu
            theme="light"
            selectedKeys={[location.pathname]}
            mode="inline"
            onClick={() => {
              toggleMenu()
            }}
            defaultOpenKeys={['insurance']}
          >
            {isPermit('insurance') &&
              renderSubmenu({
                key: 'insurance',
                title: 'กรมธรรม์',
                subMenu: insuranceMenu,
              })}
            {isPermit('insured') && (
              <Menu.Item key="/user-list">
                <Link to="/user-list">รายชื่อผู้ใช้</Link>
              </Menu.Item>
            )}
            {/* {isPermit('diagnosis') && (
              <Menu.Item key="/diagnostic-list">
                <Link to="/diagnostic-list">วินิจฉัย</Link>
              </Menu.Item>
            )}
            {isPermit('claiming') && (
              <Menu.Item key="/claim-list">
                <Link to="/claim-list">การเคลม</Link>
              </Menu.Item>
            )} */}
            <Menu.Item key="/bi-claim">
              <Link to="/bi-claim">ใบเคลม</Link>
            </Menu.Item>
            {isPermit('accessability') && (
              <Menu.Item key="/accessibility">
                <Link to="/accessibility">การเข้าถึง</Link>
              </Menu.Item>
            )}
            <Menu.Item key="/change-password">
              <Link to="/change-password">เปลี่ยนรหัสผ่าน</Link>
            </Menu.Item>
            <Menu.Item key="/logout">
              <Link to="/logout">ออกจากระบบ</Link>
            </Menu.Item>
          </Menu>
        </Sider>
      </Div>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: 'white',
          }}
        ></Header>
        <Content style={{ backgroundColor: 'white' }}>{children}</Content>
      </Layout>
    </Layout>
  )
}

AppLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default AppLayout
