/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Form, Row, Input, Radio, Space, FormInstance, message } from 'antd'
import Button from '../../../components/Button'
import Typography from '../../../components/Typography'
import { scrollToTop } from '../../../utils/getName'
import UploadMultipleFile from '../../../components/UploadMultipleFile'
import { CaseDiagnosis, Symptom } from '../../../interface'
import SelectTag from '../../../components/SelectTag/Symptom'
import request from '../../../utils/request'

interface Props {
  id?: string
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
  setStep?: Dispatch<SetStateAction<number>> | undefined
  form: FormInstance<any>
  data: CaseDiagnosis | undefined
  setSelectedSymptom: React.Dispatch<React.SetStateAction<any[]>>
  selectedSymptom: any[]
}

const { TextArea } = Input
const Step3 = ({
  setStep,
  form,
  data,
  setSelectedSymptom,
  selectedSymptom,
}: Props): JSX.Element => {
  const [valueState, setValueState] = useState(
    form.getFieldValue('isTreatable')
  )
  const [symptomOption, setSymptomOption] = useState<Symptom[]>([])

  const onChange = (e: any) => {
    if (e.target.value) {
      form.setFieldsValue({
        untreatableReason: '',
      })
    }
    setValueState(e.target.value)
  }

  useEffect(() => {
    getSymptom()
  }, [])

  const getSymptom = async () => {
    try {
      const res: Symptom[] = await request.get(`/symptom/all`)
      setSymptomOption(res)
    } catch (error) {
      message.error('เรียกข้อมูลไม่สำเร็จ')
    }
  }

  return (
    <>
      <Form.Item
        label={
          <Typography sub4 color="black65">
            มีภาวะอื่นอยู่ก่อน
          </Typography>
        }
      >
        <Form.Item noStyle name="otherCondition">
          <TextArea
            rows={3}
            autoSize={{ minRows: 3, maxRows: 3 }}
            allowClear={true}
          />
        </Form.Item>
        <UploadMultipleFile
          formName="otherConditionAttachments"
          form={form}
          data={data?.diagnosis.otherConditionAttachments}
        />
      </Form.Item>
      <SelectTag
        label={
          <Typography sub4 color="black65">
            การวินิจฉัยเบื้องต้น
          </Typography>
        }
        formName="initialDiagnosis"
        tagColor="blue"
        tagKey="name"
        options={symptomOption}
        setSelectedItems={setSelectedSymptom}
        selectedItems={selectedSymptom}
        isOpen={true}
        rules={[{ required: true, message: 'กรุณาเลือก' }]}
      />
      <UploadMultipleFile
        formName="initialDiagnosisAttachments"
        form={form}
        data={data?.diagnosis.initialDiagnosisAttachments}
      />
      <Form.Item
        style={{ width: '100%' }}
        label={
          <Typography sub4 color="black65">
            อาการนี้รักษาได้หรือไม่
          </Typography>
        }
        name="isTreatable"
        rules={[{ required: true, message: 'กรุณาเลือก' }]}
      >
        <Radio.Group onChange={(e) => onChange(e)} value={valueState}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Radio value={true}>
              <Typography sub2 color="black">
                ได้
              </Typography>
            </Radio>
            <Radio value={false}>
              <Typography sub2 color="black" style={{ padding: '0px 0px 8px' }}>
                ไม่ได้ (ถ้าไม่ได้ โปรดระบุ)
              </Typography>
              <Form.Item
                name="untreatableReason"
                noStyle
                rules={[
                  {
                    required: valueState === false ? true : false,
                    message: 'โปรดระบุ',
                  },
                ]}
              >
                {valueState === false ? (
                  <Input className="ant-input-class" />
                ) : null}
              </Form.Item>
            </Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      <Row justify="space-between" align="middle" style={{ paddingBottom: 24 }}>
        <Button
          htmlType="submit"
          title="ย้อนกลับ"
          type="secondary"
          style={{ width: '48%' }}
          onClick={() => {
            scrollToTop()
            setStep?.(2)
          }}
        />
        <Button
          htmlType="submit"
          title="ต่อไป"
          style={{ width: '48%' }}
          onClick={() => {
            if (
              form.getFieldValue('initialDiagnosis') &&
              form.getFieldValue('isTreatable')
            ) {
              scrollToTop()
              setStep?.(4)
            }
          }}
        />
      </Row>
    </>
  )
}

export default Step3
